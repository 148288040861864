import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  FormControl,
} from "react-bootstrap";
import { Box, Grid } from "@mui/material";

import { GET_COMPANY_INTEGRATIONS, GET_ZAPIER_CONNECTIONS, REFRESH_ZAPIER_CONNECTIONS } from "../../../api/integration.js";

import { Basiccardheadercolor } from "../../../data/Component/carddata/carddata.js";
import LoadingScreen from "../LoadingScreen/index";
import { startCase } from "lodash";
import { notifySuccess } from "../../../helpers/notifications.js";

const Zapier = () => {
  const [allConns, setAllConns] = useState([]);
  const [conns, setConns] = useState([]);
  const [loading, setLoading] = useState(false);


  const getZapierConnections = async () => {
    try {
      setLoading(true);
      const { data: connectionsData } = await GET_ZAPIER_CONNECTIONS();
      const { data: activeIntegrations } = await GET_COMPANY_INTEGRATIONS();
      const zapierIntegrations = activeIntegrations.filter(i => i.config?.code === 'zapier');
      
      // Count overall total and per integration type
      const integCount = {};
      zapierIntegrations.forEach(api => {
        if(api.parameters?.serviceId) {
          const serviceId = api.parameters.serviceId;
          if(integCount[serviceId]) {
            integCount[serviceId] +=1;
          } else {
            integCount[serviceId] = 1;
          }
        }
      });

      for(let c=0; c<connectionsData.length; c+=1) {
        connectionsData[c].count = integCount[connectionsData[c].serviceId] || 0;
      }

      setAllConns(connectionsData);
      setConns(connectionsData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.response);
    }
  };

  const searchHandler = async (e) => {
    const key = e.target.value.trim().toLowerCase();
    const kpis = allConns.filter((k) => k.name.toLowerCase().indexOf(key) >= 0);
    setConns(kpis);
  };

  useEffect(() => {
    getZapierConnections();
  }, []);

  const refreshList = () => {
    REFRESH_ZAPIER_CONNECTIONS()
      .then(() => {
        notifySuccess("Zapier Connections list was successfully refreshed.")
        getZapierConnections();
      });
  };

  return (
    <>
      <Box sx={{ gridArea: "first", marginTop: 5 }}>
        <Card>
          <Card.Header className="card-header">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="flex-col">
                <h2 className="card-title">Zapier Integrations - Total: {conns?.length}</h2>
                <small className="text-muted">
                  Zapier Integrations must first be crated in {" "}
                  <a 
                    href="https://zapier.com/zap-templates/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Zapier Dashboard
                  </a>
                  &nbsp; and submitted for review.
                </small>
              </div>
              <div className="d-flex justify-content-between">
                {
                  <FormControl
                    type="text"
                    placeholder="Search name or type..."
                    name="type"
                    // value={source}
                    onChange={searchHandler}
                  />
                }
              </div>
            </Box>
          </Card.Header>    
          <Card.Body className="card-body">
            <Grid container spacing={2}>
            {
              conns.map(conn => (
                <Grid item key={conn.name} xs={12} sm={6} md={4}>
                  <Basiccardheadercolor 
                    title={startCase(conn.name)}
                    subtitle={conn.dataSets.join(", ")}
                    footer={<p><strong>Service ID: {conn.serviceId}</strong></p>}
                  >
                    <div className="d-flex justify-content-between">
                      <div>
                        <p className="mb-2">Data Sets: {conn.dataSets.join(", ")}</p>
                        <p className="mb-2">Total Connections: {conn.count}</p>
                      </div>
                      <img src={conn.logo} alt={conn.name} height="50" />
                    </div>

                  </Basiccardheadercolor>

                </Grid>
              ))
            }
            </Grid>
          </Card.Body>
          {
            loading && <LoadingScreen />
          }
          <Card.Footer>
              <Box className="d-flex flex-column">
                <Button
                  className="btn btn-primary-light"
                  variant="primary-light"
                  onClick={refreshList}
                >
                  FETCH ZAPIER LIST
                </Button>
              </Box>
          </Card.Footer>
        </Card>
      </Box>
    </>
  );
};

export default Zapier;
