import React, { useState, useEffect } from "react";
import {
  Card,
  Badge,
  Button,
  Modal,
  Form,
  FormGroup,
  Alert,
  Row,
  Col,
  FormControl,
} from "react-bootstrap";
import { Box } from "@mui/material";

import {
  CREATE_PLUGIN,
  FETCH_PLUGINS,
  UPDATE_PLUGIN,
} from "../../../api/plugin.js";
import { GET_IMAGE } from "../../../helpers/images.js";
import Loader from "../LoadingScreen/index";
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { notify } from "../../../helpers/notifications.js";
import startCase from "lodash/startCase.js";

const PluginMaster = () => {
  const [show, setShow] = useState({
    show: false,
    action: "create",
  });
  const [showAlert, setShowAlert] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allPlugins, setAllPlugins] = useState([]);
  const [plugins, setPlugins] = useState([]);
  const [source, setSource] = useState("");
  const [website, setWebsite] = useState("");
  const [image, setImage] = useState();
  const [previewImage, setPreviewImage] = useState();
  const [editImage, setEditImage] = useState(false);
  const [noLogos, setNoLogos] = useState(0);

  const createPluginHandler = async () => {
    let result;
    if (source === "" || website === "" || image === null) {
      setShowAlert(true);
    } else {
      if(show.action === "create") {
        // Check if source is duplicated
        const idx = allPlugins.findIndex(p => p.source === source.trim().toLowerCase());
        if(idx >= 0) {
          notify(`${source} is a duplicate entry.`, "error");
          return;
        }
      }

      setLoading(true);
      let formData = new FormData();
      formData.append("source", source);
      formData.append("website", website);
      formData.append("image", image);

      try {
        if (show.action === "create") {
          result = await CREATE_PLUGIN(formData);
        } else {
          result = await UPDATE_PLUGIN(show.id, formData);
        }
        await fetchPlugins();
        setSource("");
        setWebsite("");
        setImage(null);
        setShow({
          show: false,
          action: "create",
        });
  
        notify(`${startCase(result.source)} was successfully saved.`)
        setLoading(false);
      } catch (err) {
        notify("Server update error", "error");
        setLoading(false);
      }
    }
  };

  const fetchPlugins = async () => {
    setFetching(true);
    try {
      const { data } = await FETCH_PLUGINS();
      const plugins = data.map((item) => ({
        ...item,
        showImg: !!(item.imageSrc),
      }))
      .sort((a,b) => !!(b.imageSrc) && !a.imageSrc ? -1 : 1 );
      setNoLogos(plugins.filter(p => !p.imageSrc).length);
      setAllPlugins(plugins)
      setPlugins(plugins);
    } catch (err) {
      console.log(err);
    }
    setFetching(false);
  };

  const searchHandler = async (e) => {
    const key = e.target.value?.trim().toLowerCase();
    if(key) {
      const matches = allPlugins.filter(p => p.source.includes(key));
      setPlugins(matches);
    } else {
      setPlugins(allPlugins);
    }
  };

  const editHandler = (plugin) => {
    setEditImage(!(plugin?.imageSrc));
    setSource(plugin.source);
    setWebsite(plugin.website);
    setPreviewImage(plugin?.imageSrc);
    setShow({
      show: true,
      action: "edit",
      id: plugin._id,
    });
  };

  useEffect(() => {
    fetchPlugins();
  }, []);

  return (
    <>
      <Modal show={show.show}>
        <Modal.Header>
          <Modal.Title>{startCase(show.action)} Plugin</Modal.Title>
          <Button
            onClick={() => {
              setSource("");
              setWebsite("");
              setImage(null);
              setShow({
                show: false,
                action: "create",
              });
            }}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>
        <Modal.Body>
          {showAlert ? (
            <Alert variant="danger">
              <Button
                onClick={() => setShowAlert(false)}
                className="btn-close"
                variant=""
              >
                <span aria-hidden="true">×</span>
              </Button>
              <Alert.Heading>Fields are required</Alert.Heading>
            </Alert>
          ) : null}
          <FormGroup>
            <Form.Label>
              Source <span className="text-red">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              placeholder="Input source | brand name"
              name="source"
              value={source}
              onChange={(e) => setSource(e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <Form.Label>
              Website <span className="text-red">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              placeholder="Input website"
              name="website"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
            />
          </FormGroup>
          <Form.Group>
            <Form.Label>
              Brand Icon 
            </Form.Label>
            {show.action === "create" ? (
              <input
                className="form-control"
                type="file"
                id="formFile"
                name=""
                onChange={(event) => {
                  setImage(event.target.files[0]);
                }}
              />
            ) : editImage ? (
              <>
                <input
                  className="form-control"
                  type="file"
                  id="formFile"
                  name=""
                  onChange={(event) => {
                    setImage(event.target.files[0]);
                  }}
                />
              </>
            ) : (
              <div
                style={{
                  border: "2px solid #EBEBEB",
                  padding: "10px",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {
                  previewImage && <>
                    <img
                      className="img-fluid"
                      style={{maxWidth: "4rem"}}
                      src={GET_IMAGE(previewImage)}
                      alt="Plugin brand logo"
                    />
                    <Button onClick={() => setEditImage(true)}>Update</Button>
                  </>
                }
                
              </div>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="default"
            className="me-1"
            onClick={() =>
              setShow({
                show: false,
                action: "create",
              })
            }
          >
            Close
          </Button>
          <Button
            variant="primary"
            className="me-1"
            onClick={createPluginHandler}
            disabled={loading}
          >
            {show.action === "create" ? 
            "Create" : 
            "Update"} Plugin
          </Button>
        </Modal.Footer>
      </Modal>

      <Box sx={{ gridArea: "first", marginTop: 5 }}>
        <Card>
          <Card.Header className="card-header"> 
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box className="flex-col">
                <Box className="d-flex flex-row align-items-center">
                  <h3 className="card-title">Plugins | Hub URLs</h3>
                  <Badge
                    bg={noLogos <= 0? "success" : "danger"}
                    className="badge rounded-pill bg-danger-gradient mx-2"
                  >
                    {noLogos <= 0?  "All With Logos": `${noLogos} No Logo${noLogos > 1? 's': ''}`}
                  </Badge> 
                </Box>
                
                <small className="text-muted">
                  Source or Brand Names are not case-sensitive and should be unique. Ideally, use square transparent PNGs for Logos.
                </small>
              </Box>

              <div style={{ display: "flex" }}>
                <FormControl
                  type="text"
                  className="form-control"
                  placeholder="search ..."
                  name="type"
                  onChange={searchHandler}
                />
                <Box ml={2}>
                  <Button
                    className="btn btn-primary-light"
                    variant="primary-light"
                    onClick={() =>
                      setShow({
                        show: true,
                        action: "create",
                      })
                    }
                  >
                    Create
                  </Button>
                </Box>
              </div>
            </Box>
          </Card.Header>

          <Card.Body className="card-body">
            <Row>
              {fetching ? (
                <Loader height="50" logoHeight="100px" />
              ) : !plugins.length ? (
                <Box
                  sx={{
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <h5>No plugins found!</h5>
                </Box>
              ) : (
                plugins.map((plugin, index) => (
                  <Col sm={6} md={4} xl={3} key={index}>
                    <Card
                      style={{
                        boxShadow:
                          "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                      }}
                    >
                      <Card.Header>
                        <h3
                          className="card-title"
                          style={{ textTransform: "capitalize" }}
                        >
                          {plugin.source}
                        </h3>
                        <div className="card-options">
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={() => editHandler(plugin)}
                          >
                            Edit
                          </button>
                        </div>
                      </Card.Header>
                      <Card.Body className="card-body d-flex flex-column align-items-center text-center">
                        <Box width="5rem" height="5rem">
                          {
                            plugin.imageSrc ? 
                            <img
                              className="img-fluid"
                              src={GET_IMAGE(plugin.imageSrc)}
                              alt={plugin.source}
                            /> : <ImageNotSupportedIcon 
                              sx={{fontSize: "5rem"}}
                              onClick={() => editHandler(plugin)}
                            />
                          }
                        </Box>
                        <div className="mb-3">
                          <small>
                            {
                              plugin.website? 
                                <a href={plugin.website} target="_blank" rel="noreferrer">{plugin.website}</a> :
                                <span>NO DEFAULT WEBSITE</span>
                            }
                          </small>
                        </div>
                      </Card.Body>
                      <Card.Footer>
                        <span className="text-muted" fontSize="0.5rem">
                          <small>ID: {plugin._id}</small>
                        </span>
                      </Card.Footer>
                    </Card>
                  </Col>
                ))
              )}
            </Row>
          </Card.Body>
        </Card>
      </Box>
    </>
  );
};

export default PluginMaster;
