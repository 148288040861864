import { useSelector } from "react-redux";

export const UseTickets = () => {
  const tickets = useSelector(state => state.adminReducer?.tickets);
  return tickets;
};

export const UseRecentCompanies = () => {
  const companies = useSelector(state => state.adminReducer?.companies);
  return companies;
};
