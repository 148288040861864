import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  FormControl,
} from "react-bootstrap";
import { Box, Grid } from "@mui/material";

import { GET_COMPANY_INTEGRATIONS, GET_APIDECK_CONNECTIONS, REFRESH_APIDECK_CONNECTIONS } from "../../../api/integration.js";

import { Basiccardheadercolor } from "../../../data/Component/carddata/carddata.js";
import LoadingScreen from "../LoadingScreen/index";
import { startCase } from "lodash";
import { notifySuccess } from "../../../helpers/notifications.js";

const Apideck = () => {
  const [allConns, setAllConns] = useState([]);
  const [conns, setConns] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);


  const getApideckConnections = async () => {
    try {
      setLoading(true);
      const { data: connectionsData } = await GET_APIDECK_CONNECTIONS();
      const { data: activeIntegrations } = await GET_COMPANY_INTEGRATIONS();
      const apideckIntegrations = activeIntegrations.filter(i => i.config?.code === 'apideck' );
      
      // Count overall total and per integration type
      setTotal(apideckIntegrations.length);
      const integCount = {};
      apideckIntegrations.forEach(api => {
        if(api.parameters?.serviceId) {
          const serviceId = api.parameters.serviceId;
          if(integCount[serviceId]) {
            integCount[serviceId] +=1;
          } else {
            integCount[serviceId] = 1;
          }
        }
      });

      for(let c=0; c<connectionsData.length; c+=1) {
        connectionsData[c].count = integCount[connectionsData[c].serviceId] || 0;
      }

      setAllConns(connectionsData);
      setConns(connectionsData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.response);
    }
  };

  const searchHandler = async (e) => {
    const key = e.target.value.trim().toLowerCase();
    const kpis = allConns.filter((k) => k.name.toLowerCase().indexOf(key) >= 0 || k.unifiedApi.toLowerCase().indexOf(key) >= 0);
    setConns(kpis);
  };

  useEffect(() => {
    getApideckConnections();
  }, []);

  const refreshList = () => {
    REFRESH_APIDECK_CONNECTIONS()
      .then(() => {
        notifySuccess("Apideck Connections list was successfully refreshed.")
        getApideckConnections();
      });
  };

  return (
    <>
      <Box sx={{ gridArea: "first", marginTop: 5 }}>
        <Card>
          <Card.Header className="card-header">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="flex-col">
                <h2 className="card-title">Apideck Integrations - Total: {total}</h2>
                <small className="text-muted">
                  Integrations must first be enabled and configured in {" "}
                  <a 
                    href="https://platform.apideck.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Apideck Dashboard
                  </a>
                  .
                </small>
              </div>
              <div className="d-flex justify-content-between">
                {
                  <FormControl
                    type="text"
                    placeholder="Search name or type..."
                    name="type"
                    // value={source}
                    onChange={searchHandler}
                  />
                }
              </div>
            </Box>
          </Card.Header>    
          <Card.Body className="card-body">
            <Grid container spacing={2}>
            {
              conns.map(conn => (
                <Grid item key={conn.name} xs={12} sm={6} md={4}>
                  <Basiccardheadercolor 
                    title={startCase(conn.name)}
                    footer={<p><strong>Service ID: {conn.serviceId}</strong></p>}
                  >
                    <div className="d-flex justify-content-between">
                      <div>
                        <p className="mb-2">API Type: {conn.unifiedApi}</p>
                        <p className="mb-2">Total Connections: {conn.count}</p>
                      </div>
                      <img src={conn.logo} alt={conn.name} height="50" />
                    </div>

                  </Basiccardheadercolor>

                </Grid>
              ))
            }
            </Grid>
          </Card.Body>
          {
            loading && <LoadingScreen />
          }
          <Card.Footer>
              <Box className="d-flex flex-column">
                <Button
                  className="btn btn-primary-light"
                  variant="primary-light"
                  onClick={refreshList}
                >
                  FETCH APIDECK LIST
                </Button>
                <Box className="d-flex justify-content-end text-red">
                  <span>* Note: Fetching latest Apideck list consumes one API call</span>
                </Box>
              </Box>
          </Card.Footer>
        </Card>
      </Box>
    </>
  );
};

export default Apideck;
