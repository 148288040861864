import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "../pages/HomePage";
import LoginPage from "../pages/Login";
import ProtectedRoute from "./ProtectedRoutes";
import routes from "./routes";
import zanexRoutes from "./zanexRoutes";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<ProtectedRoute redirectPath="/login" />}>
          <Route path="/" element={<HomePage />}>
            {
              routes.map(({ key, path, element: Element }) => (
                <Route key={key} path={path} element={<Element />} />
              ))
            }
          </Route>
        </Route>
        <Route path="/login" element={<LoginPage />} />
        <Route path = "/zanex" element={<HomePage />}>
          {
             zanexRoutes.map(({ key, path, element: Element }) => (
              <Route key={"zanex-" + key} path={"/zanex" + path} element={<Element />} />
            ))
          }
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
