import React, { useState, useEffect } from "react";
import moment from "moment";
import { Button, Tabs, Tab } from "react-bootstrap";
import { Grid } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { getRandomCode } from "../../../helpers/code";
import { PUT_AFFILIATE } from "../../../api/affiliate";
import { notify } from "../../../helpers/notifications.js";
import FormikController from "../Formik/FormikController.js";
import { startCase } from "lodash";

const AffiliatesForm = ({
  affiliates,
  users,
  config = null,
  onSave,
  onCancel,
}) => {
  const [action] = useState(config ? "update" : "register");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const arenaConfig = config?.arena?.[0] ?? null;
  const [arenaOn, setArenaOn] = useState(arenaConfig ? 1 : 0);

  const initialValues = {
    userId: config?.userId || "",
    code: config?.code || getRandomCode().toUpperCase(),
    description: config?.description || "",
    level: config?.level ?? 0,
    hasChallenge: !!arenaConfig,
    challenge: arenaConfig
      ? {
          title: arenaConfig.title || "King of the Mountain",
          subtitle: arenaConfig.subtitle || "5 Star Reviews Challenge",
          startDate: arenaConfig.startDate
            ? moment(arenaConfig.startDate).format("YYYY-MM-DD")
            : null,
          endDate: arenaConfig.startDate
            ? moment(arenaConfig.endDate).format("YYYY-MM-DD")
            : null,
          yetiPoints: arenaConfig.yetiPoints || "",
        }
      : null,
  };

  const validationSchema = Yup.object({
    userId: Yup.string().required("User is required."),
    code: Yup.string()
      .required("Affiliate code is required.")
      .matches(/^[A-Z0-9-]+$/, "No spaces and uppercase alpha-numeric only.")
      .test("unique", "Code already used.", (value) => {
        if (value) {
          const key = value.trim().toUpperCase();
          if (config?.code === key) {
            return true;
          }
          const idx = affiliates.findIndex((a) => a.code === key);
          return idx < 0;
        }
      }),
    description: Yup.string(),
    challenge: Yup.object().when(["hasChallenge"], {
      is: true,
      then: Yup.object({
        title: Yup.string().required("Challenge arena title is required."),
        subtitle: Yup.string(),
        startDate: Yup.date(),
        endDate: Yup.date().when(
          "startDate",
          (startDate, schema) =>
            startDate &&
            schema.min(startDate, "End date must be greater than start date."),
        ),
        yetiPoints: Yup.number()
          .typeError("Yeti Points must be a numeric value")
          .min(0.01, "Yeti Points must be at least 0.01")
          .max(100, "Yeti Points cannot exceed 100")
          .nullable(),
      }),
      otherwise: Yup.object().notRequired(),
    }),
  });

  const createHandler = async (affiliate, { resetForm }) => {
    setLoading(true);
    affiliate.code = affiliate.code.trim().toUpperCase();
    PUT_AFFILIATE(affiliate)
      .then(() => {
        notify(`${startCase(affiliate.code)} was saved.`);
        setLoading(false);
        resetForm();
        onSave?.(affiliate.level);
      })
      .catch((err) => {
        notify(err.response || err, "error");
        setLoading(false);
      });
  };

  useEffect(() => {
    const userOptions = [];
    if (config) {
      const { firstName, lastName, email } = users[config.userId];
      userOptions.push({
        value: config.userId,
        label: `${email} - ${firstName} ${lastName ? lastName : ""}`,
      });
    } else {
      Object.keys(users).forEach((id) => {
        if (users[id]?.isAffiliate === false) {
          const { firstName, lastName, email } = users[id];
          userOptions.push({
            value: id,
            label: `${email} - ${firstName} ${lastName ? lastName : ""}`,
          });
        }
      });
    }
    userOptions.sort((a, b) => a.label.localeCompare(b.label));
    setOptions(userOptions);
  }, [affiliates, users, config]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={createHandler}
      >
        {(formik) => (
          <Form className="px-2 pb-4">
            <h4 className="text-center">
              {startCase(action)} an existing User as an Affiliate
            </h4>
            <Grid container className="py-2">
              <Grid item xs={12} className="px-2 mb-4">
                <FormikController
                  control="select"
                  label="User"
                  name="userId"
                  className="px-2 w-full"
                  options={options}
                  disabled={config !== null}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} className="px-2 mb-4">
                <FormikController
                  control="input"
                  type="text"
                  label="Affiliate Code"
                  name="code"
                  className="px-2"
                  style={{ textTransform: "uppercase" }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} className="px-2 mb-4">
                <FormikController
                  control="select"
                  label="Level"
                  name="level"
                  className="px-2"
                  options={[
                    { label: "Freemium", value: 0 },
                    { label: "Partner", value: 1 },
                  ]}
                  required
                />
              </Grid>
              <Grid item xs={12} className="px-4 mb-4">
                <FormikController
                  control="input"
                  type="text"
                  label="Description"
                  name="description"
                  rows={4}
                />
              </Grid>

              <Grid
                item
                xs={12}
                className="px-2 mx-4 mb-4 tab-menu-heading border"
              >
                <h5>Challenge Arena Configuration</h5>
                <div className="tabs-menu1 tabstyle2">
                  <Tabs
                    variant="pills"
                    activeKey={arenaOn}
                    className="panel-tabs"
                    onSelect={(key) => {
                      formik.setFieldValue("hasChallenge", !!key);
                      setArenaOn(key);
                    }}
                  >
                    {!arenaConfig && (
                      <Tab eventKey={0} className="me-1" title="No Arena" />
                    )}
                    <Tab
                      eventKey={1}
                      className="me-1"
                      title="With Challenge Arena"
                    >
                      <Grid container>
                        <Grid item xs={10} className="px-2 mb-4 mt-4">
                          <FormikController
                            control="input"
                            type="text"
                            label="Title"
                            name="challenge.title"
                            required
                          />
                        </Grid>
                        <Grid item xs={10} className="px-2 mb-4">
                          <FormikController
                            control="input"
                            type="text"
                            label="Subtitle"
                            name="challenge.subtitle"
                          />
                        </Grid>
                        <Grid item xs={5} className="px-2 mb-4">
                          <FormikController
                            control="date"
                            label="Start Date"
                            name="challenge.startDate"
                          />
                        </Grid>
                        <Grid item xs={5} className="px-2 mb-4">
                          <FormikController
                            control="date"
                            label="End Date"
                            name="challenge.endDate"
                          />
                        </Grid>
                        <Grid item xs={10} className="px-2 mb-4">
                          <FormikController
                            control="input"
                            type="number"
                            label="Daily Yeti Points"
                            name="challenge.yetiPoints"
                            helperText="Assigning any non-zero value will add Yeti to the arena, generating the corresponding daily points."
                          />
                        </Grid>
                        <Grid item xs={10} className="px-2 mb-4">
                          <h5 variant="caption">Sample Challenge Arena URL</h5>
                          <p variant="body1">{`${
                            process.env.REACT_APP_FRONTEND
                          }/arena/${encodeURIComponent(
                            formik.values["code"].toLowerCase(),
                          )}`}</p>
                        </Grid>
                      </Grid>
                    </Tab>
                  </Tabs>
                </div>
              </Grid>
            </Grid>

            <div className="modal-footer mt-4 gap-2">
              <Button
                variant=""
                className="btn btn-outline-secondary"
                onClick={() => {
                  formik.resetForm();
                  onCancel?.();
                }}
              >
                <i className="fe fe-trash-2 me-2" />
                Cancel
              </Button>
              <Button variant="primary" disabled={loading} type="submit">
                <i className="fe fe-save me-2" />
                {action === "create" ? "Create" : "Update"}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AffiliatesForm;
