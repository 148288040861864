import { request } from "../helpers/axios";

export const GET_SETTINGS = (level) => {
  return request({
    url: `/settings`,
    method: "GET",
  });
};

export const ADD_SETTINGS = (payload) => {
  return request({
    url: "/settings",
    method: "POST",
    data: payload,
  });
};
