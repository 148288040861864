import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Button, Row, Col, Tabs, Tab } from "react-bootstrap";
import { Box } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { PUT_AFFILIATE } from "../../../api/affiliate.js";
import FormikController from "../Formik/FormikController.js";
import { notifySuccess, notifyError } from "../../../helpers/notifications.js";
import { getRandomCode } from "../../../helpers/code";

const LevelOneAffiliate = () => {
  const [loading, setLoading] = useState(false);
  const [arenaOn, setArenaOn] = useState(0);
  const navigate = useNavigate();

  // Formik
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    code: getRandomCode().toUpperCase(),
    hasChallenge: false,
    challenge: {
      title: "King of the Mountain",
      subtitle: "5 Star Reviews Challenge",
      startDate: null,
      endDate: null,
      yetiPoints: null,
    },
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string().email().required("Required"),
    code: Yup.string()
      .required("Required")
      .matches(/^[A-Z0-9-]+$/, "No spaces and uppercase alpha-numeric only."),
    challenge: Yup.object().when(["hasChallenge"], {
      is: true,
      then: Yup.object({
        title: Yup.string().required("Challenge arena title is required."),
        subtitle: Yup.string(),
        startDate: Yup.date(),
        endDate: Yup.date().when(
          "startDate",
          (startDate, schema) =>
            startDate &&
            schema.min(startDate, "End date must be greater than start date."),
        ),
        yetiPoints: Yup.number()
        .typeError("Yeti Points must be a numeric value")
        .min(0.01, "Yeti Points must be at least 0.01")
        .max(100, "Yeti Points cannot exceed 100")
        .nullable(),
      }),
      otherwise: Yup.object().notRequired(),
    }),
  });

  const createHandler = async (formDetails, { resetForm }) => {
    setLoading(true);
    try {
      const payload = {
        ...formDetails,
        level: 1,
      };
      await PUT_AFFILIATE(payload);

      notifySuccess("Affiliate created successfully");
      navigate("/affiliates/view/partner");
      resetForm({});
    } catch (err) {
      console.log(err.response);
      notifyError(err?.response?.data.message);
    }
    setLoading(false);
  };

  return (
    <Box sx={{ gridArea: "first", marginTop: 5 }}>
      <Card>
        <Card.Header className="card-header d-flex justify-content-between">
          <h3 className="card-title">Create Partner Affiliate</h3>
          <Button
            className="btn btn-primary-light w-25"
            variant="primary-light"
            onClick={() => navigate("/affiliates/view/partner")}
          >
            VIEW CURRENT PARTNERS
          </Button>
        </Card.Header>
        <Card.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={createHandler}
          >
            {(formik) => (
              <Form>
                <Row>
                  <Col sm={12} md={6} xl={6} className="mb-3">
                    <FormikController
                      control="input"
                      type="text"
                      label="First Name"
                      name="firstName"
                      required
                    />
                  </Col>
                  <Col sm={12} md={6} xl={6} className="mb-3">
                    <FormikController
                      control="input"
                      type="text"
                      label="Last Name"
                      name="lastName"
                      required
                    />
                  </Col>
                  <Col sm={12} md={6} xl={6} className="mb-3">
                    <FormikController
                      control="input"
                      type="text"
                      label="Email"
                      name="email"
                      required
                    />
                  </Col>
                  <Col sm={12} md={6} xl={6} className="mb-3">
                    <FormikController
                      control="input"
                      type="text"
                      label="Affiliate Code"
                      style={{ textTransform: "uppercase" }}
                      name="code"
                      required
                    />
                  </Col>
                  <Col
                    xs={12}
                    className="px-2 mx-4 mb-4 tab-menu-heading border"
                  >
                    <h5>Challenge Arena Configuration</h5>
                    <div className="tabs-menu1 tabstyle2">
                      <Tabs
                        variant="pills"
                        activeKey={arenaOn}
                        className="panel-tabs"
                        onSelect={(key) => {
                          formik.setFieldValue("hasChallenge", !!key);
                          setArenaOn(key);
                        }}
                      >
                        <Tab eventKey={0} className="me-1" title="No Arena" />
                        <Tab
                          eventKey={1}
                          className="me-1"
                          title="With Challenge Arena"
                        >
                          <Row className="px-4">
                            <Col xs={10} className="px-2 mb-4 mt-4">
                              <FormikController
                                control="input"
                                type="text"
                                label="Title"
                                name="challenge.title"
                                required
                              />
                            </Col>
                            <Col xs={10} className="px-2 mb-4">
                              <FormikController
                                control="input"
                                type="text"
                                label="Subtitle"
                                name="challenge.subtitle"
                              />
                            </Col>
                            <Col xs={5} className="px-2 mb-4">
                              <FormikController
                                control="date"
                                label="Start Date"
                                name="challenge.startDate"
                              />
                            </Col>
                            <Col xs={5} className="px-2 mb-4">
                              <FormikController
                                control="date"
                                label="End Date"
                                name="challenge.endDate"
                              />
                            </Col>
                            <Col xs={10} className="px-2 mb-4">
                              <FormikController
                                control="input"
                                type="number"
                                label="Daily Yeti Points"
                                name="challenge.yetiPoints"
                                helperText="Assigning any non-zero value will add Yeti to the arena, generating the corresponding daily points."
                              />
                            </Col>
                            <Col xs={10} className="px-2 mb-4">
                              <h5 variant="caption">
                                Sample Challenge Arena URL
                              </h5>
                              <p variant="body1">{`${
                                process.env.REACT_APP_FRONTEND
                              }/arena/${encodeURIComponent(
                                formik.values["code"].toLowerCase(),
                              )}`}</p>
                            </Col>
                          </Row>
                        </Tab>
                      </Tabs>
                    </div>
                  </Col>
                </Row>

                <Box
                  mt="25px"
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Button
                    variant=""
                    className="btn btn-outline-danger"
                    disabled={loading}
                    onClick={() => {
                      formik.resetForm();
                      navigate(-1);
                    }}
                  >
                    <i className="fe fe-trash-2 me-2" />
                    Cancel
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    variant="primary"
                    className="me-1"
                    disabled={loading}
                    type="submit"
                  >
                    <i className="fe fe-save me-2" />
                    Create
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </Box>
  );
};

export default LevelOneAffiliate;
