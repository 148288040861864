import React from "react";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";

const IndustryTable = ({ industries }) => {
  const columns = [
    {
      name: "Industry Name",
      selector: (row) => row.name?.trim() ? row.name : "N/A",
      sortable: true,
    },
  ];

  return (
    <div className="table remove-filter-input">
      <DataTable
        columns={columns}
        data={industries}
        noHeader
        defaultSortField="id"
        defaultSortAsc={false}
        striped
        center
        persistTableHead
        pagination={false}
        highlightOnHover
      />
    </div>
  );
};

export default IndustryTable;
