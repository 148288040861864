import { request } from "../helpers/axios";

export const GET_DEMO_USERS = ({ page = 1, limit = 50 }) => {
  return request({
    url: `/user/demos?page=${page}&limit=${limit}`,
    method: "GET",
  });
};

export const GET_COLD_LEADS = ({ page = 1, limit = 50 }) => {
  return request({
    url: `/user/cold-leads?page=${page}&limit=${limit}`,
    method: "GET",
  });
};

export const GET_STATS = () => {
  return request({
    url: `/user/stats`,
    method: "GET",
  });
};

export const CREATE_DEMO_USER = (data) => {
  return request({
    url: `/auth/register-lead`,
    method: "POST",
    data,
  });
};

export const GET_ROUTE_URL = (data) => {
  return request({
    url: `/auth/route-to-login`,
    method: "POST",
    data,
  });
};

export const DELETE_DEMO_USER = (data) => {
  console.log(data._id);
  return request({
    url: `/auth/remove-lead?userId=${data._id}`,
    method: "DELETE",
    data,
  });
};
