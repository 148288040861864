export const MENUITEMS = [
  {
    menutitle: "REPORTS",
    Items: [
      {
        path: `/dashboard`,
        icon: "home",
        type: "link",
        active: true,
        title: "Main Dashboard",
        badge: "badge bg-success-gradient",
        key: "companies",
      },
      {
        path: `/tickets`,
        icon: "inbox",
        type: "link",
        active: false,
        title: "Tickets",
        badge: "badge bg-danger-gradient",
        key: "tickets",
      },
      {
        path: `/industries`,
        icon: "list",
        type: "link",
        active: false,
        title: "Industries",
        badge: "badge bg-danger-gradient",
        key: "industries",
      },
      {
        path: `/cancel-users`,
        icon: "user",
        type: "link",
        active: false,
        title: "Cancel Requests",
        badge: "badge bg-danger-gradient",
        key: "cancel-user",
      },
    ],
  },
  {
    menutitle: "APP DOMAIN",
    Items: [
      {
        path: `/workspaces`,
        icon: "database",
        type: "link",
        active: false,
        title: "Workspaces",
      },

      {
        path: `/integrations`,
        icon: "airplay",
        type: "sub",
        active: false,
        title: "Integrations",
        children: [
          {
            path: `/integrations/apideck`,
            title: "Apideck",
            type: "link",
          },
        ],
      },
      {
        path: `/kpi/models`,
        icon: "pie-chart",
        type: "sub",
        active: false,
        title: "KPIs",
        children: [
          {
            path: `/kpi/models/view`,
            title: "Models | Data Cubes",
            type: "link",
          },
          {
            path: `/kpi/integrations`,
            title: "Integrations",
            type: "link",
          },
        ],
      },
      {
        path: `/master-badge/create`,
        icon: "award",
        type: "sub",
        active: false,
        title: "Badges",
        children: [
          {
            path: `/master-badge/create`,
            title: "Create",
            type: "link",
          },
          {
            path: `/master-badge/manage`,
            title: "Manage",
            type: "link",
          },
        ],
      },
      {
        path: `/widgets/create`,
        icon: "grid",
        type: "sub",
        active: false,
        title: "Widgets",
        children: [
          {
            path: `/widgets/create`,
            title: "Create",
            type: "link",
          },
          {
            path: `/widgets/manage`,
            title: "Manage",
            type: "link",
          },
        ],
      },
      {
        path: `/plugin-master`,
        icon: "cloud-lightning",
        type: "link",
        active: false,
        title: "Plugins",
      },
    ],
  },
  {
    menutitle: "ACCOUNTS & MARKETING",
    Items: [
      /* { // Merged subscription and companies
        path: `/subcription`,
        icon: "file-plus",
        type: "link",
        active: false,
        title: "Subscriptions",
      }, */
      {
        path: `/companies`,
        icon: "database",
        type: "link",
        active: false,
        title: "Companies",
      },
      {
        title: "Challenge Arena",
        icon: "sunrise",
        type: "sub",
        active: false,
        children: [
          {
            path: `/challenge-arena/create`,
            title: "Create",
            type: "link",
          },
          {
            path: "/challenge-arena",
            title: "Manage",
            type: "link",
          },
        ],
      },
      {
        title: "Jetpacks",
        icon: "battery-charging",
        type: "sub",
        active: false,
        children: [
          {
            path: "/jetpacks/list",
            title: "List",
            type: "link",
          },
          {
            path: `/jetpacks/create`,
            title: "Create",
            type: "link",
          },
        ],
      },
      {
        title: "Prospects",
        icon: "crosshair",
        type: "sub",
        active: false,
        children: [
          {
            path: "/prospects/leads",
            title: "Leads",
            type: "link",
          },
          {
            path: "/prospects/cold-leads",
            title: "Cold Leads",
            type: "link",
          },
        ],
      },
      {
        path: `/affiliates`,
        icon: "shopping-bag",
        type: "sub",
        active: false,
        title: "Affiliates",
        children: [
          {
            path: `/affiliates/view/partner`,
            title: "Partners",
            type: "link",
          },
          {
            path: `/affiliates/view/freemium`,
            title: "Freemium Users",
            type: "link",
          },
        ],
      },
      /* // Removing Demo Accounts menu, as it is not implemented anymore in marketing side
      {
        path: `/demo`,
        icon: "tv",
        type: "link",
        active: false,
        title: "Demo Accounts",
      }, */
    ],
  },
  {
    menutitle: "MASTER SETTINGS",
    Items: [
      {
        path: `/restrictions`,
        icon: "alert-triangle",
        type: "link",
        active: false,
        title: "Impose Restrictions",
      },
      {
        path: `/settings`,
        icon: "settings",
        type: "link",
        active: false,
        title: "Global Settings",
      },
    ],
  },
];
