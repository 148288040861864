// Zanex routes to view preset pages and elements

import Search from "../components/Advanced-Elements/Search/Search";
import Login from "../components/CustomPages/Login/Login";

const routes = [
  {
    key: "login",
    path: "/login",
    element: Login,
  },
  {
    key: "search",
    path: "/search",
    element: Search,
  },
];

export default routes;