import * as Yup from "yup";

const ModelSchema = Yup.object({
  type: Yup.string().required("Type is required."),
  direction: Yup.mixed()
    .oneOf(
      ["1", "-1"],
      "Value can either be 1 (Positive KPI) or -1 (Negative KPI)",
    )
    .required("Directions is required."),
  defaultTarget: Yup.number().required("Default target is required."),
  defaultPointScale: Yup.number().required("Default point scale is required."),
  defaultInputs: Yup.object().nullable(),
  kind: Yup.mixed()
    .oneOf(["$", "#", "%"], "Either $ (Monetary), % (Percentage), # (Item)")
    .required("KPI value kind is required"),
});

export default ModelSchema;
