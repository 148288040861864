import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { Box, Pagination } from "@mui/material";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";

import {
  GET_COMPANIES,
  GET_SUBSCRIPTION,
  CREATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
} from "../../../api/subscription.js";
import Loader from "../LoadingScreen/index";

const Subscriptions = () => {
  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState(null);
  const [fetching, setFetching] = useState(false);

  const fetchCompanies = async (event, value) => {
    setFetching(true);
    try {
      const response = await GET_COMPANIES({ page: value });
      const subscriptionResponse = await GET_SUBSCRIPTION();

      const companies = response.data.docs.map((item) => {
        const isAvailable = subscriptionResponse.data.find(
          (el) => el.companyId === item._id,
        );

        if (isAvailable) {
          return {
            ...item,
            betaTest: true,
            toggled: isAvailable.level === 0 ? false : true,
            level: isAvailable.level,
          };
        } else {
          return {
            ...item,
            toggled: false,
            betaTest: false,
          };
        }
      });

      setPaginationData(response.data);
      setData(companies);
    } catch (err) {
      console.log(err.response);
    }
    setFetching(false);
  };

  const triggerSwitch = async (e, row) => {
    const companies = data.map((item) => {
      if (item._id === row._id) {
        return {
          ...item,
          toggled: !item.toggled,
          betaTest: true,
        };
      } else {
        return item;
      }
    });

    setData(companies);
    try {
      if (!row.betaTest) {
        await CREATE_SUBSCRIPTION(row);
      } else {
        const level = {
          level: e.target.checked ? 1 : 0,
        };

        await UPDATE_SUBSCRIPTION(row._id, level);
      }
    } catch (err) {
      console.log(err.response);
    }
  };

  const columns = [
    {
      name: "Business Name",
      selector: (row) => [row.businessName ? row.businessName : "N/A"],
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) => [row.phoneNumber ? row.phoneNumber : "N/A"],
      sortable: true,
    },
    {
      name: "Industry",
      selector: (row) => [row.industry ? row.industry : "N/A"],
      sortable: true,
    },
    {
      name: "Country",
      selector: (row) => [row.country ? row.country : "N/A"],
      sortable: true,
    },
    {
      name: "State",
      selector: (row) => [row.state ? row.state : "N/A"],
      sortable: true,
    },
    {
      name: "Website URL",
      selector: (row) => [row.websiteUrl ? row.websiteUrl : "N/A"],
      sortable: true,
    },
    {
      name: "Beta Test",
      cell: (row) => (
        <label className="custom-switch ps-0">
          <input
            type="checkbox"
            name="custom-switch-checkbox"
            className="custom-switch-input"
            checked={row.toggled}
            onChange={(e) => triggerSwitch(e, row)}
          />
          <span className="custom-switch-indicator"></span>
        </label>
      ),
    },
  ];

  const tableData = {
    columns,
    data,
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  return (
    <Box sx={{ gridArea: "first", marginTop: 5 }}>
      <Card>
        <Card.Header className="card-header">
          <h3 className="card-title">Companies</h3>
        </Card.Header>
        <Card.Body>
          {fetching ? (
            <Loader height="50" logoHeight="100px" />
          ) : !data.length ? (
            <Box
              sx={{
                width: "100%",
                textAlign: "center",
              }}
            >
              <h5>No Companies found!</h5>
            </Box>
          ) : (
            <div className="table">
              <DataTableExtensions {...tableData}>
                <DataTable
                  columns={columns}
                  data={data}
                  noHeader
                  defaultSortField="id"
                  defaultSortAsc={false}
                  striped={true}
                  center={true}
                  persistTableHead
                  pagination={false}
                  highlightOnHover
                />
              </DataTableExtensions>
            </div>
          )}
          <Pagination
            count={paginationData?.totalPages}
            onChange={fetchCompanies}
          />
        </Card.Body>
      </Card>
    </Box>
  );
};

export default Subscriptions;
