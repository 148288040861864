import React, { useEffect, useState } from "react";
import { Card, Button, FormControl } from "react-bootstrap";
import { Box, Pagination } from "@mui/material";
import CompanyTable from "./CompanyTable";

import { GET_ALL_COMPANIES } from "../../../api/company";
import LoadingScreen from "../LoadingScreen/index.jsx";

const LIMIT = 50;

const Companies = () => {
  const [companies, setCompanies] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(null);

  const getNewCompanies = async (page = 1) => {
    setLoading(true);
    try {
      const { data } = await GET_ALL_COMPANIES({
        page,
        limit: LIMIT,
        ...(filter && { filter: filter.trim() }),
      });
      const { docs, total } = data;
      setCompanies(docs);
      setTotalPages(Math.ceil(total / LIMIT));
    } catch (err) {
      console.log(err.response);
    }
    setLoading(false);
  };

  const handleChange = async (event, value) => {
    getNewCompanies(value);
  };

  useEffect(() => {
    getNewCompanies();
  }, []);

  return (
    <Box sx={{ gridArea: "first", marginTop: 5 }}>
      <Card>
        <Card.Header className="card-header d-flex justify-content-between">
          <h3 className="card-title">Companies</h3>
          <Box className="d-flex w-50">
            <FormControl
              type="text"
              placeholder="Search company name ..."
              name="type"
              className="me-2"
              onChange={(e) => setFilter(e.target.value)}
            />
            <Button
              className="btn btn-primary-light w-50"
              variant="primary-light"
              onClick={() => getNewCompanies(1)}
            >
              <i className="icon icon-magnifier me-2" /> Search
            </Button>
          </Box>
        </Card.Header>
        <Card.Body className="card-body">
          {loading ? (
            <LoadingScreen />
          ) : !companies?.length ? (
            <Box
              sx={{
                width: "100%",
                textAlign: "center",
              }}
            >
              <h5>No Companies found!</h5>
            </Box>
          ) : (
            <CompanyTable companies={companies} />
          )}
          {totalPages > 1 && (
            <Pagination count={totalPages} onChange={handleChange} />
          )}
        </Card.Body>
      </Card>
    </Box>
  );
};

export default Companies;
