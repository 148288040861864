import React from "react";
import { Field, ErrorMessage } from "formik";
import { FormLabel } from "react-bootstrap";

import Error from "./Error.js";

const Select = (props) => {
  const { name, label, options, className, required, helperText, placeholder, ...rest } = props;

  return (
    <div className={`formik-controller ${className}`}> 
      {
        label && <FormLabel style={{ display: "block" }} htmlFor={name}>
          {label} { required && <span className="text-red">*</span> }
        </FormLabel>
      }
      {
        helperText && <span className="text-muted">{helperText}</span>
      }
      {
        options && options.length ? 
          <Field as="select" name={name} className="form-select" {...rest}>
            <option value="">{placeholder || `Select ${label || "item"}`}</option>
            {
              options.map(({label, value}) => (
                <option key={value} value={value}>{label}</option>
              ))
            }
          </Field>
        : <h6 className="py-4 text-muted">No options given</h6>
      }
      <ErrorMessage name={name} component={Error} />
    </div>
  );
};

export default Select;
