import React from "react";
import Header2 from "../layouts/Header2/Header2";
import Sidebar from "../layouts/SideBar/SideBar";
import Switcher from "../layouts/Switcher/Switcher";
import RightSidebar from "../layouts/RightSidebar/RightSidebar";
import TabToTop from "../layouts/TabToTop/TabToTop";
import { Outlet } from "react-router-dom";

export default function HomePage() {
  return (
    <>
      <div className="horizontalMenucontainer">
        <TabToTop />
        <div className="page">
          <div className="page-main">
            <Header2 />
            <Sidebar />
            <div className="main-content app-content ">
              <div className="side-app">
                <div
                  className="main-container container-fluid"
                  // onClick={() => {
                  //   Switcherdata.responsiveSidebarclose();
                  //   Switcherdata.Horizontalmenudefultclose();
                  // }}
                >
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
          <RightSidebar />
          <Switcher />
        </div>
      </div>
    </>
  );
}
