import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Col, FormControl, Row } from "react-bootstrap";
import { Box } from "@mui/material";
import moment from "moment";
import { Pagination } from "@mui/material";
import DataTable from "react-data-table-component";

import {
  GET_DEMO_USERS,
  GET_COLD_LEADS,
  DELETE_DEMO_USER,
  GET_ROUTE_URL,
} from "../../../api/user.js";
import { GET_IMAGE } from "../../../helpers/images.js";
import "./style.css";
import LoadingScreen from "../LoadingScreen/index";
import StatsWithPieChart from "./StatsWithPieChart.jsx";
import { notifySuccess, notifyError } from "../../../helpers/notifications.js";
import BLANK_USER from "../../../assets/images/users/blankPic.png";

const ManageColdLeads = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [loginUsers, setLoginUsers] = useState([]);
  const [brandedUsers, setBrandedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationData, setPaginationData] = useState(null);
  const navigate = useNavigate();
  const urlParams = useParams();
  const { mode } = urlParams;

  const getDemoUsers = async (page = 0) => {
    try {
      setLoading(true);
      let response = null;

      if (mode === "leads") {
        response = await GET_DEMO_USERS({ page, limit: 50 });
      } else {
        response = await GET_COLD_LEADS({ page, limit: 50 });
      }

      if (response) {
        const { data: usersData } = response;

        const logins = usersData.docs.filter((item) => item.logins > 0);
        const branded = usersData.docs.filter(
          (item) => item.primaryColor || item.companyLogo,
        );

        setLoginUsers(logins);
        setBrandedUsers(branded);
        setAllUsers(usersData.docs);
        setPaginationData(usersData);
        setUsers(usersData.docs);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.response);
    }
  };

  const searchHandler = async (e) => {
    const key = e.target.value.trim().toLowerCase();
    const filteredUsers = allUsers.filter(
      (k) =>
        k.firstName?.toLowerCase().indexOf(key) >= 0 ||
        k.lastName?.toLowerCase().indexOf(key) >= 0 ||
        k.email?.toLowerCase().indexOf(key) >= 0 ||
        k.companyName?.toLowerCase().indexOf(key) >= 0,
    );
    setUsers(filteredUsers);
  };

  const handleChange = async (event, value) => {
    await getDemoUsers(value);
  };

  useEffect(() => {
    getDemoUsers();
  }, [mode]);

  const viewAccount = async ({ username }) => {
    const response = await GET_ROUTE_URL({ username });
    if ((response.statusText = "OK")) {
      console.log(response.data.url);
      window.open(response.data.url, "_blank");
    } else {
      console.error(
        "Failed to get customer URL:",
        response.status,
        response.statusText,
      );
    }
  };

  const columns = [
    {
      name: "PROFILE",
      selector: (row) => [
        <img
          src={row.image ? GET_IMAGE(row.image) : BLANK_USER}
          alt={row.firstName + " " + row.lastName}
          className="avatar avatar-md cover-image"
          onClick={() => viewAccount(row)}
        />,
      ],
      grow: 0,
      sortable: true,
    },
    {
      name: "NAME",
      selector: (row) => [
        row.firstName + (row.lastName ? " " + row.lastName : ""),
      ],
      sortable: true,
    },
    {
      name: "EMAIL",
      selector: (row) => [row.email ? row.email : "N/A"],
      grow: 2,
      sortable: true,
    },
    {
      name: "COMPANY",
      selector: (row) => [row.companyName ? row.companyName : "N/A"],
      sortable: true,
    },
    {
      name: "LOGO",
      selector: (row) => [
        row.companyLogo ? (
          <img
            src={GET_IMAGE(row.companyLogo)}
            alt={row.companyName || "Company"}
            className="avatar avatar-md cover-image"
          />
        ) : null,
      ],
      grow: 0,
      sortable: true,
    },
    {
      name: "LOGINS",
      selector: (row) => [row.logins ? row.logins : "0"],
      grow: 0,
      sortable: true,
    },
    {
      name: "COLOR",
      selector: (row) => [
        row.primaryColor ? (
          <span
            className="tag text-white"
            style={{ backgroundColor: row.primaryColor }}
          >
            {row.primaryColor}
          </span>
        ) : (
          "-"
        ),
      ],
      sortable: true,
    },
    {
      name: "DATE",
      selector: (row) => {
        if (row.createdAt) {
          const createdAt = moment(row.createdAt);
          const isRecent = moment().diff(createdAt, "days") <= 7;
          return [
            <span
              className={`tag ${isRecent? 'tag-success' : ''}`}
            >
              {createdAt.format("YYYY-MM-DD")}
            </span>,
          ];
        }
        return "";
      },
      sortable: true,
    },
    ...(mode !== "leads"
      ? [
          {
            name: "Created By",
            selector: (row) => [row.createdBy?.firstName],
            sortable: true,
          },
        ]
      : []),
    {
      name: "Action",
      selector: (row) => [
        <Button
          variant=""
          className="btn btn-outline-danger"
          disabled={loading}
          onClick={() => {
            removeLead(row);
          }}
        >
          <i className="fe fe-trash-2" />
        </Button>,
      ],
      sortable: false,
    },
  ];

  const removeLead = async (data) => {
    try {
      setLoading(true);
      console.log(data);

      await DELETE_DEMO_USER(data);

      const filteredUsers = users.filter((user) => user._id !== data._id);

      setUsers(filteredUsers);
      setLoading(false);
      notifySuccess("Demo Account removed");
    } catch (error) {
      setLoading(false);
      notifyError(error.response.data.message);
    }
  };

  return (
    <Box sx={{ gridArea: "first", marginTop: 2 }}>
      <Row>
        <Col xs={12} lg={6}>
          <Card>
            <Card.Body>
              <h4 className="card-title">Demo Login Stats</h4>
              <div className="chart-container">
                {!loading && (
                  <StatsWithPieChart
                    labels={["Total Users", "Uers who Logged In"]}
                    series={[users?.length, loginUsers?.length]}
                    height={200}
                    colors={["#165BAA", "#1DDD8D"]}
                  />
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} lg={6}>
          <Card>
            <Card.Body>
              <h4 className="card-title">Demo Branding Stats</h4>
              <div className="chart-container">
                {!loading && (
                  <StatsWithPieChart
                    labels={["Total Users", "Users who Branded Account"]}
                    series={[users?.length, brandedUsers?.length]}
                    height={200}
                    colors={["#165BAA", "#F765A3"]}
                  />
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Card>
        <Card.Header className="card-header">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div className="flex-fill">
              <h2 className="card-title">Cold Leads List</h2>
              <p className="card-subtitle mt-1">
                Click Profile to view account
              </p>
            </div>
            <div className="d-flex justify-content-between flex-fill gap-2">
              <FormControl
                type="text"
                placeholder="Search name, email or company name ..."
                name="type"
                onChange={searchHandler}
              />
              <Button
                className="btn btn-primary-light w-20"
                variant="primary-light"
                onClick={() => navigate("/prospects/create")}
              >
                <i className="icon icon-user-follow me-2" /> Create
              </Button>
            </div>
          </Box>
        </Card.Header>
        <Card.Body className="card-body">
          {loading ? (
            <LoadingScreen />
          ) : !users.length ? (
            <Box
              sx={{
                width: "100%",
                textAlign: "center",
              }}
            >
              <h5>No demo accounts found!</h5>
            </Box>
          ) : (
            <div className="table remove-filter-input">
              <DataTable
                columns={columns}
                data={users}
                noHeader
                defaultSortField="id"
                defaultSortAsc={false}
                striped={true}
                center={true}
                persistTableHead
                pagination={false}
                highlightOnHover
              />
            </div>
          )}

          <Pagination
            count={paginationData?.totalPages}
            onChange={handleChange}
          />
        </Card.Body>
      </Card>
    </Box>
  );
};

export default ManageColdLeads;
