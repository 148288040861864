import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  FormLabel,
  Row,
  Col,
  Form as BootstrapForm,
} from "react-bootstrap";
import { Box } from "@mui/material";
import { Formik, Form } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";

import FormikController from "../Formik/FormikController.js";
import { UPDATE_MASTER_BADGE, UPLOAD_GRAPHICS } from "../../../api/badge.js";
import { notifySuccess, notifyError } from "../../../helpers/notifications.js";

const EditMasterBadge = () => {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const { state } = useLocation();
  const navigate = useNavigate();

  // Formik
  const initialValues = {
    kpiId: { value: state?.row.kpiId._id, label: state?.row.kpiId.type },
    title: state?.row.title,
    target: state?.row.target,
  };

  const validationSchema = Yup.object({
    kpiId: Yup.object().nullable().required("Required"),
    target: Yup.number().required("Required"),
    title: Yup.string().required("Required"),
  });

  const createHandler = async (formDetails) => {
    setLoading(true);
    let payload = {};

    try {
      if (image) {
        let formData = new FormData();
        formData.append("image", image);

        const { data } = await UPLOAD_GRAPHICS(formData);

        if (data.key) {
          payload = {
            ...formDetails,
            kpiId: formDetails.kpiId.value,
            image: data.key,
            oldImage: state?.row.image,
            _id: state?.row._id,
          };
        }
      } else {
        payload = {
          ...formDetails,
          kpiId: formDetails.kpiId.value,
          image: state?.row.image,
          _id: state?.row._id,
        };
      }

      await UPDATE_MASTER_BADGE(payload);
      notifySuccess("Badge updated successfully");
      navigate("/master-badge/manage/");
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  return (
    <Box sx={{ gridArea: "first", marginTop: 5 }}>
      <Card>
        <Card.Header className="card-header">
          <h3 className="card-title">Edit Master Badge</h3>
        </Card.Header>
        <Card.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={createHandler}
          >
            {(formik) => (
              <Form>
                <Row>
                  <Col sm={12} md={6} xl={6} className="mb-3">
                    <FormikController
                      control="input"
                      type="text"
                      label="Title"
                      name="title"
                    />
                  </Col>
                  <Col sm={12} md={6} xl={6} className="mb-3">
                    <FormLabel>Industry</FormLabel>
                    <Select
                      name="industry"
                      value={formik.values.kpiId}
                      placeholder="Select KPI"
                      isClearable
                      isLoading={loading}
                      isDisabled
                    />
                  </Col>
                  <Col sm={12} md={6} xl={6} className="mb-3">
                    <FormikController
                      control="input"
                      type="number"
                      label="Target"
                      name="target"
                      readOnly
                    />
                  </Col>
                  <Col sm={12} md={6} xl={6} className="mb-3">
                    <BootstrapForm.Group>
                      <BootstrapForm.Label>Graphics</BootstrapForm.Label>
                      <input
                        className="form-control"
                        type="file"
                        id="formFile"
                        name=""
                        onChange={(event) => {
                          setImage(event.target.files[0]);
                        }}
                      />
                    </BootstrapForm.Group>
                  </Col>
                </Row>
                <Box
                  mt="25px"
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Button
                    variant=""
                    className="btn btn-outline-danger"
                    disabled={loading}
                    onClick={() => {
                      navigate("/master-badge/manage/");
                    }}
                  >
                    <i className="fe fe-trash-2 me-2" />
                    Cancel
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    variant="primary"
                    className="me-1"
                    disabled={loading}
                    type="submit"
                  >
                    <i className="icon icon-pencil me-2" />
                    Update
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </Box>
  );
};

export default EditMasterBadge;
