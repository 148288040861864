import React, { useState, useEffect } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import { Box } from "@mui/material";
import { Link, useParams, useNavigate } from "react-router-dom";

import { GET_CHALLENGE_ARENA_DETAILS } from "../../../api/challenge.js";
import Loader from "../LoadingScreen/index";
import "./style.css";

const ChallengeArenaDetails = () => {
  const [fetching, setFetching] = useState(false);
  const [details, setDetails] = useState({
    challenge: {},
    challengeBoard: [],
  });
  const navigate = useNavigate();

  const { id } = useParams();

  const fetchChallengeDetails = async () => {
    try {
      setFetching(true);
      const { data } = await GET_CHALLENGE_ARENA_DETAILS(id);
      setDetails(data);
      setFetching(false);
    } catch (error) {
      setFetching(false);
      console.log("==========>", error);
    }
  };

  useEffect(() => {
    fetchChallengeDetails();
  }, []);

  return (
    <Box sx={{ gridArea: "first", marginTop: 5 }}>
      {fetching ? (
        <Loader height="50" logoHeight="100px" />
      ) : (
        <Card>
          <Card.Header className="card-header">
            <h3 className="card-title">Challenge Arena Details</h3>
          </Card.Header>
          <Card.Body className="challenge-arena">
            <Row>
              <Col sm="6" md="4" lg="4" className="mb-4">
                <div>
                  <h5>Title</h5>
                </div>
                <div>
                  <h6>{details?.challenge?.title}</h6>
                </div>
              </Col>
              <Col sm="6" md="4" lg="4" className="mb-4">
                <div>
                  <h5>Subtitle</h5>
                </div>
                <div>
                  <h6>
                    {details?.challenge?.subtitle
                      ? details?.challenge?.subtitle
                      : "N/A"}
                  </h6>
                </div>
              </Col>
              <Col sm="6" md="4" lg="4" className="mb-4">
                <div>
                  <h5>Type</h5>
                </div>
                <div>
                  <h6>{details?.challenge?.type}</h6>
                </div>
              </Col>
              <Col sm="6" md="4" lg="4" className="mb-4">
                <div>
                  <h5>Max Players</h5>
                </div>
                <div>
                  <h6>
                    {details?.challenge?.maxPlayers
                      ? details?.challenge?.maxPlayers
                      : 0}
                  </h6>
                </div>
              </Col>
              <Col sm="6" md="4" lg="4" className="mb-4">
                <div>
                  <h5>URL</h5>
                </div>
                <div>
                  <h6>
                    <a
                      href={`${process.env.REACT_APP_FRONTEND}/join/${details?.challenge?.url}`}
                      target="_blank"
                    >{`${process.env.REACT_APP_FRONTEND}/join/${details?.challenge?.url}`}</a>
                  </h6>
                </div>
              </Col>
              <Col sm="6" md="4" lg="4" className="mb-4">
                <div>
                  <h5>Is Private</h5>
                </div>
                <div>
                  <h6>{details?.challenge?.isPrivate ? "Yes" : "No"}</h6>
                </div>
              </Col>
              <Col sm="6" md="4" lg="4" className="mb-4">
                <div>
                  <h5>Description</h5>
                </div>
                <div>
                  <h6>{details?.challenge?.description}</h6>
                </div>
              </Col>
              <Col sm="6" md="4" lg="4" className="mb-4">
                <div>
                  <h5>Challenge Board</h5>
                </div>
                <div>
                  <h6>
                    Total Participants:{" "}
                    {details?.challengeBoard.length
                      ? details?.challengeBoard.length
                      : 0}
                  </h6>
                </div>
              </Col>
            </Row>
            <Row>
              {details?.challengeBoard?.map((board) => (
                <Col sm="6" md="4" lg="4" className="mb-4" key={board._id}>
                  <Card>
                    <Card.Body>
                      <h6>
                        <strong>Business Name:</strong>{" "}
                        {board?.companyId?.businessName
                          ? board?.companyId.businessName
                          : "N/A"}
                      </h6>
                      <h6>
                        <strong>Industry:</strong>{" "}
                        {board?.companyId?.industry
                          ? board?.companyId.industry
                          : "N/A"}
                      </h6>
                      <h6>
                        <strong>Phone No:</strong>{" "}
                        {board?.companyId?.phoneNumber
                          ? board?.companyId.phoneNumber
                          : "N/A"}
                      </h6>
                      <h6>
                        <strong>Country, City:</strong>{" "}
                        {board?.companyId?.country
                          ? board?.companyId.country
                          : "N/A"}
                        ,{" "}
                        {board?.companyId?.city ? board?.companyId.city : "N/A"}
                      </h6>
                      <h6>
                        <strong>Address One:</strong>{" "}
                        {board?.companyId?.addressOne
                          ? board?.companyId.addressOne
                          : "N/A"}
                      </h6>
                      <h6>
                        <strong>Address Two:</strong>{" "}
                        {board?.companyId?.addressTwo
                          ? board?.companyId.addressTwo
                          : "N/A"}
                      </h6>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Card.Body>
        </Card>
      )}
    </Box>
  );
};

export default ChallengeArenaDetails;
