import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Card,
  Button,
} from "react-bootstrap";
import { Box, Grid } from "@mui/material";

import { GET_KPI_MODELS, GET_KPI_INTEGRATIONS } from "../../../api/kpi.js";

import KpiIntegrationsForm from "./IntegrationsForm.jsx";
import { Basiccardheadercolor } from "../../../data/Component/carddata/carddata";
import { Singleselect1s } from "../../../data/Advancedelements/Searchdata/Searchdata";
import LoadingScreen from "../LoadingScreen/index";
import moment from "moment";
import { startCase } from "lodash";

const KpiIntegrations = () => {
  const [allConfigs, setAllConfigs] = useState([]);
  const [kpiIntegrations, setKpiIntegrations] = useState([]);
  const [kpiModels, setKpiModels] = useState([]);
  const [inputMode, setInputMode] = useState(false);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { mode } = useParams();

  const getKpiData = async () => {
    try {
      setLoading(true);
      const { data: models } = await GET_KPI_MODELS();
      const { data: configs } = await GET_KPI_INTEGRATIONS();

      const allModels = models.map(m => ({key: m.type, value: m.type, label: startCase(m.type) }));
      setKpiModels(allModels);

      const integrations = configs.map(c => ({
        type: c.type,
        id: c._id,
        dataSet: c.dataSet,
        description: c.description,
        parameters: c.parameters,
        admin: { firstName: c.admin?.firstName },
        updatedAt: c.updatedAt,
      }));
      console.log(integrations);
      setAllConfigs(integrations);
      setKpiIntegrations(integrations);
      setLoading(false);
      
    } catch (error) {
      setLoading(false);
      console.log(error.response);
    }
  };

  const searchHandler = async (modelKey) => {
    if(modelKey) {
      const configs = allConfigs.filter((k) => k.type === modelKey);
      setKpiIntegrations(configs);
    } else {
      setKpiIntegrations(allConfigs);
    }

  };

  const setMode = (pageMode = "view") => {
    navigate(`/kpi/integrations/${pageMode}`);
  };

  useEffect(() => {
    switch(mode) {
      case "create":
        setInputMode(true);
        break;
      case "edit": 
        if(selected) {
          setInputMode(true);
        } else {
          setMode("view");
        }
        break;
      default:
        setInputMode(false);
        getKpiData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  useEffect(() => {
    getKpiData();
  }, []);

  return (
    <>
      <Box sx={{ gridArea: "first", marginTop: 5 }}>
        <Card>
          <Card.Header className="card-header">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="flex-col">
                <h2 className="card-title">KPI Integrations</h2>
                <small className="text-muted">
                  Each configuration defines how an external application integrates with a specific
                  <Button 
                    className="btn btn-link px-1"
                    variant="link"
                    onClick={()=>navigate("/kpi/models")}
                  >
                    KPI Model
                  </Button>
                  .
                  <a
                    href="https://docs.google.com/document/d/1cNX_7qYrUMXhlLB_x1BqVZvb8mHdkoLGZE3A2jij5NQ/view"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Reference Doc
                  </a>
                </small>
              </div>
              <div className="d-flex justify-content-between">
                {
                  !inputMode &&
                    <>
                      <h6 style={{width: "8rem"}} className="my-auto mx-1 text-muted">Model:</h6>
                      <Singleselect1s
                        options={kpiModels}
                        placeholder={loading? "Loading KPIs ..." : "Filter KPIs ..."}
                        onChange={searchHandler}
                      />
                    </>

                }
                <Box ml={2}>
                  <Button
                    className="btn btn-primary-light"
                    variant="primary-light"
                    onClick={()=>{
                      setSelected(null);
                      setMode(inputMode? "view" : "create");
                    }}
                  >
                    {
                      inputMode ? 
                        <><i className="icon icon-book-open me-2" />VIEW</> :
                        <><i className="icon icon-note me-2" />CREATE</>
                    }
                  </Button>
                </Box>
              </div>
            </Box>
          </Card.Header>    
          <Card.Body className="card-body pb-2">
            {
              !inputMode && 
                <Grid container spacing={1}>
                {
                  kpiIntegrations?.map(kpi => (
                    <Grid item key={kpi.id} xs={12} sm={6} md={4}>
                      <Basiccardheadercolor 
                        title={startCase(kpi.type)}
                        subtitle={startCase(kpi.dataSet)}
                        footer={
                          <div className="d-flex justify-content-between">
                            <small>ID: {kpi.id}</small>
                            <Button 
                              variant="secondary"
                              size="sm"
                              onClick={()=>{
                                setSelected(kpi);
                                setMode("edit");
                              }}
                            >
                              <i className="icon icon-pencil"/> EDIT
                            </Button>
                          </div>
                        }
                      >
                                              
                        <div style={{minHeight: "220px"}}>
                          <p className="mb-1 text-muted">Parameters</p>
                          {
                            kpi.parameters && Object.keys(kpi.parameters).map((k) => {
                              const key = k;
                              const param = kpi.parameters[k];
                              return <p key={key} className="mb-2">{startCase(key)}: {JSON.stringify(param)}</p>
                            })
                          }
                          <p className="mt-4 mb-0 text-muted">Description</p>
                          <p className="mt-0 mb-8">{kpi.description || 'None'}</p>
                          <p className="position-absolute bottom-0 mb-2">        
                            <small>Updated by: {kpi.admin?.firstName || "???"}</small>
                            {kpi.updatedAt ? <small> / {moment(kpi.updatedAt).format("MMM-DD-YYYY")}</small> : null}
                        </p>
                        </div>
                      </Basiccardheadercolor>

                    </Grid>
                  ))
                }
                </Grid>
            }
            {
              inputMode && 
                <KpiIntegrationsForm 
                  kpis={kpiModels}
                  config={selected}
                  onCancel={()=> setMode()}
                  onSave={()=> setMode()}
                />
            }
          </Card.Body>
          {
            loading && <LoadingScreen />
          }
        </Card>
      </Box>
    </>
  );
};

export default KpiIntegrations;
