import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import ReactApexChart from "react-apexcharts";
import { Col, Row, Card, Button } from "react-bootstrap";
import * as dashDatas from "./dashboardData";

import DataCards from "./DataCards";
import CompanyTable from "../Companies/CompanyTable";

import { GET_PAYMENTS } from "../../../api/subscription";
import { UseRecentCompanies } from "../../../hooks/data";

export default function Dashboard() {
  const [monthEarnings, setMonthEarnings] = useState(0);
  const [monthTransactions, setMonthTransactions] = useState(0);
  const [pastEarnings, setPastEarnings] = useState([]);
  const [pastTransactions, setPastTransactions] = useState([]);
  const [monthSignups, setMonthSignups] = useState(0);
  const [userStatus, setUserStatus] = useState([]);
  const [companies, setCompanies] = useState([]);
  const recentCompanies = UseRecentCompanies();
  const navigate = useNavigate();

  useEffect(() => {
    GET_PAYMENTS().then(({ data }) => {
      const startDate = moment().subtract(1, "month").unix();
      let earnings = 0;
      let transactions = 0;
      const monthlyData = {};

      data.forEach((curr) => {
        // Store data for the past month
        if (curr.created >= startDate) {
          earnings += curr.received;
          transactions += curr.amount;
        }
        // Store historic monthly data for the past year
        const month = moment(curr.created * 1000).format("YYMM");
        if (monthlyData[month]) {
          monthlyData[month].earned += curr.received;
          monthlyData[month].transacted += curr.amount;
        } else {
          monthlyData[month] = {
            earned: curr.received,
            transacted: curr.amount,
          };
        }
      });

      // Sort historic monthly data
      const historicEarnings = [];
      const historicTransactions = [];
      const startMonth = moment().subtract(1, "year");
      for (let i = 0; i < 12; i++) {
        const month = moment(startMonth).add(i, "month").format("YYMM");
        historicEarnings.push(
          monthlyData[month] ? monthlyData[month].earned / 100 : 0,
        );
        historicTransactions.push(
          monthlyData[month] ? monthlyData[month].transacted / 100 : 0,
        );
      }
      setPastEarnings(historicEarnings);
      setPastTransactions(historicTransactions);
      setMonthEarnings(earnings / 100);
      setMonthTransactions(transactions / 100);
    });
  }, []);

  useEffect(() => {
    if(recentCompanies) {
      const monthAgo = moment().subtract(1, "month");
      let newSignups = 0;
      const statuses = {};

      for (const company of recentCompanies) {
        if (moment(company.createdAt) >= monthAgo) {
          newSignups += 1;
        }
        const key = company.subscription?.status;
        if (statuses[key]) {
          statuses[key] += 1;
        } else {
          statuses[key] = 1;
        }
      }

      const statuesesArray = [];
      Object.keys(statuses).forEach((key) => {
        statuesesArray.push({ status: key, value: statuses[key] });
      });
      statuesesArray.sort((a, b) => b.value - a.value);
      setMonthSignups(newSignups);
      setUserStatus(statuesesArray);
      setCompanies(recentCompanies.splice(0, 10));
    };
  }, [recentCompanies]);

  return (
    <div style={{ marginTop: "1.5rem" }}>
      <Row>
        <Col lg={12} md={12} sm={12} xl={12}>
          <Row>
            <Col lg={6} md={12} sm={12} xl={3}>
              <DataCards
                title="Month's Earnings"
                prefix="$"
                amount={monthEarnings}
                color="primary"
                icon="money"
              />
            </Col>
            <Col lg={6} md={12} sm={12} xl={3}>
              <DataCards
                title="Month's Transactions"
                prefix="$"
                amount={monthTransactions}
                color="secondary"
                icon="dollar"
              />
            </Col>
            <Col lg={6} md={12} sm={12} xl={3}>
              <DataCards
                title="Missed Earnings"
                prefix={monthTransactions > monthEarnings ? "-$" : "$"}
                amount={monthTransactions - monthEarnings}
                color="danger"
                textColor={monthTransactions > monthEarnings ? "danger" : "dark"}
                icon="money"
              />
            </Col>
            <Col lg={6} md={12} sm={12} xl={3}>
              <DataCards
                title="Month's New Clients"
                amount={monthSignups}
                color="success"
                icon="briefcase"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={8}>
          <Card>
            <Card.Header className="card-header">
              <h3 className="card-title">Earnings Report</h3>
            </Card.Header>
            <Card.Body className="card-body pb-0">
              <div id="chartArea" className="chart-donut">
                <ReactApexChart
                  options={{
                    ...dashDatas.EarningReport.options,
                    xaxis: {
                      ...dashDatas.EarningReport.options.xaxis,
                      categories: (() => {
                        const months = [];
                        const startMonth = moment().subtract(1, "year");
                        for (let i = 0; i < 12; i++) {
                          months.push(
                            moment(startMonth).add(i, "month").format("MMM"),
                          );
                        }
                        return months;
                      })(),
                    },
                  }}
                  series={[
                    {
                      name: "Total Transactions",
                      type: "line",
                      data: pastEarnings,
                    },
                    {
                      name: "Total Earnings",
                      type: "line",
                      data: pastTransactions,
                    },
                    {
                      name: "",
                      type: "area",
                      data: pastTransactions,
                    },
                  ]}
                  type="area"
                  height={300}
                  width="100%"
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={4}>
          <Card>
            <Card.Header className="card-header">
              <h3 className="card-title">Client Subscriptions</h3>
            </Card.Header>
            <Card.Body className="card-body">
              <ReactApexChart
                type="pie"
                options={{
                  ...dashDatas.UserStatusPieChart.options,
                  labels: userStatus?.map((s) => s.status.toUpperCase()),
                }}
                series={userStatus?.map((s) => s.value)}
                width={"100%"}
                height={344}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Card className="w-100">
            <Card.Header className="card-header d-flex justify-content-between">
              <h3 className="card-title">New Companies</h3>
              <Button
                className="btn btn-md"
                variant="primary-light"
                onClick={() => navigate("/companies")}
              >
                <i className="fa fa-group me-1" /> View All
              </Button>
            </Card.Header>
            <Card.Body className="card-body">
              <CompanyTable companies={companies} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
