import React, { useEffect, useState } from "react";
import {
  Button,
  FormLabel,
  Dropdown,
} from "react-bootstrap";
import { Grid } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { SET_KPI_INTEGRATION } from "../../../api/kpi.js";

import { notify } from "../../../helpers/notifications.js";
import FormikController from "../Formik/FormikController.js";
import { startCase } from "lodash";

import { DATASETS, PARAMS_KEYS, PARAMS_PRESETS } from "./integrationParams.js";

const KpiIntegrationsForm = ({kpis, config, onSave, onCancel}) => {
  const [action] = useState(config ? "udpate": "create");
  const [loading, setLoading] = useState(false);
  const [paramKeys, setParamKeys] = useState(config?.parameters ? Object.keys(config.parameters) : []);

  //Formik
  const initialValues = {
    type: config?.type ||  "",
    dataSet: config?.dataSet || "",
    parameters: {
      ...config?.parameters,
      ...config?.parameters?.condition && { 
        condition: { // Restructure parameters.condition to fit Formik control
          key: Object.keys(config.parameters.condition)[0],
          value: Object.values(config.parameters.condition)[0],
        }
      }
    } || {},
    description: config?.description || "",
  };

  const validationSchema = Yup.object({
    type: Yup.string().required("KPI type is required."),
    dataSet: Yup.string().oneOf(DATASETS, "Should be a valid Data Set").required("Data Set is required."),
    parameters: Yup.object(),
    description: Yup.string().required("Description is required."),
  });

  const createHandler = async (formData, { resetForm }) => {
    
    setLoading(true);

    const integration = {...formData};
    // Re-structure parameter.condition to original object format
    if(integration.parameters?.condition) {
      const condParam = {...integration.parameters.condition};
      integration.parameters.condition = {};
      integration.parameters.condition[condParam.key] = condParam.value;
    }

    SET_KPI_INTEGRATION(integration)
      .then(() => {
        notify("Integration data was saved.");
        setLoading(false);
        resetForm();
        onSave?.();
      })
      .catch(err => {
        notify(err.response || "Integration Save Error", "error");
        setLoading(false);
      })
  };

  const addNewParam = (values, key) => {
    if(key === "condition") {
      values.condition = { key: "input_key", value: "input_value"}
    } else {
      values[key] = "input_value";
    }
    setParamKeys([...paramKeys, key]);
  };

  const removeParam = (values, key) => {
    delete values[key];
    const pTemp = [...paramKeys];
    const index = pTemp.indexOf(key);
    pTemp.splice(index, 1);
    setParamKeys(pTemp);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={createHandler}
      >
        {(formik) => (
          <Form className="px-2 pb-4">
            <h4 className="text-center">{config? "Update" : "Create a new"} KPI Integration</h4>
            <h5 className="text-center text-muted mb-4">{config && (startCase(config.type) + " - " + startCase(config.integrationCode))}</h5>
            <FormikController
              control="select"
              label="KPI Type"
              name="type"
              className="px-2 mb-4"
              options={kpis}
              disabled={config !== null}
              required
            />
            <FormikController
              control="select"
              label="Data Set"
              name="dataSet"
              className="px-2 mb-4"
              options={DATASETS.map(d => ({ value: d, label: d }))}
              disabled={config !== null}
              required
            />
              
            <div className="px-2 mb-2">
              <FormLabel className="mb-2">
                Parameters
              </FormLabel>
            </div>
            <Grid container className="py-2">
              {
                paramKeys && paramKeys.map(key => {
                  return <>
                    <Grid item xs={2} sm={2} className="px-2">
                      <Button variant="secondary" className="w-100 px-2 text-center"
                        onClick={() => removeParam(formik.values.parameters, key)}
                      >
                        <i className="fe fe-trash-2 me-2" />
                      </Button>
                    </Grid>
                    <Grid item xs={4} sm={3} className="px-2">
                      <Button disabled className="btn btn-secondary w-100 px-2">
                        <span className="px-4">{startCase(key)}</span>
                      </Button>
                    </Grid>
                    {
                      key!== "condition" ?
                        <Grid item xs={6} className="px-2">
                        {
                          PARAMS_PRESETS[key] ?
                            <FormikController
                              control="select"
                              options={PARAMS_PRESETS[key].map(p=>({label: p.toString(), value: p}))}
                              name={`parameters.${key}`}
                            /> :
                            <FormikController
                              control="input"
                              type="text"
                              name={`parameters.${key}`}
                            />
                        }
                        </Grid> :
                        <>
                          <Grid item xs={3} className="px-2">
                            <FormikController
                              control="input"
                              type="text"
                              name={`parameters.${key}.key`}
                            />
                          </Grid>
                          <span className="my-auto font-weight-bold">=</span>
                          <Grid item xs={3} className="px-2">
                            <FormikController
                              control="input"
                              type="text"
                              name={`parameters.${key}.value`}
                            />
                          </Grid>
                        </>
                    }

                  </>
                })
              }
                <Grid item xs={6} sm={2} className="px-2 my-2">
                  <Dropdown className="dropdown btn-group w-100">
                      <Dropdown.Toggle
                        variant=""
                        type="button"
                        className="btn btn-secondary dropdown-toggle w-100"
                      >
                        <i className="fe fe-plus me-2" />Add Param
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu">
                        {
                          PARAMS_KEYS.map(key => {
                            if(paramKeys.indexOf(key) < 0) {
                              return <Dropdown.Item className="dropdown-item"
                                onClick={() => addNewParam(formik.values.parameters, key)}
                                key={key}
                              >
                                {startCase(key)}
                              </Dropdown.Item>
                            }
                            return null;
                          })
                        }
                      </Dropdown.Menu>
                    </Dropdown>
                </Grid>
              </Grid>
              <FormikController
                control="input"
                type="text"
                label="Description"
                name="description"
                className="px-2"
                required
              />
              <div className="modal-footer mt-4 gap-2">
                <Button
                  variant=""
                  className="btn btn-outline-secondary" 
                  onClick={() => {
                    formik.resetForm();
                    onCancel?.();
                  }}
                >
                  <i className="fe fe-x me-2" />Cancel
                </Button>
                <Button
                  variant="primary"
                  disabled={loading}
                  type="submit"
                >
                  <i className="fe fe-save me-2" />{action === "create" ? "Create" : "Update"}
                </Button>
              </div>

          </Form>
        )}
      </Formik>
    </>
  );
};

export default KpiIntegrationsForm;
