import React from "react";

function Error({ children }) {
  return (
    <div className="error text-red py-1">
      {children}
    </div>
  );
}
export default Error;
