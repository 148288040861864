const YETI_PROFILE = {
    image: "YETI_PROFILE_PIC", // Image Key from S3 for Yeti's profile image
    firstName: "Yeti",
    lastName: "McMountainFace",
    email: "yeti@gamemybiz.com",
    group: {
      name: "Yeti",
      image: "YETI_PROFILE_PIC"
    }
  };

export default YETI_PROFILE;