import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import { Accordion, Card, Button, FormControl, Table } from "react-bootstrap";
import { Box } from "@mui/material";

import AffiliatesForm from "./AffiliatesForm.jsx";
import LoadingScreen from "../LoadingScreen";
import {
  GET_AFFILIATES,
  GET_USERS,
  SEND_CREDENTIALS_TO_AFFILIATE,
} from "../../../api/affiliate";
import { GET_IMAGE } from "../../../helpers/images.js";
import startCase from "lodash/startCase";
import { notifySuccess, notifyError } from "../../../helpers/notifications.js";

const LEVELS = ["freemium", "partner"];

const Affiliates = () => {
  const [allAffs, setAllAffs] = useState([]);
  const [affs, setAffs] = useState([]);
  const [users, setUsers] = useState({});
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [inputMode, setInputMode] = useState(false);
  const navigate = useNavigate();
  const { mode, type } = useParams();
  const [level, setLevel] = useState(null);

  const getUsers = async () => {
    // Get all users as list of potential affiliates (for Register Existing form)
    const { data: allUsers } = await GET_USERS();
    const usersObj = {};
    allUsers.forEach((u) => {
      const { _id: userId, ...rest } = u;
      usersObj[userId] = { ...rest };
    });
    setUsers(usersObj);
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    const idx = LEVELS.indexOf(type?.toLowerCase());
    setLevel(idx);
  }, [type]);

  const searchHandler = async (e) => {
    const key = e.target.value.trim().toLowerCase();
    const affiliates = allAffs.filter((a) => {
      const user = users[a._id];
      return (
        (user.fisrtName + " " + user.lastName).toLowerCase().indexOf(key) >=
          0 ||
        user.email.toLowerCase().indexOf(key) >= 0 ||
        a.code.toLowerCase().indexOf(key) >= 0
      );
    });
    setAffs(affiliates);
  };

  useEffect(() => {
    if(mode === "view" && Number.isFinite(level)) {
      const getAffiliates = async () => {
        try {
          setLoading(true);
          const { data: affiliates } = await GET_AFFILIATES(level);
          setAllAffs(affiliates);
          setAffs(affiliates);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.log(error.response);
        }
      };
      getAffiliates();
    }
  }, [level, mode]);

  const setMode = (newMode = "view", level) => {
    navigate(`/affiliates/${newMode}${level >= 0 ? `/${LEVELS[level]}` : ""}`);
  };

  const sendCredentialsTrigger = async (referrer) => {
    try {
      const { email } = referrer;
      const { data } = await SEND_CREDENTIALS_TO_AFFILIATE({ email });

      if (data) {
        notifySuccess(data?.message);
      }
    } catch (err) {
      console.log(err?.response);
      notifyError(err?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (mode !== "edit" && mode !== "register") {
      setInputMode(false);
      setSelected(null);
    } else {
      setInputMode(true);
    }
  }, [mode, affs]);

  return (
    <>
      <Box sx={{ gridArea: "first", marginTop: 5 }}>
        <Card>
          <Card.Header className="card-header d-flex justify-content-between gap-4">
            <h2 className="card-title flex-fill">
              Affiliates {type ? `- ${startCase(type)}` : ""}
            </h2>
            <div className="d-flex flex-fill gap-2">
              {!inputMode && (
                <FormControl
                  type="text"
                  placeholder="Search name, email or affiliate code ..."
                  name="type"
                  onChange={searchHandler}
                />
              )}
              <Button
                className="btn btn-primary-light w-50"
                variant="primary-light"
                onClick={() => navigate("/affiliates/create")}
              >
                <i className="icon icon-user-follow me-2" /> CREATE NEW USER
              </Button>
              <Button
                className="btn btn-primary-light w-50"
                variant="primary-light"
                onClick={() => setMode(inputMode ? "view" : "register")}
              >
                {inputMode ? (
                  <>
                    <i className="icon icon-book-open me-2" />
                    VIEW LIST
                  </>
                ) : (
                  <>
                    <i className="icon icon-note me-2" />
                    REGISTER EXISTING
                  </>
                )}
              </Button>
            </div>
          </Card.Header>
          <Card.Body className="card-body defaultaccordion">
            {!inputMode && !loading && (
              <Accordion>
                {affs.length > 0
                  ? affs.map((affiliate) => {
                      const referrer = users[affiliate?.userId];
                      if (!referrer) return null;
                      return (
                        <Accordion.Item
                          key={affiliate?.userId}
                          eventKey={affiliate?.userId}
                          className="mb-1"
                        >
                          <Accordion.Header className="d-flex justify-content-between align-middle">
                            <div className="col-2 d-flex">
                              <img
                                className="avatar avatar-sm brround cover-image"
                                src={GET_IMAGE(referrer.image)}
                                alt={affiliate?.userId?._id}
                              />
                              <h5 className="my-auto mx-2">
                                {referrer.firstName} {referrer.lastName || " "}
                              </h5>
                            </div>
                            <div className="col-1 text-primary d-flex">
                              {affiliate.level ? (
                                <>
                                  <i className="icon icon-people me-2" />{" "}
                                  PARTNER
                                </>
                              ) : (
                                <>
                                  <i className="icon icon-user me-2" /> FREE
                                </>
                              )}
                            </div>
                            <p className="my-auto mx-4 text-muted col-3">
                              {referrer.email}
                            </p>
                            <div className="col-4 d-flex">
                              <p className="my-auto mx-4 text-primary col-4">
                                Referrals: {affiliate.referrals?.length ?? 0}
                              </p>
                              <p className="my-auto mx-4 text-secondary col-8">
                                CODE: {affiliate.code}
                              </p>
                            </div>
                            <div className="col-1">
                              {affiliate.arena?.length ? (
                                <p className="text-primary">
                                  <i className="pe-7s-arc me-2" />
                                  <a href={`${process.env.REACT_APP_FRONTEND}/arena/${affiliate.arena[0]?.url}`}
                                    target="_blank"
                                  >
                                    Arena
                                  </a>
                                </p>
                              ) : (
                                <p className="text-muted">No arena</p>
                              )}
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="d-flex justify-content-end w-full gap-4 mb-2">
                              <Button
                                variant="secondary"
                                size="sm"
                                onClick={() => {
                                  setSelected(affiliate);
                                  setMode("edit");
                                }}
                              >
                                <i className="icon icon-pencil me-2" /> EDIT
                                AFFILIATE
                              </Button>
                              <Button
                                variant="secondary"
                                size="sm"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  sendCredentialsTrigger(referrer);
                                }}
                                disabled={
                                  affiliate?.userId?.logins > 0 ? true : false
                                }
                              >
                                <i className="icon icon-envelope-letter me-2" />
                                RESEND CREDENTIALS
                              </Button>
                            </div>
                            {affiliate.referrals?.length ? (
                              <Table className="table border text-nowrap text-md-nowrap table-striped mg-b-0">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Profile</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Company</th>
                                    <th>Logo</th>
                                    <th>Date</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {affiliate?.referrals.map(
                                    ({ _id: userId }, index) => {
                                      const item = users[userId];
                                      return (
                                        <tr key={userId}>
                                          <td>{index + 1}</td>
                                          <td>
                                            {item.image ? (
                                              <img
                                                src={GET_IMAGE(item.image)}
                                                alt={
                                                  item.firstName +
                                                  " " +
                                                  item.lastName
                                                }
                                                className="avatar avatar-sm cover-image"
                                              />
                                            ) : null}
                                          </td>
                                          <td>
                                            {item.firstName +
                                              " " +
                                              item.lastName}
                                          </td>
                                          <td>{item.email} </td>
                                          <td>{item.companyName}</td>
                                          <td>
                                            {item.companyLogo ? (
                                              <img
                                                src={GET_IMAGE(
                                                  item.companyLogo,
                                                )}
                                                alt={item.companyName}
                                                className="avatar avatar-sm cover-image"
                                              />
                                            ) : null}
                                          </td>
                                          <td>
                                            {item.createdAt
                                              ? moment(
                                                  new Date(item.createdAt),
                                                ).format("YYYY MMM Do")
                                              : ""}
                                          </td>
                                        </tr>
                                      );
                                    },
                                  )}
                                </tbody>
                              </Table>
                            ) : (
                              <h5 className="text-muted px-8">
                                Affiliate has no referrals yet
                              </h5>
                            )}
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })
                  : null}
              </Accordion>
            )}
            {inputMode && !loading && (
              <AffiliatesForm
                affiliates={allAffs}
                users={users}
                config={selected}
                onCancel={() => setMode("view")}
                onSave={(level) => setMode("view", level)}
              />
            )}
            {loading && <LoadingScreen />}
          </Card.Body>
          <Card.Footer>
            {/*               <Box className="d-flex flex-column">
                <Button
                  className="btn btn-primary-light"
                  variant="primary-light"
                  onClick={refreshList}
                >
                  FETCH APIDECK LIST
                </Button>
                <Box className="d-flex justify-content-end text-red">
                  <span>* Note: Fetching latest Apideck list consumes one API call</span>
                </Box>
              </Box> */}
          </Card.Footer>
        </Card>
      </Box>
    </>
  );
};

export default Affiliates;
