import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Card, Button, FormControl } from "react-bootstrap";
import { Box, Grid } from "@mui/material";

import { GET_KPI_MODELS } from "../../../api/kpi.js";

import KpiModelsForm from "./ModelsForm.jsx";
import { Basiccardheadercolor } from "../../../data/Component/carddata/carddata.js";
import LoadingScreen from "../LoadingScreen/index";
import moment from "moment";
import { startCase } from "lodash";

const KpiModels = () => {
  const [allModels, setAllModels] = useState([]);
  const [kpiModels, setKpiModels] = useState([]);
  const [inputMode, setInputMode] = useState(false);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { mode } = useParams();

  const getKpiModels = async () => {
    try {
      setLoading(true);
      const { data } = await GET_KPI_MODELS();
      setAllModels(data);
      setKpiModels(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.response);
    }
  };

  const searchHandler = async (e) => {
    const key = e.target.value.trim().toLowerCase();
    const kpis = allModels.filter((k) => k.type.indexOf(key) >= 0);
    setKpiModels(kpis);
  };

  const getValueType = (kind) => {
    switch (kind) {
      case "$":
        return "$ (Money)";
      case "%":
        return "% (Percent)";
      case "#":
        return "# (Item)";
      default:
        return "X Error: Invalid type";
    }
  };

  const toggleInputMode = (isCreate) => {
    navigate(`/kpi/models/${isCreate ? "create" : "view"}`);
  };

  useEffect(() => {
    if (mode === "view") {
      setInputMode(false);
      getKpiModels();
    } else {
      setInputMode(true);
    }
  }, [mode]);

  useEffect(() => {
    getKpiModels();
  }, []);

  return (
    <>
      <Box sx={{ gridArea: "first", marginTop: 5 }}>
        <Card>
          <Card.Header className="card-header">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="flex-col">
                <h2 className="card-title">KPI Models | Data Cubes</h2>
                <small className="text-muted">
                  Each KPI model can belong to any number of
                  <Button
                    className="btn btn-link px-1"
                    variant="link"
                    onClick={() => navigate("/workspaces")}
                  >
                    Workspace
                  </Button>
                  and can be assigned any number of
                  <Button
                    className="btn btn-link px-1"
                    variant="link"
                    onClick={() => navigate("/kpi/integrations")}
                  >
                    KPI Integration
                  </Button>
                  methods.
                </small>
              </div>
              <div className="d-flex justify-content-between">
                {!inputMode && (
                  <FormControl
                    type="text"
                    placeholder="Search type or tag..."
                    name="type"
                    // value={source}
                    onChange={searchHandler}
                  />
                )}
                <Box ml={2}>
                  <Button
                    className="btn btn-primary-light"
                    variant="primary-light"
                    onClick={() => toggleInputMode(!inputMode)}
                  >
                    {inputMode ? (
                      <>
                        <i className="icon icon-book-open me-2" />
                        VIEW
                      </>
                    ) : (
                      <>
                        <i className="icon icon-note me-2" />
                        CREATE
                      </>
                    )}
                  </Button>
                </Box>
              </div>
            </Box>
          </Card.Header>
          <Card.Body className="card-body">
            {!inputMode && (
              <Grid container spacing={2}>
                {kpiModels.map((kpi) => (
                  <Grid item key={kpi._id} xs={12} md={4} lg={3}>
                    <Basiccardheadercolor
                      title={startCase(kpi.type)}
                      footer={
                        <div className="d-flex justify-content-between">
                          <small>ID: {kpi._id}</small>
                          <Button
                            variant="secondary"
                            size="sm"
                            onClick={() => {
                              setSelected(kpi);
                              navigate('/kpi/models/edit');
                            }}
                          >
                            <i className="icon icon-pencil" /> EDIT
                          </Button>
                        </div>
                      }
                    >
                      <p className="mb-2">Integration: {kpi.defaultTarget}</p>
                      <p className="mb-2">
                        Default Point Scale: {kpi.defaultPointScale}
                      </p>
                      <p className="mb-2">
                        Direction:{" "}
                        {kpi.direction >= 1 ? (
                          <i className="icon icon-like text-green" />
                        ) : (
                          <i className="icon icon-dislike text-red" />
                        )}
                      </p>
                      {kpi.defaultInputs && kpi.defaultInputs.length > 0 ? (
                        <p className="mb-2">
                          Default Inputs:{" "}
                          {kpi.defaultInputs
                            .map((input) => startCase(input.value))
                            .join(",")}
                        </p>
                      ) : (
                        <p className="mb-2">Default Inputs: None</p>
                      )}
                      <p className="mb-5">
                        Value Type: {getValueType(kpi.kind)}
                      </p>

                      <p className="position-absolute bottom-0 mb-2">
                        <small>
                          Updated by: {kpi.admin?.firstName || "???"}
                        </small>
                        {kpi.updatedAt ? (
                          <small>
                            {" "}
                            / {moment(kpi.updatedAt).format("MMM-DD-YYYY")}
                          </small>
                        ) : null}
                      </p>
                    </Basiccardheadercolor>
                  </Grid>
                ))}
              </Grid>
            )}
            {inputMode && (
              <KpiModelsForm
                kpis={allModels}
                config={selected}
                onCancel={() => toggleInputMode(false)}
                onSave={() => toggleInputMode(false)}
              />
            )}
          </Card.Body>
          {loading && <LoadingScreen />}
        </Card>
      </Box>
    </>
  );
};

export default KpiModels;
