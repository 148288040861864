import React, { useState, useEffect } from "react";
import moment from "moment";
import { Card } from "react-bootstrap";
import { Box } from "@mui/material";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Link, useNavigate } from "react-router-dom";
import { Pagination } from "@mui/material";

import Loader from "../LoadingScreen/index";
import { GET_CHALLENGE_ARENA } from "../../../api/challenge.js";
import { GET_IMAGE } from "../../../helpers/images.js";
import YETI from "../../../constants/yetiProfile.js";

const ChallengeArena = () => {
  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState(null);
  const [fetching, setFetching] = useState(false);

  const navigate = useNavigate();

  const columns = [
    {
      name: "Type",
      selector: (row) => [row.type],
      sortable: true,
    },
    {
      name: "Title",
      selector: (row) => [row.title],
      sortable: true,
    },
    {
      name: "Subtitle",
      selector: (row) => [row.subtitle ? row.subtitle : "N/A"],
      sortable: true,
    },
    {
      name: "URL",
      selector: (row) => [
        <a
          href={`${process.env.REACT_APP_FRONTEND}/arena/${row.url}`}
          target="_blank"
        >
          {row.url.toUpperCase()}
        </a>,
      ],
      sortable: true,
    },
    {
      name: "Players",
      selector: (row) => [
        `${row.players?.length ?? 0} / ${row.maxPlayers ?? 10}`,
      ],
      sortable: false,
    },
    {
      name: "Period",
      selector: (row) => [
        `${
          row.startDate
            ? moment(row.startDate).format("MMM Do, YYYY")
            : "Month Start"
        } - ${
          row.endDate ? moment(row.endDate).format("MMM Do, YYYY") : "Month End"
        }`,
      ],
      sortable: true,
    },
    {
      name: "Yeti",
      selector: (row) => [
        (
          <>
            <img
              className="avatar avatar-sm brround cover-image"
              style={{filter: row.yetiPoints * 1 > 0 ? "none" : "grayscale(100%)"}}
              src={GET_IMAGE(YETI.image)}
              alt="The Yeti"
            />

              <span className="ms-2">{row.yetiPoints * 1 > 0 ? `${row.yetiPoints * 1} PT${row.yetiPoints*1> 1? 'S':''}/DAY` : "No Yeti"}</span>
              
          </>
        )
      ],
      sortable: false,
    },
  ];

  const fetchChallengeArena = async () => {
    try {
      setFetching(true);
      const { data } = await GET_CHALLENGE_ARENA({ page: 1, limit: 10 });
      setData(data.docs);
      setPaginationData(data);
      setFetching(false);
    } catch (error) {
      setFetching(false);
      console.log(error.response);
    }
  };

  const tableData = {
    columns,
    data,
  };

  useEffect(() => {
    fetchChallengeArena();
  }, []);

  const handleChange = async (event, value) => {
    try {
      setFetching(true);
      const { data } = await GET_CHALLENGE_ARENA({ page: value, limit: 10 });
      setData(data.docs);
      setFetching(false);
    } catch (error) {
      setFetching(false);
      console.log(error.response);
    }
  };

  return (
    <Box sx={{ gridArea: "first", marginTop: 5 }}>
      <Card>
        <Card.Header className="card-header">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <h3 className="card-title">Challenge Arena</h3>
            <div style={{ display: "flex" }}>
              <Box ml={2}>
                <Link to="/challenge-arena/create/">Create</Link>
              </Box>
            </div>
          </Box>
        </Card.Header>
        <Card.Body>
          {fetching ? (
            <Loader height="50" logoHeight="100px" />
          ) : !data.length ? (
            <Box
              sx={{
                width: "100%",
                textAlign: "center",
              }}
            >
              <h5>No Challenge Arenas found!</h5>
            </Box>
          ) : (
            <div className="table">
              <DataTableExtensions {...tableData}>
                <DataTable
                  columns={columns}
                  data={data}
                  noHeader
                  defaultSortField="id"
                  defaultSortAsc={false}
                  striped={true}
                  center={true}
                  persistTableHead
                  pagination={false}
                  highlightOnHover
                  onRowClicked={(row) => {
                    navigate(`/challenge-arena/details/${row._id}`, {
                      state: row,
                    });
                  }}
                />
              </DataTableExtensions>
            </div>
          )}
          <Pagination
            count={paginationData?.totalPages}
            onChange={handleChange}
          />
        </Card.Body>
      </Card>
    </Box>
  );
};

export default ChallengeArena;
