import { request } from "../helpers/axios";

export const LOGIN_USER = (username, password) => {
  return request({
    url: '/auth/login', 
    method: "POST",
    data: { username, password }
  });
};


export const LOGIN_GOOGLE_USER = (token) => {
  return request({
    url: '/auth/google-login', 
    method: "POST",
    data: { token }
  });
};