import Dashboard from "../components/GMB/Dashboard/Dashboard";
import Tickets from "../components/GMB/Tickets/Tickets";
import PluginMaster from "../components/GMB/PluginMaster/PluginMaster";
import Signout from "../components/GMB/Signout/Signout";
import WorkspaceMaster from "../components/GMB/WorkspaceMaster/WorkspaceMaster";
import ChallengeArena from "../components/GMB/ChallengeArena/ChallengeArena";
import CreateChallengeArena from "../components/GMB/ChallengeArena/Create";
import ChallengeArenaDetails from "../components/GMB/ChallengeArena/ChallengeArenaDetails";
import KpiModels from "../components/GMB/Kpi/Models";
import KpiIntegrations from "../components/GMB/Kpi/Integrations";
import Subscriptions from "../components/GMB/Subscriptions/Subscriptions";
import IntegrationsApideck from "../components/GMB/Integrations/Apideck";
import IntegrationsZapier from "../components/GMB/Integrations/Zapier";
import Affiliates from "../components/GMB/Affiliates/Affiliates";
import LevelOneAffiliate from "../components/GMB/Affiliates/LevelOneAffiliate";
import Restrictions from "../components/GMB/Restrictions/Restrictions";
import CreateDemoAccounts from "../components/GMB/Demo/CreateDemoAccounts";
import ManageColdLeads from "../components/GMB/Demo/ManageColdLeads";
import CreateMasterBadge from "../components/GMB/MasterBadge/CreateMasterBadge";
import ManageMasterBadge from "../components/GMB/MasterBadge/ManageMasterBadge";
import EditMasterBadge from "../components/GMB/MasterBadge/EditMasterBadge";
import Settings from "../components/GMB/Settings/Settings";
import Companies from "../components/GMB/Companies/Companies";
import CancelRequests from "../components/GMB/CancelRequests/CancelRequests";
import Industries from "../components/GMB/Industries/Industries";
import CreateWidgets from "../components/GMB/Widgets/CreateWidgets";
import ManageWidgets from "../components/GMB/Widgets/ManageWidgets";
import EditWidget from "../components/GMB/Widgets/EditWidget";
import JetpackCreate from "../components/GMB/Jetpacks/Create";
import JetpackList from "../components/GMB/Jetpacks/List";

const routes = [
  {
    key: "home",
    path: "/",
    element: Dashboard,
  },
  {
    key: "dashboard",
    path: "/dashboard",
    element: Dashboard,
  },
  {
    key: "industries",
    path: "/industries",
    element: Industries,
  },
  {
    key: "cancel-users",
    path: "/cancel-users",
    element: CancelRequests,
  },
  {
    key: "companies",
    path: "/companies",
    element: Companies,
  },
  {
    key: "tickets",
    path: "/tickets",
    element: Tickets,
  },
  {
    key: "pluginmaster",
    path: "/plugin-master",
    element: PluginMaster,
  },
  {
    key: "workspace-master",
    path: "/workspaces",
    element: WorkspaceMaster,
  },
  {
    key: "subscription",
    path: "/subcription",
    element: Subscriptions,
  },
  {
    key: "challenge-arena",
    path: "/challenge-arena",
    element: ChallengeArena,
  },
  {
    key: "create-challenge-arena",
    path: "/challenge-arena/create",
    element: CreateChallengeArena,
  },
  {
    key: "challenge-arena-details",
    path: "/challenge-arena/details/:id",
    element: ChallengeArenaDetails,
  },
  {
    key: "prospects-create",
    path: "/prospects/create",
    element: CreateDemoAccounts,
  },
  {
    key: "prospects-manage",
    path: "/prospects/:mode",
    element: ManageColdLeads,
  },
  {
    key: "challenge-arena-edit",
    path: "/challenge-arena/edit/:id",
    element: CreateChallengeArena,
  },
  {
    key: "kpi-models-main",
    path: "/kpi/models",
    element: KpiModels,
  },
  {
    key: "settings",
    path: "/settings",
    element: Settings,
  },
  {
    key: "create-master-badge",
    path: "/master-badge/create",
    element: CreateMasterBadge,
  },
  {
    key: "manage-master-badge",
    path: "/master-badge/manage",
    element: ManageMasterBadge,
  },
  {
    key: "master-badge-edit",
    path: "/master-badge/edit/:id",
    element: EditMasterBadge,
  },

  {
    key: "create-widget",
    path: "/widgets/create",
    element: CreateWidgets,
  },
  {
    key: "manage-widgets",
    path: "/widgets/manage",
    element: ManageWidgets,
  },
  {
    key: "widgets-edit",
    path: "/widgets/edit/:id",
    element: EditWidget,
  },
  {
    key: "kpi-models-mode",
    path: "/kpi/models/:mode",
    element: KpiModels,
  },
  {
    key: "kpi-integrations-main",
    path: "/kpi/integrations",
    element: KpiIntegrations,
  },
  {
    key: "kpi-integrations-mode",
    path: "/kpi/integrations/:mode",
    element: KpiIntegrations,
  },
  {
    key: "integrations",
    path: "/integrations",
    element: IntegrationsApideck,
  },
  {
    key: "integrations-apideck",
    path: "/integrations/apideck",
    element: IntegrationsApideck,
  },
  {
    key: "integrations-zapier",
    path: "/integrations/zapier",
    element: IntegrationsZapier,
  },
  {
    key: "affiliates-main",
    path: "/affiliates",
    element: Affiliates,
  },
  {
    key: "affiliates-create",
    path: "/affiliates/create",
    element: LevelOneAffiliate,
  },
  {
    key: "affiliates-view-type",
    path: "/affiliates/:mode/:type",
    element: Affiliates,
  },
  {
    key: "affiliates-mode",
    path: "/affiliates/:mode",
    element: Affiliates,
  },
  {
    key: "restrictions",
    path: "/restrictions",
    element: Restrictions,
  },
  {
    key: "jetpack-list",
    path: "/jetpacks/list",
    element: JetpackList,
  },
  {
    key: "jetpack-create",
    path: "/jetpacks/create",
    element: JetpackCreate,
  },
  {
    key: "jetpack-edit",
    path: "/jetpacks/edit/:id",
    element: JetpackCreate,
  },
  {
    key: "signout",
    path: "/signout",
    element: Signout,
  },
];

export default routes;
