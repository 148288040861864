import { request } from "../helpers/axios";

export const CREATE_RESTRICTION = (data) => {
  return request({
    url: `/restriction`,
    method: "POST",
    data,
  });
};

export const GET_RESTRICTION = () => {
  return request({
    url: `/restriction`,
    method: "GET",
  });
};
