import React from "react";
import moment from "moment";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";

import { GET_ROUTE_URL } from "../../../api/user";
import { GET_IMAGE } from "../../../helpers/images.js";

const CompanyTable = ({ companies }) => {
  const columns = [
    {
      name: "Company Name",
      selector: (row) => [row.businessName?.trim() ? row.businessName : "N/A"],
      sortable: true,
    },
    {
      name: "Logo",
      selector: (row) => [
        row.logo ? (
          <img
            src={GET_IMAGE(row.logo)}
            alt={row.target}
            className="avatar avatar-lg cover-image"
          />
        ) : null,
      ],
      grow: 0,
      sortable: false,
    },
    {
      name: "User Name",
      selector: (row) => [
        row.user ? `${row.user.firstName} ${row.user.lastName}` : "N/A",
      ],
      grow: 1,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => [row.user ? row.user.email : "N/A"],
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => {
        if (row.createdAt) {
          const createdAt = moment(row.createdAt);
          const isRecent = moment().diff(createdAt, "days") <= 7;
          return [
            <span
              className={`tag ${isRecent? 'tag-success' : ''}`}
            >
              {createdAt.format("YYYY-MM-DD")}
            </span>,
          ];
        }
        return "";
      },
      grow: 0,
      sortable: true,
    },
    {
      name: "Tier",
      selector: (row) => [
        row.subscription?.level ? `TIER-${row.subscription.level}` : "FREE",
      ],
      grow: 0,
      sortable: true,
    },
    {
      name: "Onboarding",
      selector: (row) => [
        row.onboardingStatus ?? "N/A",
      ],
      grow: 1,
      sortable: true,
    },
    {
      name: "Subscription",
      selector: (row) => [
        row.subscription?.status ? row.subscription.status : "N/A",
      ],
      grow: 1,
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (row) => [
        <Button
          variant="primary"
          size="sm"
          onClick={async () => {
            const response = await GET_ROUTE_URL({companyId: row._id, username: row.user.email});

            if ((response.statusText = "OK")) {
              console.log(response.data.url);
              window.open(response.data.url, "_blank");
            } else {
              console.error(
                "Failed to get customer URL:",
                response.status,
                response.statusText,
              );
            }
          }}
        >
          Login
        </Button>,
      ],
      grow: 0,
      sortable: false,
    },
  ];

  return (
    <div className="table remove-filter-input">
        <DataTable
          columns={columns}
          data={companies}
          noHeader
          defaultSortField="id"
          defaultSortAsc={false}
          striped={true}
          center={true}
          persistTableHead
          pagination={false}
          highlightOnHover
        />
    </div>
  );
};

export default CompanyTable;
