import React, { useState, useRef } from "react";
import FormikController from "../../Formik/FormikController.js";
import { useFormikContext } from "formik";

import { Button, Row, Col, Tabs, Tab } from "react-bootstrap";
import Paper from "@mui/material/Paper";
import JetpackPreview from "./Preview.jsx";
import JetpackEditor from "./Editor.jsx";

import { notify } from "../../../../helpers/notifications.js";

const JetpackForm = () => {
  const { values, setFieldValue, errors } = useFormikContext();
  const [editorType, setEditorType] = useState(0);
  const fileRef = useRef();

  const handleUpload = async (e) => {
    try {
      if (e.target.files.length === 0) {
        return;
      }
      const htmlFile = e.target.files[0];
      const html = new FileReader();
      html.onload = function (e) {
        const content = e.target.result;
        setFieldValue("page", content);
        notify("Content updated!");
      };
      html.readAsText(htmlFile);
    } catch (error) {
      notify("Content error", "error");
      console.log("Html err", error);
    }
  };

  return (
    <div className="w-100 h-auto d-flex flex-column">
      <div className="w-100 h-100 d-flex justify-content-between align-items-center">
        {/** Form container */}
        <Paper variant="outlined" className="flex-grow-1 p-4">
          <Row className="gy-2">
            <Col sm={9}>
              <FormikController
                control="input"
                type="text"
                label="Name / Title"
                name="name"
                required
              />
            </Col>
            <Col sm={3}>
              <FormikController
                control="input"
                type="number"
                label="Trial Period (days)"
                name="trial"
                required
              />
            </Col>
            <Col sm={12} md={12}>
              <FormikController
                control="input"
                type="text"
                label="Description"
                name="desc"
                rows={4}
                required
              />
            </Col>
            <Col sm={12} md={6}>
              <FormikController
                control="input"
                type="text"
                label="URL"
                name="url"
                required
              />
            </Col>
            <Col sm={12} md={6}>
              <FormikController
                control="input"
                type="text"
                label="Image URL"
                name="image"
                required
              />
            </Col>
          </Row>
        </Paper>

        {/** Preview container */}
        <div className="d-flex flex-column mx-4">
          <div className="error text-red pb-2">
            {Object.keys(errors).length > 0
              ? `Problem found in: ${Object.keys(errors).join(", ")}`
              : null}
          </div>

          <JetpackPreview values={values} previewOnly />
        </div>
      </div>

      {/** Editor container */}
      <div className="w-100 h-auto my-4">
        <Tabs
          variant="pills"
          activeKey={editorType}
          className="panel-tabs my-2"
          onSelect={(key) => {
            setEditorType(key);
          }}
        >
          <Tab eventKey={0} className="me-1" title="Upload HTML (Recommended)">
            <Paper variant="outlined" className="w-100 px-2 py-2">
              <Button
                variant="secondary"
                onClick={() => fileRef?.current.click()}
              >
                <i className="icon icon-doc me-2" />
                Submit File
              </Button>
              {values.page ? (
                <Paper variant="outlined" className="w-100 my-2">
                  <div dangerouslySetInnerHTML={{ __html: values.page }} />
                </Paper>
              ) : null}
              <input
                type="file"
                accept="text/html"
                ref={fileRef}
                onChange={handleUpload}
                hidden
              />
            </Paper>
          </Tab>
          <Tab eventKey={1} className="me-1" title="Edit Page Content">
            <JetpackEditor
              content={values.page}
              updateValue={(value) => {
                setFieldValue("page", value);
              }}
            />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default JetpackForm;
