import { request } from "../helpers/axios";

export const CREATE_WIDGET = (data) => {
  return request({
    url: `/widgets`,
    method: "POST",
    data,
  });
};

export const UPDATE_WIDGET = (widgetId, data) => {
  return request({
    url: `/widgets?widgetId=${widgetId}`,
    method: "PATCH",
    data,
  });
};

export const FETCH_WIDGETS = () => {
  return request({
    url: `/widgets`,
    method: "GET",
  });
};
