import { types, initState} from "./types";

const rootReducer = (state = {adminReducer: initState}, payload) => {
  let adminState = state.adminReducer;
  switch (payload.type) {
    case types.API_OFFLINE:
    case types.PUT_VERSION:
      adminState = {
        ...adminState,
        version: payload.version,
        synced: payload.synced,
      };
      break;
    case types.LOGIN_REQUEST:
    case types.SOCIAL_LOGIN_REQUEST:
      adminState = {
        ...adminState,
        authUserId: null,
        authName: null,
      };
      break;
    case types.LOGIN_SUCCESS:
      adminState = { 
        ...adminState,
        authUserId: payload.userId,
        authName: payload.name,
      };
      break;
    case types.LOGIN_FAILED:
      adminState = { 
        ...adminState,
        authUserId: null,
        authName: null,
      };
      break;
    case types.GET_TICKETS_REQUEST: {
      adminState = { 
        ...adminState,
        tickets: null,
      };
      break;
    }
    case types.GET_TICKETS_SUCCESS: {
      adminState = { 
        ...adminState,
        tickets: payload.data,
      };
      break;
    }
    case types.GET_TICKETS_FAILED: {
      adminState = { 
        ...adminState,
        tickets: [],
        error: payload.error,
      };
      break;
    }
    case types.GET_RECENT_COMPANIES_REQUEST: {
      adminState = { 
        ...adminState,
        companies: null,
      };
      break;
    }
    case types.GET_RECENT_COMPANIES_SUCCESS: {
      adminState = { 
        ...adminState,
        companies: payload.data,
      };
      break;
    }
    case types.GET_RECENT_COMPANIES_FAILED: {
      adminState = { 
        ...adminState,
        companies: [],
        error: payload.error,
      };
      break;
    }
    case types.SIGN_OUT:
      // eslint-disable-next-line no-case-declarations
      const {adminReducer: { version }} = state; // Reset all saved information execept for appReducer
      adminState = { version }; 
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      break;
    default:
      return state;
  }    
  
  if(payload.error) {
    adminState = {
      ...adminState,
      error: state.error? [...state.adminReducer.error, payload.error]: [payload.error],
    };
  }
  
  return  {
    ...state,
    adminReducer: adminState,
  };
}

export default rootReducer;
