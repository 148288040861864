import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { getTickets } from "../../../redux/action";
import { HANDLE_TICKET, RESOLVE_TICKET } from "../../../api/tickets";
import { UseAuthUserId } from "../../../hooks/auth";
import { UseTickets } from "../../../hooks/data";

import { Typography, Grid } from "@mui/material";
import { Badge, Button, Card } from "react-bootstrap";
import LoadingScreen from "../LoadingScreen";

import { notifySuccess, notifyError } from "../../../helpers/notifications.js";

const Tickets = () => {
  const [items, setItems] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const authUserId = UseAuthUserId();
  const tickets = UseTickets();

  useEffect(() => {
    // Tickets hook is null (falsy) while request is ongoing; result is an array which will stop the loading
    setIsLoading(!tickets);
    if(tickets) {
      setItems(tickets);
    }
  }, [tickets]);

  const handleTicket = (ticketId) => {
    HANDLE_TICKET(ticketId)
      .then(() => {
        notifySuccess("Ticket now assigned to you");
        dispatch(getTickets());
      })
      .catch((err) => {
        notifyError(
          `Ticket assignment error: ${err.response?.data ?? err.response}`,
        );
      });
  };

  const resolveTicket = (ticketId) => {
    RESOLVE_TICKET(ticketId)
      .then(() => {
        notifySuccess("Ticket has been marked as resolved");
        dispatch(getTickets());
      })
      .catch((err) => {
        notifyError(
          `Ticket resolution error: ${err.response?.data ?? err.response}`,
        );
      });
  };

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <Grid container className="w-100">
          {items &&
            items.length > 0 &&
            items.map((ticket, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={ticket._id}
                  sx={{ padding: 2, minHeight: "220px" }}
                >
                  <Card className="h-100">
                    <Card.Header
                      className={`${
                        ticket.done
                          ? "bg-success-gradient"
                          : ticket.handledBy
                          ? "bg-primary-gradient"
                          : "bg-danger-gradient"
                      } text-white`}
                    >
                      <Card.Title className="d-flex justify-content-between w-100">
                        <div>
                          <Typography variant="button">
                            {ticket.user
                              ? `${ticket.user?.firstName} ${ticket.user?.lastName}`
                              : "Anonymous"}
                          </Typography>
                          <Typography variant="body2">
                            {ticket.user?.email}
                          </Typography>
                        </div>

                        <Typography variant="body2">
                          {moment(ticket.createdAt).fromNow()}
                        </Typography>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Card.Text>{ticket.text}</Card.Text>
                    </Card.Body>
                    <Card.Footer className="d-flex justify-content-between w-100">
                      {ticket.handler ? (
                        <>
                          <div className="d-flex gap-2 align-items-center">
                            <p
                              className={`${
                                ticket.done ? "font-weight-bold" : "text-muted"
                              }`}
                            >
                              {ticket.done ? "Resolved" : "Handled"} By:
                            </p>
                            <h4>
                              <Badge
                                bg="warning"
                                className={`rounded-pill ${
                                  ticket.done
                                    ? "bg-success-gradient"
                                    : "bg-primary-gradient"
                                }`}
                                style={{ fontSize: "1.5rem !important" }}
                              >
                                {ticket.handler.firstName}
                              </Badge>
                            </h4>
                          </div>
                          {!ticket.done && authUserId === ticket?.handledBy ? (
                            <Button
                              className="btn btn-md"
                              variant="primary-light"
                              onClick={() => resolveTicket(ticket._id)}
                            >
                              <i className="icon icon-diamond me-1" /> Mark as
                              Resolved
                            </Button>
                          ) : null}
                        </>
                      ) : (
                        <Button
                          className="btn btn-md"
                          variant="secondary-light"
                          onClick={() => handleTicket(ticket._id)}
                        >
                          <i className="icon icon-shield me-1" /> Assign to
                          Myself
                        </Button>
                      )}
                    </Card.Footer>
                  </Card>
                </Grid>
              );
            })}

          {items && items.length === 0 && (
            <p className="text-m md:text-sm font-bold">No Tickets Available</p>
          )}
        </Grid>
      )}
    </>
  );
};

export default Tickets;
