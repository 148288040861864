import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Button } from "react-bootstrap";
import { Box } from "@mui/material";
import { GET_JETPACKS } from "../../../api/jetpacks";

import LoadingScreen from "../LoadingScreen";
import JetpackPreview from "./elements/Preview";

const JetpackList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [packs, setPacks] = useState(null);

  useEffect(() => {
    setLoading(true);
    GET_JETPACKS()
      .then(({ data }) => {
        setPacks(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <Box sx={{ gridArea: "first", marginTop: 5 }}>
      <Card>
        <Card.Header className="card-header">
          <Box className="w-100 d-flex justify-content-between align-items-center">
            <h3 className="card-title">All Jetpacks</h3>
            <Button
              variant="primary"
              onClick={() => navigate("/jetpacks/create")}
            >
              <i className="icon icon-energy me-2" />
              CREATE
            </Button>
          </Box>
        </Card.Header>
        <Card.Body>
          <Box className="w-100 d-flex flex-wrap justify-content-center align-items-start">
            {loading ? (
              <LoadingScreen />
            ) : (
              packs?.map((item) => (
                <div  key={item.name}  className="m-4">
                  <JetpackPreview values={item} editable={true} />
                </div>
              ))
            )}
          </Box>
        </Card.Body>
      </Card>
    </Box>
  );
};

export default JetpackList;
