import * as Yup from "yup";

const jetpackSchema = Yup.object({
  _id: Yup.string().nullable(),
  name: Yup.string().required("Name is required"),
  desc: Yup.string().required("Description is required"),
  image: Yup.string()
    .required("Image URL is required")
    .matches(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/, "Image URL must be valid"),
  url: Yup.string()
    .required("URL is required")
    .matches(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/, "URL must be valid"),
  trial: Yup.number().nullable(),
  page: Yup.string().nullable(),
});

export default jetpackSchema;
