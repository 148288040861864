import { types } from "./types";

export const getAppVersion = () => ({
  type: types.GET_VERSION,
});

export const socialLoginUser = (token, authType ='google') => ({
  type: types.SOCIAL_LOGIN_REQUEST,
  token,
  authType,
});

export const getTickets = () => ({
  type: types.GET_TICKETS_REQUEST,
});

export const getRecentCompanies = () => ({
  type: types.GET_RECENT_COMPANIES_REQUEST,
});

export const signOutUser = () => ({
  type: types.SIGN_OUT,
});

