import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ReactApexChart from "react-apexcharts";

// Imported Images and Icons
import imgPlaceholder from "../../../img/random.png";
import StadiumOutlinedIcon from "@mui/icons-material/StadiumOutlined";

import { GET_STATS } from "../../../api/user.js";
import { GET_NEW_COMPANY } from "../../../api/company";

// Random Colors
const getColor = () => {
  const textColors = [
    "#6259ca",
    "#fb6b25",
    "#13bfa6",
    "#45aaf2",
    "#f7b731",
    "#f82649",
  ];
  const randomColor = textColors[Math.floor(Math.random() * textColors.length)];
  return randomColor;
};

export const EarningReport = {
  options: {
    chart: {
      height: 300,
      type: "line",
      stacked: false,
      toolbar: {
        show: false,
      },
      dropShadow: {
        enabled: true,
        opacity: 0.1,
      },
    },
    colors: ["#6259ca", "#f99433", "rgba(119, 119, 142, 0.01)"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: [3, 3, 0],
      dashArray: [0, 4],
      lineCap: "round",
    },
    grid: {
      padding: {
        left: 0,
        right: 0,
      },
      strokeDashArray: 3,
    },
    markers: {
      size: 0,
      hover: {
        size: 0,
      },
    },

    xaxis: {
      type: "month",
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      axisBorder: {
        show: false,
        color: "rgba(119, 119, 142, 0.08)",
      },
      labels: {
        style: {
          color: "#8492a6",
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          color: "#8492a6",
          fontSize: "12px",
        },
      },
      axisBorder: {
        show: false,
        color: "rgba(119, 119, 142, 0.08)",
      },
    },
    fill: {
      gradient: {
        inverseColors: false,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    },
    tooltip: {
      show: false,
    },
    legend: {
      position: "top",
      show: false,
    },
  },
};

export const UserStatusPieChart =  {
  series: [],
  options: {
    chart: {
      type: "pie",
    },
    fill: {
      type: 'gradient',
    },
    labels: [],
    dataLabels: {
      enabled: true,
    },
    legend: {
      show: true,
      position: "bottom",
      labels: {
        useSeriesColors: true,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 380,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  }
};

export const PopularityIndustry = {
  series: [83],
  options: {
    chart: {
      height: 305,
      type: "radialBar",
      responsive: "true",
      offsetY: 10,
      offsetX: 0,
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        dataLabels: {
          name: {
            fontSize: "16px",
            color: undefined,
            offsetY: 30,
          },
          value: {
            offsetY: -16,
            fontSize: "22px",
            color: undefined,
            formatter: function (val) {
              return val + "%";
            },
          },
        },
      },
    },
    colors: ["#ff5d9e"],
    fill: {
      type: "gradient",
      gradient: {
        shade: "gradient",
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: ["#6259ca"],
        inverseColors: !0,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    stroke: {
      dashArray: 4,
    },
    labels: [""],
  },
};

export const TopGMBIndustry = () => {
  const topIndustry = [
    {
      industry: "Window Cleaning",
      icon: StadiumOutlinedIcon,
      total: "25,000",
      color: getColor(),
    },
    {
      industry: "Plumbing",
      icon: StadiumOutlinedIcon,
      total: "15,300",
      color: getColor(),
    },
    {
      industry: "Exterior Cleaning",
      icon: StadiumOutlinedIcon,
      total: "15,300",
      color: getColor(),
    },
    {
      industry: "HVAC",
      icon: StadiumOutlinedIcon,
      total: "15,300",
      color: getColor(),
    },
    {
      industry: "Electrical",
      icon: StadiumOutlinedIcon,
      total: "15,300",
      color: getColor(),
    },
    {
      industry: "Landscaping",
      icon: StadiumOutlinedIcon,
      total: "15,300",
      color: getColor(),
    },
    {
      industry: "Tree Services",
      icon: StadiumOutlinedIcon,
      total: "15,300",
      color: getColor(),
    },
  ];

  return (
    <Box>
      {topIndustry.map((data) => (
        <Box
          key={data.industry}
          sx={{ width: "100%", marginBottom: "0.75rem" }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontFamily: "Roboto",
            }}
          >
            <Box sx={{ display: "flex", gap: "0.25rem", alignItems: "center" }}>
              {<data.icon sx={{ color: "#495584" }} />}
              <Typography sx={{ lineHeight: 0.85 }}>{data.industry}</Typography>
            </Box>
            <Typography
              sx={{ color: data.color, fontSize: "1rem", fontWeight: 500 }}
            >
              {data.total}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export const SalesSummary = () => {
  const salesInfo = [
    {
      category: "By Industry",
      timeFrame: "last month",
      amount: "15,300",
      color: getColor(),
    },
    {
      category: "By Location (State)",
      timeFrame: "last month",
      amount: "15,300",
      color: getColor(),
    },
    {
      category: "By Tier Plan",
      timeFrame: "last month",
      amount: "15,300",
      color: getColor(),
    },
    {
      category: "Total Revenue",
      timeFrame: "last month",
      amount: "15,300",
      color: getColor(),
    },
    {
      category: "Total Income",
      timeFrame: "last month",
      amount: "15,300",
      color: getColor(),
    },
  ];

  return (
    <Box sx={{ width: "100%" }}>
      {salesInfo?.map((data) => (
        <Box key={data.category} sx={{ width: "100%", marginBottom: "0.5rem" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontFamily: "Roboto",
            }}
          >
            <Box>
              <Typography sx={{ lineHeight: 0.85 }}>{data.category}</Typography>
              <Typography sx={{ color: "#76839A", fontSize: "0.9rem" }}>
                {data.timeFrame}
              </Typography>
            </Box>
            <Typography
              sx={{ color: data.color, fontSize: "1rem", fontWeight: 500 }}
            >
              ${data.amount}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export const ActiveUsers = ({ totalActiveUser }) => {
  const [stats, setStats] = useState({
    totalUsers: 0,
    returnedUsers: 0,
    todayUsers: 0,
  });

  const state = {
    series: [
      stats.totalUsers,
      stats.todayUsers,
      totalActiveUser,
      stats.returnedUsers,
    ],
    options: {
      chart: {
        width: 380,
        height: 400,
        type: "pie",
      },
      colors: ["#165BAA", "#F765A3", "#16BFD6", "#1DDD8D"],
      labels: [
        "Users",
        "New Users for Today",
        "Active Users",
        "Returning Customers",
      ],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        position: "bottom",
        labels: {
          useSeriesColors: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 380,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  const getStats = async () => {
    try {
      const { data } = await GET_STATS();

      setStats(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  useEffect(() => {
    getStats();
  }, []);

  return (
    <div id="chart">
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="pie"
        width={380}
        height={400}
      />
    </div>
  );
};

