import { request } from "../helpers/axios";

export const CREATE_CHALLENGE_ARENA = (data) => {
  return request({
    url: `/challenge/one-vs-one`,
    method: "POST",
    data,
  });
};

export const GET_CHALLENGE_ARENA = ({ page, limit }) => {
  return request({
    url: `/challenge/all?page=${page}&limit=${limit}`,
    method: "GET",
  });
};

export const GET_CHALLENGE_ARENA_DETAILS = (id) => {
  return request({
    url: `/challenge/details?id=${id}`,
    method: "GET",
  });
};

export const UPDATE_CHALLENGE_ARENA = (id, data) => {
  console.log(id, data);
  return request({
    url: `/challenge?challengeId=${id}`,
    method: "PATCH",
    data,
  });
};
