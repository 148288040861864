
import { request } from "../helpers/axios";

export const GET_INTEGRATION_CONFIGS = () => {
  return request({
    url: `/integration/configs`,
    method: "GET",
  });
};

export const GET_COMPANY_INTEGRATIONS = () => {
  return request({
    url: `/integration/company/all`,
    method: "GET",
  });
};

export const REFRESH_APIDECK_CONNECTIONS = () => {
  return request({
    url: `/apideck/connections`,
    method: "PUT",
  });
};

export const GET_APIDECK_CONNECTIONS = () => {
  return request({
    url: `/apideck/connections`,
    method: "GET",
  });
};

export const GET_ZAPIER_CONNECTIONS = () => {
  return request({
    url: `/zapier/connections`,
    method: "GET",
  });
};

export const REFRESH_ZAPIER_CONNECTIONS = () => {
  return request({
    url: `/zapier/connections`,
    method: "PUT",
  });
};
