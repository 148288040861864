import React from "react";
import AppRoutes from "./routes";
import { getAppVersion } from "./redux/action";
import { useDispatch } from "react-redux";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const dispatch = useDispatch();
  
  React.useEffect(() => {
    dispatch(getAppVersion());
  }, [dispatch]);

  return (
    <>
      <AppRoutes />
      <ToastContainer />
    </>
  );
}

export default App;
