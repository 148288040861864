const INDUSTRY_OPTIONS = [
  "Others",
  "Window Cleaning",
  "Home Service",
  "Landscaping",
  "Plumbing",
  "Carpet Cleaning",
  "HVAC",
  "Garage Doors",
  "Electrical",
  "Tree Service",
  "Automotive",
  "Exterior Cleaning",
];

export default INDUSTRY_OPTIONS;