import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  Modal,
  FormLabel,
  Row,
  Col,
  FormControl,
} from "react-bootstrap";
import { Box } from "@mui/material";
import Select from "react-select";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import {
  CREATE_WORKSPACE,
  UPDATE_WORKSPACE,
  GET_WORKSPACES,
  SEARCH_WORKSPACE,
} from "../../../api/workspace.js";

import { GET_KPI_MODELS } from "../../../api/kpi.js";

import FormikController from "../Formik/FormikController.js";
import Loader from "../LoadingScreen/index";

const WorkspaceMaster = () => {
  const [show, setShow] = useState({
    show: false,
    action: "create",
  });
  const [loadingKpiModels, setLoadingKpiModels] = useState(false);
  const [kpiModels, setKpiModels] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [workspaces, setWorkspaces] = useState([]);

  //Formik
  const initialValues = {
    type: show.item ? show.item.type : "",
    kpimodels: show.item
      ? show.item.kpiModels.map((item) => ({
          ...item,
          value: item._id,
          label: item.type,
        }))
      : "",
  };

  const validationSchema = Yup.object({
    type: Yup.string().required("Required"),
    kpimodels: Yup.array().nullable().required("Required"),
  });

  const getKpiModels = async () => {
    try {
      setLoadingKpiModels(true);
      const { data } = await GET_KPI_MODELS();
      const kpis = data.map((item) => ({
        ...item,
        value: item._id,
        label: item.type,
      }));

      setKpiModels(kpis);
      setLoadingKpiModels(false);
    } catch (error) {
      setLoadingKpiModels(false);
      console.log(error.response);
    }
  };

  const getWorkspaces = async () => {
    try {
      setFetching(true);
      const { data } = await GET_WORKSPACES();
      setWorkspaces(data.data);
      setFetching(false);
    } catch (error) {
      setFetching(false);
      console.log(error.response);
    }
  };

  const createHandler = async (formDetails, { resetForm }) => {
    setLoading(true);
    try {
      const kpiModelIds = formDetails.kpimodels.map((item) => item._id);
      const body = {
        type: formDetails.type,
        kpiModels: kpiModelIds,
      };

      if (show.action === "create") {
        await CREATE_WORKSPACE(body);
      } else {
        await UPDATE_WORKSPACE({ id: show.item._id, data: body });
      }
      setWorkspaces([
        {
          type: formDetails.type,
          kpiModels: formDetails.kpimodels,
        },
        ...workspaces,
      ]);

      setShow({
        show: false,
        action: "create",
      });
      resetForm();
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const searchHandler = async (e) => {
    const { data } = await SEARCH_WORKSPACE(e.target.value);
    setWorkspaces(data.data);
  };

  useEffect(() => {
    getKpiModels();
    getWorkspaces();
  }, []);

  return (
    <>
      <Modal show={show.show}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={createHandler}
        >
          {(formik) => (
            <Form>
              <Modal.Header>
                <Modal.Title>Create Workspace Master</Modal.Title>
                <Button
                  onClick={() =>
                    setShow({
                      show: false,
                      action: "create",
                    })
                  }
                  className="btn-close"
                  variant=""
                >
                  x
                </Button>
              </Modal.Header>
              <Modal.Body>
                <FormikController
                  control="input"
                  type="text"
                  label="Type"
                  name="type"
                />
                <FormLabel>
                  Kpi Model <span className="text-red">*</span>
                </FormLabel>
                <Select
                  isLoading={loadingKpiModels}
                  options={kpiModels}
                  isMulti
                  name="kpimodels"
                  value={formik.values.kpimodels}
                  onChange={(selectedOption) => {
                    formik.setFieldValue("kpimodels", selectedOption);
                  }}
                />
                {formik.errors.kpimodels && formik.touched.kpimodels ? (
                  <div className="error">{formik.errors.kpimodels}</div>
                ) : null}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="primary"
                  className="me-1"
                  onClick={() =>
                    setShow({
                      show: false,
                      action: "create",
                    })
                  }
                >
                  Close
                </Button>
                <Button
                  variant="default"
                  className="me-1"
                  disabled={loading}
                  type="submit"
                >
                  {show.action === "create" ? "Create" : "Update"}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <Box sx={{ gridArea: "first", marginTop: 5 }}>
        <Card>
          <Card.Header className="card-header">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <h3 className="card-title">Workspace Master</h3>
              <div style={{ display: "flex" }}>
                <FormControl
                  type="text"
                  className="form-control"
                  placeholder="type..."
                  name="type"
                  // value={source}
                  onChange={searchHandler}
                />
                <Box ml={2}>
                  <Button
                    className="btn btn-primary-light"
                    variant="primary-light"
                    onClick={() => {
                      setShow({
                        show: true,
                        action: "create",
                      });
                    }}
                  >
                    Create
                  </Button>
                </Box>
              </div>
            </Box>
          </Card.Header>

          <Card.Body className="card-body pb-2">
            <Row>
              {fetching ? (
                <Loader height="50" logoHeight="100px" />
              ) : !workspaces.length ? (
                <Box
                  sx={{
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <h5>No Workspaces found!</h5>
                </Box>
              ) : (
                workspaces.map((item, index) => (
                  <Col sm={12} md={6} xl={4} key={index}>
                    <Card>
                      <Card.Header>
                        <h3
                          className="card-title"
                          style={{ textTransform: "capitalize" }}
                        >
                          {item.type}
                        </h3>
                        <div className="card-options">
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={() =>
                              setShow({
                                show: true,
                                action: "edit",
                                item,
                              })
                            }
                          >
                            Edit
                          </button>
                        </div>
                      </Card.Header>
                      <Card.Body className="card-body">
                        <div className="tags">
                          {item.kpiModels.map((kpi) => (
                            <span className="tag">{kpi.type}</span>
                          ))}
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                ))
              )}
            </Row>
          </Card.Body>
        </Card>
      </Box>
    </>
  );
};

export default WorkspaceMaster;
