import React, { useState } from "react";
import { Button, FormLabel } from "react-bootstrap";
import { Grid } from "@mui/material";
import { Formik, Form } from "formik";
import ModelSchema from "./ModelSchema.js";

import { SET_KPI_MODEL } from "../../../api/kpi.js";
import { notify } from "../../../helpers/notifications.js";
import FormikController from "../Formik/FormikController.js";
import { startCase } from "lodash";

// This must sync with FORM_OPTIONS at gmb-client\src\components\FormBuilder\formElementOptions.jsx
// HINT: this should follow convention for HTML input tpyes (https://www.w3schools.com/html/html_form_input_types.asp)
const INPUT_TYPES = ["text", "date", "image", "number", "checkbox"];

const KpiModelsForm = ({ kpis, config, onSave, onCancel }) => {
  const [action] = useState(config? "update" : "create");
  const [loading, setLoading] = useState(false);
  
  const inputsObj = {};
  config?.defaultInputs?.forEach(input => {
    inputsObj[input.value] = input.type;
  });

  const [inputKeys, setInputKeys] = useState(
    Object.keys(inputsObj).length > 0 ? [...Object.keys(inputsObj)] : [],
  );
  const [newInput, setNewInput] = useState(null);

  //Formik
  const initialValues = {
    type: config?.type ? startCase(config.type) : "",
    defaultTarget: config?.defaultTarget ?? 1,
    defaultPointScale: config?.defaultPointScale ?? 100,
    defaultInputs: inputsObj,
    direction: config?.direction?.toString() ?? "1",
    kind: config?.kind ?? "$",
  };

  const createHandler = async (model, { resetForm }) => {
    const key = model.type.trim().toLowerCase();
    const idx = kpis.findIndex((k) => k.type === key);

    if (action !== "update" && idx >= 0) {
      notify(`${startCase(key)} already exists.`, "error");
    } else {
      setLoading(true);

      if (Object.keys(model.defaultInputs).length > 0) {
        const configuredInputs = Object.keys(model.defaultInputs).map(
          (key) => ({
            value: key,
            type: model.defaultInputs[key],
          }),
        );

        model.defaultInputs = configuredInputs;
      } else {
        delete model.defaultInputs;
      }

      if(action === "update") {
        model._id = config._id;
      }

      SET_KPI_MODEL(model)
        .then(() => {
          notify(`${startCase(model.type)} was saved.`);
          setLoading(false);
          resetForm();
          onSave?.();
        })
        .catch((err) => {
          notify(err.response || err, "error");
          setLoading(false);
        });
    }
  };

  const addNewInput = (currentInputs) => {
    if (newInput) {
      const key = newInput.trim().toLowerCase();
      currentInputs[key] = "text";
      setInputKeys([...inputKeys, key]);
      setNewInput("");
    }
  };

  const removeInput = (currentInputs, key) => {
    delete currentInputs[key];
    const temp = [...inputKeys];
    const index = temp.indexOf(key);
    temp.splice(index, 1); // Remove from list of keys
    setInputKeys(temp);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={ModelSchema}
        onSubmit={createHandler}
      >
        {(formik) => (
          <Form className="px-2 pb-4">
            <h4 className="text-center">{action==="create" ? "Create New" : "Edit"} KPI Model | Data Cube</h4>
            <p  className="text-center"><small>{action === "update" ? "* Most fields can not be updated" : ""}</small></p>
            <FormikController
              control="input"
              type="text"
              label="Type"
              name="type"
              className="px-2"
              disabled={action === "update"}
            />
            <Grid container className="py-2">
              <Grid item xs={12} sm={6} className="px-2">
                <FormikController
                  required
                  control="radio"
                  label="Direction"
                  name="direction"
                  disabled={action === "update"}
                  options={[
                    {
                      value: "1",
                      label: (
                        <>
                          <i className="icon icon-like text-green my-auto" />
                          Positive
                        </>
                      ),
                    },
                    {
                      value: "-1",
                      label: (
                        <>
                          <i className="icon icon-dislike text-red my-auto" />
                          Negative
                        </>
                      ),
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="px-2">
                <FormikController
                  required
                  control="radio"
                  label="Value Type"
                  name="kind"
                  disabled={action === "update"}
                  options={[
                    {
                      value: "$",
                      label: (
                        <>
                          <i className="fe fe-dollar-sign my-auto" />
                          Monetary
                        </>
                      ),
                    },
                    {
                      value: "%",
                      label: (
                        <>
                          <i className="fe fe-percent my-auto" />
                          Percentage
                        </>
                      ),
                    },
                    {
                      value: "#",
                      label: (
                        <>
                          <i className="fe fe-hash my-auto" />
                          Item
                        </>
                      ),
                    },
                  ]}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} className="px-2 py-2">
              <FormikController
                control="input"
                type="number"
                label="Default Target"
                name="defaultTarget"
                disabled={action === "update"}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="px-2 py-2">
              <FormikController
                control="input"
                type="number"
                label="Default Point Scale"
                name="defaultPointScale"
                disabled={action === "update"}
              />
            </Grid>

            <div className="px-2 mt-4">
              <FormLabel className="mb-2">Default Inputs</FormLabel>
            </div>
            <Grid container className="py-2">
              <Grid item xs={12} className="px-2 mb-4 mt-2 d-flex">
                <input
                  type="text"
                  class="form-control w-25"
                  value={newInput}
                  onChange={(e) => setNewInput(e.target.value)}
                  placeholder="Enter unique input field name"
                />
                <Button
                  className="btn btn-secondary w-25 mx-2"
                  onClick={() => addNewInput(formik.values.defaultInputs)}
                >
                  <i className="fe fe-plus me-2" />
                  Add Input
                </Button>
              </Grid>

              {inputKeys &&
                inputKeys.map((key) => {
                  return (
                    <Grid
                      item
                      xs={8}
                      className="px-2 d-flex align-items-center align-content-between"
                    >
                      <div className="w-25 mb-2">
                        <Button
                          disabled
                          className="btn btn-secondary w-100 px-2"
                        >
                          <span className="px-4">{startCase(key)}</span>
                        </Button>
                      </div>

                      <div className="px-2 w-25">
                        <FormikController
                          control="select"
                          options={INPUT_TYPES.map((p) => ({
                            label: startCase(p),
                            value: p,
                          }))}
                          name={`defaultInputs.${key}`}
                        />
                      </div>
                      <div className="px-2 w-10">
                        <Button
                          variant="secondary"
                          className="px-2 text-center"
                          onClick={() =>
                            removeInput(formik.values.defaultInputs, key)
                          }
                        >
                          <i className="fe fe-trash-2" />
                        </Button>
                      </div>
                    </Grid>
                  );
                })}
            </Grid>

            <div className="modal-footer mt-4 gap-2">
              <Button
                variant=""
                className="btn btn-outline-secondary"
                onClick={() => {
                  formik.resetForm();
                  onCancel?.();
                }}
              >
                <i className="fe fe-trash-2 me-2" />
                Cancel
              </Button>
              <Button variant="primary" disabled={loading} type="submit">
                <i className="fe fe-save me-2" />
                {action === "create" ? "Create" : "Update"}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default KpiModelsForm;
