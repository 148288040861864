export const initState = {
  version: 0,
  synced: true,
  authUserId: null,
  authName: null,
  error: null,
  tickets: null,
  companies: null,
};

export const types = {
  GET_VERSION: "GET_VERSION",
  PUT_VERSION: "PUT_VERSION",
  VERSION_SYNCED: "VERSION_SYNCED",
  API_OFFLINE: "API_OFFLINE",

  LOGIN_REQUEST: "LOGIN_REQUEST",
  SOCIAL_LOGIN_REQUEST: "SOCIAL_LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILED: "LOGIN_FAILED",

  GET_TICKETS_REQUEST: "GET_TICKETS_REQUEST",
  GET_TICKETS_SUCCESS: "GET_TICKETS_SUCCESS",
  GET_TICKETS_FAILED: "GET_TICKETS_FAILED",

  GET_RECENT_COMPANIES_REQUEST: "GET_RECENT_COMPANIES_REQUEST",
  GET_RECENT_COMPANIES_SUCCESS: "GET_RECENT_COMPANIES_SUCCESS",
  GET_RECENT_COMPANIES_FAILED: "GET_RECENT_COMPANIES_FAILED",

  SIGN_OUT: "SIGN_OUT",
};