import { request } from "../helpers/axios";
import { COMPANY_ID as DEMO_COMPANY_ID } from "../constants/demo";

export const CREATE_WORKSPACE = (data) => {
  return request({
    url: `/workspace`,
    method: "POST",
    data,
  });
};

export const UPDATE_WORKSPACE = ({ id, data }) => {
  return request({
    url: `/workspace?workspaceId=${id}`,
    method: "PATCH",
    data,
  });
};

export const SEARCH_WORKSPACE = (text) => {
  return request({
    url: `/workspace/search?text=${text}`,
    method: "GET",
  });
};

export const GET_KPI_MODELS = () => {
  return request({
    url: `/kpi/kpi-model`,
    method: "GET",
  });
};

export const GET_WORKSPACES = (data) => {
  return request({
    url: `/workspace`,
    method: "GET",
  });
};

export const GET_DEMO_WORKSPACES = () => {
  return request({
    url: `/group/${DEMO_COMPANY_ID}`,
    method: "GET",
  });
};

