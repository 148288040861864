import { request } from "../helpers/axios";

export const CREATE_NEW_INDUSTRY = (body) => {
  return request({
    url: `/industry/add-industry`,
    method: "POST",
    data: body,
  });
};

export const GET_ALL_INDUSTRIES = ({ page = 1, limit = 50, filter = null }) => {
  return request({
    url: `/industry`,
    method: "GET",
    params: { page, limit, filter },
  });
};
