import axios from "axios";

export const instance = axios.create({ baseURL: process.env.REACT_APP_LINK_HOST + "/api" });

/*
  axios.defaults.withCredentials = true;
*/

export const request = async ({ ...options }) => {
  const authToken = localStorage.getItem('token');

  instance.defaults.headers = {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    ...authToken && { "Authorization": `Bearer ${authToken}` },
    ...options.headers,
  };

  return new Promise((resolve, reject) => {
    instance(options)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};
