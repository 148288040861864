import React from "react";

import Input from "./form-elements/Input.js";
import Radio from "./form-elements/Radio.js";
import Select from "./form-elements/Select.js";
import Date from "./form-elements/Date.js";

function FormikController(props) {
  const { control, ...rest } = props;
  switch (control) {
    case "input":
      return <Input {...rest} />;
    case "radio":
      return <Radio {...rest} />;
    case "select":
        return <Select {...rest} />;
    case "date":
      return <Date {...rest} />;
    default:
      return null;
  }
}
export default FormikController;
