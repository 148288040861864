import React, { useEffect } from "react";
import { Card, Button } from "react-bootstrap";
import { LoginSocialGoogle } from 'reactjs-social-login';

import { UseAuth } from "../hooks/auth";
import { socialLoginUser } from "../redux/action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const GOOLE_AUTH_SCOPE = "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile";

export default function Login() {
  const auth = UseAuth();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if(auth) {
      navigate("/");
    }
  }, [auth, navigate])

  return (
    <div className="login-img">
      <div className="page">
        <div className="container-login100">
          <div className="wrap-login100 p-0">
            <Card.Body>
              <div className="col col-login mx-auto">
                <div className="text-center">
                  <img
                    src={require("../assets/images/brand/logo.png")}
                    className="header-brand-img"
                    alt=""
                  />
                </div>
              </div>
              <div className="mt-4">
                <LoginSocialGoogle
                  client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
                  scope={GOOLE_AUTH_SCOPE}
                  onResolve={({ provider, data }) => {
                    dispatch(socialLoginUser(data.access_token, provider.toLowerCase()))
                  }}
                >
                  <Button>
                    LOGIN with GOOGLE
                  </Button>
                </LoginSocialGoogle>
              </div>
            </Card.Body>
          </div>
        </div>
      </div>
    </div>
  );
}
