import React from "react";
import { Field, ErrorMessage } from "formik";
import { FormLabel } from "react-bootstrap";

import Error from "./Error.js";

const Radio = (props) => {
  const { name, label, options, className, helperText, required, ...rest } = props;

  return (
    <div className={`formik-controller ${className}`}> 
      <FormLabel style={{ display: "block" }} htmlFor={name}>
        {label} { required && <span className="text-red">*</span> }
      </FormLabel>
      {
        helperText && <span className="text-muted">{helperText}</span>
      }
      <div role="group" >
      {
        options && options.length ? options.map(({label, value}) => (
            <label key={value} className="custom-control custom-radio">
              <Field name={name} value={value} type="radio" {...rest} className="custom-control-input"/>
              <div className="custom-control-label text-center" style={{display: "flex", gap: 2}}>
                {label}
              </div>
            </label>
        )) :
        <h6 className="py-4 text-muted">No options given</h6>
      }
      </div>
      <ErrorMessage name={name} component={Error} />
    </div>
  );
};

export default Radio;
