import { request } from "../helpers/axios";

export const CREATE_PLUGIN = (data) => {
  return request({
    url: `/pluginmaster`,
    method: "POST",
    data,
  });
};

export const UPDATE_PLUGIN = (id, data) => {
  return request({
    url: `/pluginmaster?id=${id}`,
    method: "PATCH",
    data,
  });
};

export const FETCH_PLUGINS = () => {
  return request({
    url: `/pluginmaster?all=${true}`,
    method: "GET",
  });
};

export const SEARCH_PLUGINS = (text) => {
  return request({
    url: `/pluginmaster/search?text=${text}`,
    method: "GET",
  });
};
