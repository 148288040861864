import React, { useMemo } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import startCase from "lodash/startCase";
import defaultCardMedia from "../../../../img/cardmedia_default.png";
import { ReactComponent as RocketIcon } from "../../../../img/streamline-emojis_rocket.svg";
import "../styles/preview.css";

const JetpackPreview = ({ values, previewOnly = false }) => {
  const data = useMemo(() => values, [values]);
  const adminEnabled = useMemo(
    () => data?._id && !previewOnly,
    [data?._id, previewOnly],
  );
  const navigate = useNavigate();

  return (
    <Card elevation={2} className="cardPaper">
      {adminEnabled && (
        <Button
          size="small"
          variant="outlined"
          color="warning"
          sx={{ position: "absolute", top: "4px", right: "12px" }}
          onClick={() =>
            navigate(`/jetpacks/edit/${data?._id}`, { state: data })
          }
        >
          <i className="icon icon-note me-2" />
          Edit
        </Button>
      )}
      <CardMedia
        className="cardImage"
        component="img"
        src={data?.image || defaultCardMedia}
        title="Jetpack Image"
      />
      <CardContent className="cardContent">
        <div className="d-flex align-items-center">
          <Typography className="cardTitle">
            {startCase(data.name || "Jetpack Name")}
          </Typography>
          <RocketIcon width="24px" height="24px" />
        </div>
        <Typography className="cardText">
          {data.desc || "Put jetpack description here..."}
        </Typography>
        <Button
          color="primary"
          variant="contained"
          className="mt-5"
          onClick={() => window.open(data.url, "_blank")}
        >
          Start {data.trial}-day Free Trial
        </Button>
      </CardContent>
      {adminEnabled && (
        <Typography
          variant="caption"
          sx={{ position: "absolute", bottom: "20px", right: "12px" }}
        >
          <i className="icon icon-note me-1" /> By {data?.admin?.firstName} on {moment(data?.updatedAt).format('MMM-DD')}
        </Typography>
      )}
    </Card>
  );
};

export default JetpackPreview;
