import React from "react";
import CountUp from "react-countup";
import { Card, Row } from "react-bootstrap";

const DataCards = ({title, amount, prefix, color='primary', icon='money', textColor='dark'}) => {
  return (
    <Card className="overflow-hidden">
      <Card.Body className="card-body">
        <Row>
          <div className="col">
            <h6 className="">{title}</h6>
            <h1 className={`mb-2 number-font text-${textColor}`}>
              {prefix}
              <CountUp end={amount} separator="," start={0} duration={2.94} />
            </h1>
          </div>
          <div className="col col-auto">
            <div className={`counter-icon bg-${color}-gradient box-shadow-${color} brround ms-auto`}>
              <i className={`fa fa-${icon} text-white mb-5`} />
            </div>
          </div>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default DataCards;
