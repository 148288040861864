import React from "react";

// Imported Images and Icons
import gmbLogoLoad from "../../../img/gmb-finaldetail.gif";

const LoadingScreen = ({ height, logoHeight, logoWidth }) => {
  return (
    <div
      style={{
        height: `${height || "50vh"}`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "500px",
        margin: "0 auto",
        padding: "0 2.5rem",
      }}
    >
      <img
        src={gmbLogoLoad}
        alt=""
        style={{
          height: `${logoHeight || "auto"}`,
          width: `${logoWidth || "auto"}`,
          maxWidth: "100%",
        }}
      />
    </div>
  );
}

export default LoadingScreen;