import { toast } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import 'react-toastify/dist/ReactToastify.css';

if (typeof window !== "undefined") {
  injectStyle();
}

export const notify = (mesage, type = "success") => toast[type](
    <p className="text-white tx-16 mb-0">
    {mesage}
    </p>, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
          autoClose:5000,
          theme: "colored",
        }
      );
  
export const notifySuccess = (message) => notify(message, "success");
export const notifyError = (message) => notify(message, "error");
export const notifyInfo = (message) => notify(message, "info");
export const notifyWarn = (message) => notify(message, "warn");