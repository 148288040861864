import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Modal,
  FormControl,
} from "react-bootstrap";
import { Box, Pagination } from "@mui/material";
import { Formik, Form } from "formik";
import IndustryTable from "./IndustryTable.jsx";
import { GET_ALL_INDUSTRIES, CREATE_NEW_INDUSTRY } from "../../../api/industries";
import LoadingScreen from "../LoadingScreen/index.jsx";
import FormikController from "../Formik/FormikController.js";

const LIMIT = 50;

const Industries = () => {
  const [industries, setIndustries] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(null);
  const [show, setShow] = useState({
    show: false,
    action: "create",
  });

  // Formik initial values
  const initialValues = {
    name: "",
  };

  const createHandler = async (formDetails, { resetForm }) => {
    setLoading(true);
    try {
      const body = {
        name: formDetails.name,
      };

      if (show.action === "create") {
        await CREATE_NEW_INDUSTRY(body);
      }

      setShow({
        show: false,
        action: "create",
      });
      getNewIndustries();
      resetForm();
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const getNewIndustries = async (page = 1) => {
    setLoading(true);
    try {
      const { data } = await GET_ALL_INDUSTRIES({
        page,
        limit: LIMIT,
        ...(filter && { filter: filter.trim() }),
      });
      const { docs, total } = data;
      setIndustries(docs);
      setTotalPages(Math.ceil(total / LIMIT));
    } catch (err) {
      console.log(err.response);
    }
    setLoading(false);
  };

  const handleChange = async (event, value) => {
    getNewIndustries(value);
  };

  useEffect(() => {
    getNewIndustries();
  }, [filter]);
  

  return (
    <Box sx={{ gridArea: "first", marginTop: 5 }}>
      <Modal show={show.show}>
        <Formik
          initialValues={initialValues}
          onSubmit={createHandler}
        >
          {(formik) => (
            <Form>
              <Modal.Header>
                <Modal.Title>Create Industry</Modal.Title>
                <Button
                  onClick={() =>
                    setShow({
                      show: false,
                      action: "create",
                    })
                  }
                  className="btn-close"
                  variant=""
                >
                  x
                </Button>
              </Modal.Header>
              <Modal.Body>
                <FormikController
                  control="input"
                  type="text"
                  label="Name of Industry"
                  name="name"
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="default"
                  className="me-1"
                  disabled={loading}
                  type="submit"
                >
                  {show.action === "create" ? "Create" : "Update"}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <Button
        className="btn btn-primary-light mb-2"
        variant="primary-light"
        onClick={() => {
          setShow({
            show: true,
            action: "create",
          });
        }}
      >
        Create
      </Button>
      <Card>
        <Card.Header className="card-header d-flex justify-content-between">
          <h3 className="card-title">Industries</h3>
          <Box className="d-flex w-50">
            <FormControl
              type="text"
              placeholder="Search Industry name ..."
              name="name"
              className="me-2"
              onChange={(e) => setFilter(e.target.value)}
            />
            <Button
              className="btn btn-primary-light w-50"
              variant="primary-light"
              onClick={() => getNewIndustries(1)}
            >
              <i className="icon icon-magnifier me-2" /> Search
            </Button>
          </Box>
        </Card.Header>
        <Card.Body className="card-body">
          {loading ? (
            <LoadingScreen />
          ) : !industries?.length ? (
            <Box
              sx={{
                width: "100%",
                textAlign: "center",
              }}
            >
              <h5>No Industries found!</h5>
            </Box>
          ) : (
            <IndustryTable industries={industries} />
          )}
          {totalPages > 1 && (
            <Pagination count={totalPages} onChange={handleChange} />
          )}
        </Card.Body>
      </Card>
    </Box>
  );
};

export default Industries;
