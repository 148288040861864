import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Button } from "react-bootstrap";
import { Box } from "@mui/material";

import { ADD_JETPACK } from "../../../api/jetpacks.js";
import { Formik, Form } from "formik";
import initialValues from "./config/initialValues.js";
import jetpackSchema from "./config/validation.js";

import startCase from "lodash/startCase";
import { notify } from "../../../helpers/notifications.js";

import JetpackForm from "./elements/Form.jsx";

const JetpackCreate = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const formValues = {
    ...initialValues,
    ...(location.state && {
      ...location.state,
      name: startCase(location.state.name),
    }),
  };

  const handleSubmit = async (formDetails, { resetForm }) => {
    setLoading(true);
    try {
      const payload = { ...formDetails };
      await ADD_JETPACK(payload);
      resetForm();
      notify("Jetpack created successfully!");
      navigate("/jetpacks/list");
    } catch (err) {
      notify(err.response?.data ?? "Unknown Error", "error");
      console.log(err.response);
    }
    setLoading(false);
  };

  return (
    <Box sx={{ gridArea: "first", marginTop: 5 }}>
      <Formik
        initialValues={formValues}
        validationSchema={jetpackSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <Card>
              <Card.Header className="card-header">
                <Box className="w-100 d-flex justify-content-between align-items-center">
                  <h3 className="card-title">Create Jetpack</h3>
                  <Box>
                    <Button
                      variant="warning-light"
                      onClick={() => navigate("/jetpacks/list")}
                      className="mx-4"
                      disabled={loading}
                    >
                      <i className="icon icon-book-open me-2" />
                      View List
                    </Button>
                    <Button variant="primary" type="submit" disabled={loading}>
                      <i className="icon icon-rocket me-2" />
                      Save Jetpack
                    </Button>
                  </Box>
                </Box>
              </Card.Header>

              <Card.Body>
                <Box className="w-100 d-flex justify-content-center align-items-center">
                  <div className="w-100 h-auto">
                    <JetpackForm />
                  </div>
                </Box>
              </Card.Body>
            </Card>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default JetpackCreate;
