import { request } from "../helpers/axios";

export const GET_KPI_MODELS = () => {
  return request({
    url: `/kpi/models`,
    method: "GET",
  });
};

export const SET_KPI_MODEL = (data) => {
  return request({
    url: `/kpi/model`,
    method: "PUT",
    data,
  });
};

export const GET_KPI_INTEGRATIONS = () => {
  return request({
    url: `/kpi/integrations`,
    method: "GET",
  });
};

export const SET_KPI_INTEGRATION = (data) => {
  return request({
    url: `/kpi/integration`,
    method: "PUT",
    data,
  });
};