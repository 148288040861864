import React, { useState, useEffect } from "react";
import { Card, Row, Col, Modal, Button, FormLabel } from "react-bootstrap";
import FormBootstrap from "react-bootstrap/Form";
import { Box, Switch } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Select from "react-select";

import {
  GET_RESTRICTION,
  CREATE_RESTRICTION,
} from "../../../api/restriction.js";
import { notifySuccess } from "../../../helpers/notifications.js";
import Loader from "../LoadingScreen/index";
import FormikController from "../Formik/FormikController";
import "./style.css";

const Restrictions = () => {
  const [restrictions, setRestrictions] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [features, setFeatures] = useState([
    {
      value: "scorekeeper",
      label: "Ultimate Business Scoreboard",
    },
    {
      value: "hub",
      label: "Hub",
    },
    {
      value: "feed",
      label: "Job Feed",
    },
    {
      value: "claims",
      label: "Claims",
    },
    {
      value: "level-up",
      label: "Level Up",
    },
    {
      value: "messages",
      label: "Messages",
    },
    {
      value: "affiliates",
      label: "Affiliates",
    },
  ]);

  const fetchRestrictions = async () => {
    setFetching(true);
    try {
      const { data } = await GET_RESTRICTION();

      if (data.length) {
        setRestrictions(data);
        const updatedFeatures = features.filter((item) => {
          const el = data[0].accessControl.find(
            (curr) => curr.feature === item.value,
          );
          if (!el) return item;
        });

        setFeatures(updatedFeatures);
      }
    } catch (error) {
      console.log(error);
    }
    setFetching(false);
  };

  useEffect(() => {
    fetchRestrictions();
  }, []);

  const createHandler = async (formDetails, { resetForm }) => {
    const key = formDetails.feature.value;
    const featureName = formDetails.feature.label;
    const maxUserLevelZero = Number(formDetails.maxUserLevelZero);
    const maxUserLevelOne = Number(formDetails.maxUserLevelOne);
    const maxUserLevelTwo = Number(formDetails.maxUserLevelTwo);
    const maxUserLevelThree = Number(formDetails.maxUserLevelThree);

    const maxWorkspaceLevelZero = Number(formDetails.maxWorkspaceLevelZero);
    const maxWorkspaceLevelOne = Number(formDetails.maxWorkspaceLevelOne);
    const maxWorkspaceLevelTwo = Number(formDetails.maxWorkspaceLevelTwo);
    const maxWorkspaceLevelThree = Number(formDetails.maxWorkspaceLevelThree);

    const maxAdminLevelZero = Number(formDetails.maxAdminLevelZero);
    const maxAdminLevelOne = Number(formDetails.maxAdminLevelOne);
    const maxAdminLevelTwo = Number(formDetails.maxAdminLevelTwo);
    const maxAdminLevelThree = Number(formDetails.maxAdminLevelThree);
    let updatedRestrictions = [];

    if (restrictions.length) {
      updatedRestrictions = restrictions.map((item) => ({
        level: item.level,
        maxUsers:
          item.level === 0
            ? maxUserLevelZero
            : item.level === 1
            ? maxUserLevelOne
            : item.level === 2
            ? maxUserLevelTwo
            : maxUserLevelThree,
        maxWorkspaces:
          item.level === 0
            ? maxWorkspaceLevelZero
            : item.level === 1
            ? maxWorkspaceLevelOne
            : item.level === 2
            ? maxWorkspaceLevelTwo
            : maxWorkspaceLevelThree,
        maxAdmin:
          item.level === 0
            ? maxAdminLevelZero
            : item.level === 1
            ? maxAdminLevelOne
            : item.level === 2
            ? maxAdminLevelTwo
            : maxAdminLevelThree,
        accessControl: [
          ...item.accessControl,
          {
            feature: key,
            featureName,
            allow:
              item.level === 0
                ? formDetails.levelZero
                : item.level === 1
                ? formDetails.levelOne
                : item.level === 2
                ? formDetails.levelTwo
                : formDetails.levelThree,
          },
        ],
      }));
    } else {
      updatedRestrictions = [
        {
          level: 0,
          maxUsers: maxUserLevelZero,
          maxWorkspaces: maxWorkspaceLevelZero,
          maxAdmin: maxAdminLevelZero,
          accessControl: [
            {
              feature: key,
              featureName,
              allow: formDetails.levelZero,
            },
          ],
        },
        {
          level: 1,
          maxUsers: maxUserLevelOne,
          maxWorkspaces: maxWorkspaceLevelOne,
          maxAdmin: maxAdminLevelOne,
          accessControl: [
            {
              feature: key,
              featureName,
              allow: formDetails.levelOne,
            },
          ],
        },
        {
          level: 2,
          maxUsers: maxUserLevelTwo,
          maxWorkspaces: maxWorkspaceLevelTwo,
          maxAdmin: maxAdminLevelTwo,
          accessControl: [
            {
              feature: key,
              featureName,
              allow: formDetails.levelTwo,
            },
          ],
        },
        {
          level: 3,
          maxUsers: maxUserLevelThree,
          maxWorkspaces: maxWorkspaceLevelThree,
          maxAdmin: maxAdminLevelThree,
          accessControl: [
            {
              feature: key,
              featureName,
              allow: formDetails.levelThree,
            },
          ],
        },
      ];
    }

    try {
      await Promise.all(
        updatedRestrictions.map(async (item) => await CREATE_RESTRICTION(item)),
      );
      resetForm();
      setShowModal(false);
      setRestrictions(updatedRestrictions);
      notifySuccess("Restriction imposed successfully");
    } catch (error) {
      console.log(error);
    }
  };

  const updateAllow = async (index, status, secondIndex) => {
    const restrictionsCopy = [...restrictions];
    restrictionsCopy[index].accessControl[secondIndex].allow = !status;

    setRestrictions(restrictionsCopy);
  };

  const updateUserLimit = async (index, limit) => {
    limit = Number(limit);

    if (!isNaN(limit)) {
      const restrictionsCopy = [...restrictions];
      restrictionsCopy[index].maxUsers = limit;

      setRestrictions(restrictionsCopy);
    }
  };

  const updateWorkspaceLimit = async (index, limit) => {
    limit = Number(limit);

    if (!isNaN(limit)) {
      const restrictionsCopy = [...restrictions];
      restrictionsCopy[index].maxWorkspaces = limit;

      setRestrictions(restrictionsCopy);
    }
  };

  const updateAdminLimit = async (index, limit) => {
    limit = Number(limit);

    if (!isNaN(limit)) {
      const restrictionsCopy = [...restrictions];
      restrictionsCopy[index].maxAdmin = limit;

      setRestrictions(restrictionsCopy);
    }
  };

  const updateLevel = async (index) => {
    setLoading(true);
    try {
      await CREATE_RESTRICTION(restrictions[index]);

      notifySuccess("Level updated successfully");
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  // Formik
  const initialValues = {
    feature: "",
    levelZero: false,
    levelOne: false,
    levelTwo: false,
    levelThree: false,
    maxUserLevelZero: 5,
    maxUserLevelOne: 5,
    maxUserLevelTwo: 5,
    maxUserLevelThree: 5,

    maxWorkspaceLevelZero: 2,
    maxWorkspaceLevelOne: 2,
    maxWorkspaceLevelTwo: 2,
    maxWorkspaceLevelThree: 2,

    maxAdminLevelZero: 2,
    maxAdminLevelOne: 2,
    maxAdminLevelTwo: 2,
    maxAdminLevelThree: 2,
  };

  const validationSchema = Yup.object({
    feature: Yup.object().nullable().required("Required"),
    levelZero: Yup.boolean().required("Required"),
    levelOne: Yup.boolean().required("Required"),
    levelTwo: Yup.boolean().required("Required"),
    levelThree: Yup.boolean().required("Required"),
    maxUserLevelZero: Yup.number("Should be a number")
      .positive()
      .integer("Should be a number"),
    maxUserLevelOne: Yup.number().positive().integer("Should be a number"),
    maxUserLevelTwo: Yup.number().positive().integer("Should be a number"),
    maxUserLevelThree: Yup.number().positive().integer("Should be a number"),
    maxWorkspaceLevelZero: Yup.number("Should be a number")
      .positive()
      .integer("Should be a number"),
    maxWorkspaceLevelOne: Yup.number().positive().integer("Should be a number"),
    maxWorkspaceLevelTwo: Yup.number().positive().integer("Should be a number"),
    maxWorkspaceLevelThree: Yup.number()
      .positive()
      .integer("Should be a number"),
    maxAdminLevelZero: Yup.number("Should be a number")
      .positive()
      .integer("Should be a number"),
    maxAdminLevelOne: Yup.number().positive().integer("Should be a number"),
    maxAdminLevelTwo: Yup.number().positive().integer("Should be a number"),
    maxAdminLevelThree: Yup.number().positive().integer("Should be a number"),
  });

  return (
    <>
      <Modal show={showModal}>
        <Modal.Header>
          <Modal.Title>Create a restriction for a feature</Modal.Title>
          <Button
            onClick={() => {
              setShowModal(false);
            }}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={createHandler}
          >
            {(formik) => (
              <Form>
                <Row>
                  <Col sm={12} md={12} xl={12} className="mb-2">
                    <Select
                      isLoading={fetching}
                      options={features}
                      name="feature"
                      value={formik.values.feature}
                      onChange={(selectedOption) => {
                        formik.setFieldValue("feature", selectedOption);
                      }}
                    />
                    {formik.errors.feature && formik.touched.feature ? (
                      <div className="error">{formik.errors.feature}</div>
                    ) : null}
                  </Col>
                  <Col sm={12} md={6} xl={6}>
                    <div className="d-flex justify-content-between mb-2">
                      <FormLabel htmlFor={"levelZero"}>
                        Impose for level zero
                      </FormLabel>
                      <Switch
                        name="levelZero"
                        checked={formik.values.levelZero === true}
                        onChange={(event, checked) => {
                          formik.setFieldValue(
                            "levelZero",
                            checked ? true : false,
                          );
                        }}
                      />
                    </div>
                  </Col>
                  {!restrictions.length ? (
                    <>
                      <Col sm={12} md={6} xl={6}>
                        <FormLabel>Max User Limit</FormLabel>
                        <FormikController
                          control="input"
                          type="text"
                          name="maxUserLevelZero"
                        />
                      </Col>
                      <Col sm={12} md={6} xl={6}>
                        <FormLabel>Max Workspace Limit</FormLabel>
                        <FormikController
                          control="input"
                          type="text"
                          name="maxWorkspaceLevelZero"
                        />
                      </Col>
                      <Col sm={12} md={6} xl={6}>
                        <FormLabel>Max Admin Limit</FormLabel>
                        <FormikController
                          control="input"
                          type="text"
                          name="maxAdminLevelZero"
                        />
                      </Col>
                      <hr className="mt-4" />
                    </>
                  ) : null}

                  <Col sm={12} md={6} xl={6}>
                    <div className="d-flex justify-content-between mb-2">
                      <FormLabel htmlFor={"levelOne"}>
                        Impose for level one
                      </FormLabel>
                      <Switch
                        name="levelOne"
                        checked={formik.values.levelOne === true}
                        onChange={(event, checked) => {
                          formik.setFieldValue(
                            "levelOne",
                            checked ? true : false,
                          );
                        }}
                      />
                    </div>
                  </Col>
                  {!restrictions.length ? (
                    <>
                      <Col sm={12} md={6} xl={6}>
                        <FormLabel>Max User Limit</FormLabel>
                        <FormikController
                          control="input"
                          type="text"
                          name="maxUserLevelOne"
                        />
                      </Col>
                      <Col sm={12} md={6} xl={6}>
                        <FormLabel>Max Workspace Limit</FormLabel>
                        <FormikController
                          control="input"
                          type="text"
                          name="maxWorkspaceLevelOne"
                        />
                      </Col>
                      <Col sm={12} md={6} xl={6}>
                        <FormLabel>Max Admin Limit</FormLabel>
                        <FormikController
                          control="input"
                          type="text"
                          name="maxAdminLevelOne"
                        />
                      </Col>
                      <hr className="mt-4" />
                    </>
                  ) : null}

                  <Col sm={12} md={6} xl={6}>
                    <div className="d-flex justify-content-between mb-2">
                      <FormLabel htmlFor={"levelTwo"}>
                        Impose for level two
                      </FormLabel>
                      <Switch
                        name="levelTwo"
                        checked={formik.values.levelTwo === true}
                        onChange={(event, checked) => {
                          formik.setFieldValue(
                            "levelTwo",
                            checked ? true : false,
                          );
                        }}
                      />
                    </div>
                  </Col>
                  {!restrictions.length ? (
                    <>
                      <Col sm={12} md={6} xl={6}>
                        <FormLabel>Max User Limit</FormLabel>
                        <FormikController
                          control="input"
                          type="text"
                          name="maxUserLevelTwo"
                        />
                      </Col>
                      <Col sm={12} md={6} xl={6}>
                        <FormLabel>Max Workspace Limit</FormLabel>
                        <FormikController
                          control="input"
                          type="text"
                          name="maxWorkspaceLevelTwo"
                        />
                      </Col>
                      <Col sm={12} md={6} xl={6}>
                        <FormLabel>Max Admin Limit</FormLabel>
                        <FormikController
                          control="input"
                          type="text"
                          name="maxAdminLevelTwo"
                        />
                      </Col>
                      <hr className="mt-4" />
                    </>
                  ) : null}

                  <Col sm={12} md={6} xl={6}>
                    <div className="d-flex justify-content-between mb-2">
                      <FormLabel htmlFor={"levelThree"}>
                        Impose for level three
                      </FormLabel>
                      <Switch
                        name="levelThree"
                        checked={formik.values.levelThree === true}
                        onChange={(event, checked) => {
                          formik.setFieldValue(
                            "levelThree",
                            checked ? true : false,
                          );
                        }}
                      />
                    </div>
                  </Col>
                  {!restrictions.length ? (
                    <>
                      <Col sm={12} md={6} xl={6}>
                        <FormLabel>Max User Limit</FormLabel>
                        <FormikController
                          control="input"
                          type="text"
                          name="maxUserLevelThree"
                        />
                      </Col>
                      <Col sm={12} md={6} xl={6}>
                        <FormLabel>Max Workspace Limit</FormLabel>
                        <FormikController
                          control="input"
                          type="text"
                          name="maxWorkspaceLevelThree"
                        />
                      </Col>
                      <Col sm={12} md={6} xl={6}>
                        <FormLabel>Max Admin Limit</FormLabel>
                        <FormikController
                          control="input"
                          type="text"
                          name="maxAdminLevelThree"
                        />
                      </Col>
                      <hr className="mt-4" />
                    </>
                  ) : null}
                </Row>

                <Box
                  mt="25px"
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Button
                    variant=""
                    className="btn btn-outline-danger"
                    disabled={loading}
                    onClick={() => {
                      setShowModal(false);
                    }}
                  >
                    <i className="fe fe-trash-2 me-2" />
                    Cancel
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    variant="primary"
                    className="me-1"
                    disabled={loading}
                    type="submit"
                  >
                    <i className="fe fe-save me-2" />
                    Create
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      <Box sx={{ gridArea: "first", marginTop: 5 }}>
        <Card>
          <Card.Header className="card-header">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <h3 className="card-title">Restrictions</h3>

              <Button
                className="btn btn-primary-light"
                variant="primary-light"
                onClick={() => setShowModal(true)}
              >
                <>
                  <i className="icon icon-note me-2" />
                  CREATE
                </>
              </Button>
            </Box>
          </Card.Header>
          <Card.Body className="card-body pb-0">
            {fetching ? (
              <Loader height="50" logoHeight="100px" />
            ) : !restrictions.length ? (
              <Box
                sx={{
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <h5>No restriction found!</h5>
              </Box>
            ) : (
              <Row>
                {restrictions.map((data, index) => (
                  <Col sm={12} md={12} lg={6} xl={6} key={data.level}>
                    <Card className="card-shadow">
                      <Card.Header className="card-header custom-header">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <h3 className="card-title">Level {data.level}</h3>
                          <Button
                            variant=""
                            className="me-1 btn btn-outline-success"
                            disabled={loading}
                            onClick={() => updateLevel(index)}
                          >
                            <i className="fe fe-save me-2" />
                            Update
                          </Button>
                        </Box>
                      </Card.Header>
                      <Card.Body>
                        <FormLabel>Max User Limit</FormLabel>
                        <FormBootstrap.Control
                          type="number"
                          className="mb-4"
                          defaultValue={data.maxUsers}
                          onChange={(e) => {
                            updateUserLimit(index, e.target.value);
                          }}
                        />
                        <FormLabel>Max Workspace Limit</FormLabel>
                        <FormBootstrap.Control
                          type="number"
                          className="mb-4"
                          defaultValue={data.maxWorkspaces}
                          onChange={(e) => {
                            updateWorkspaceLimit(index, e.target.value);
                          }}
                        />
                        <FormLabel>Max Admin Limit</FormLabel>
                        <FormBootstrap.Control
                          type="number"
                          className="mb-4"
                          defaultValue={data.maxAdmin}
                          onChange={(e) => {
                            updateAdminLimit(index, e.target.value);
                          }}
                        />
                        <hr />
                        <FormLabel>Feature Restrictions</FormLabel>
                        {data.accessControl.map((item, secondIndex) => (
                          <>
                            <div className="level-card" key={item.feature}>
                              <p>{item.featureName}</p>
                              <label className="custom-switch ps-0">
                                <input
                                  type="checkbox"
                                  name="custom-switch-checkbox"
                                  className="custom-switch-input"
                                  checked={item.allow}
                                  onChange={() => {
                                    updateAllow(index, item.allow, secondIndex);
                                  }}
                                />
                                <span className="custom-switch-indicator"></span>
                              </label>
                            </div>
                          </>
                        ))}
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            )}
          </Card.Body>
        </Card>
      </Box>
    </>
  );
};

export default Restrictions;
