import { request } from "../helpers/axios";

export const CREATE_MASTER_BADGE = (data) => {
  return request({
    url: `/badge/master`,
    method: "POST",
    data,
  });
};

export const UPDATE_MASTER_BADGE = (data) => {
  return request({
    url: `/badge/master`,
    method: "PATCH",
    data,
  });
};

export const GET_MASTER_BADGE = () => {
  return request({
    url: `/badge/master`,
    method: "GET",
  });
};

export const UPLOAD_GRAPHICS = (data) => {
  return request({
    url: `/badge/upload`,
    method: "PUT",
    data,
  });
};
