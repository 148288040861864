import React, { useState, useEffect } from "react";
import moment from "moment";
import { Card, Button, FormLabel, Row, Col } from "react-bootstrap";
import { Box, Switch } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import FormikController from "../Formik/FormikController.js";
import { GET_KPI_MODELS } from "../../../api/kpi.js";
import { notify } from "../../../helpers/notifications.js";
import { CREATE_CHALLENGE_ARENA } from "../../../api/challenge.js";
import { Link, useLocation } from "react-router-dom";

const challengeCategories = [
  {
    key: "EmployeeVsEmployee",
    value: "EmployeeVsEmployee",
    label: "Employee vs Employee",
  },
  {
    key: "CompanyVsCompany",
    value: "CompanyVsCompany",
    label: "Company vs Company",
  },
  {
    key: "CompanyGroups",
    value: "CompanyGroups",
    label: "Company Group Challenge",
  },
];

const Create = () => {
  const [loading, setLoading] = useState(false);
  const [types, setTypes] = useState([]);
  const location = useLocation();
  const challenge = location?.state?.challenge ?? {};

  // Formik
  const initialValues = {
    type: challenge.type ?? "review",
    category: challenge.category ?? "",
    title: challenge.title ?? "King of the Mountain",
    subtitle: challenge.subtitle ?? "",
    maxPlayers: challenge.maxPlayers ?? "",
    isPrivate: challenge.isPrivate ?? false,
    startDate: challenge.startDate
      ? moment(challenge.startDate).format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD"),
    endDate: challenge.endDate
      ? moment(challenge.endDate).format("YYYY-MM-DD")
      : "",
    description: challenge.description ?? "",
    yetiPoints: challenge?.yetiPoints ?? "",
  };

  const validationSchema = Yup.object({
    type: Yup.string().required("Required"),
    category: Yup.string().required("Required"),
    title: Yup.string().required("Required"),
    subtitle: Yup.string(),
    maxPlayers: Yup.number()
      .typeError("Max Players must be a numeric value")
      .min(10, "Max Players must be at least 10")
      .max(100, "Max Players cannot exceed 100")
      .nullable(),
    isPrivate: Yup.boolean().required("Required"),
    startDate: Yup.date().nullable(),
    endDate: Yup.date()
      .nullable()
      .when(
        "startDate",
        (startDate, schema) =>
          startDate &&
          schema.min(startDate, "End date must be greater than start date."),
      ),
    description: Yup.string().required("Required"),
    yetiPoints: Yup.number()
      .typeError("Yeti Points must be a numeric value")
      .min(0.01, "Yeti Points must be at least 0.01")
      .max(100, "Yeti Points cannot exceed 100")
      .nullable(),
  });

  const createHandler = async (formDetails, { resetForm }) => {
    setLoading(true);
    try {
      const payload = {
        defaultJoin: false,
        ...formDetails,
      };
      await CREATE_CHALLENGE_ARENA(payload);
      notify("Challenge created successfully!");
      resetForm();
    } catch (err) {
      console.log(err.response);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (challenge?.type) {
      const { type } = challenge;
      setTypes([{ key: type, value: type, label: type }]);
    } else {
      GET_KPI_MODELS().then(({ data }) => {
        const models = data.map((m) => ({
          key: m.type,
          value: m.type,
          label: m.type,
        }));
        setTypes(models);
      });
    }
  }, []);

  return (
    <Box sx={{ gridArea: "first", marginTop: 5 }}>
      <Card>
        <Card.Header className="card-header">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <h3 className="card-title">Create Challenge Arena</h3>
          </Box>
        </Card.Header>
        <Card.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={createHandler}
          >
            {(formik) => (
              <Form>
                <Row className="gy-4">
                  <Col sm={12} md={6} xl={6}>
                    <FormikController
                      control="select"
                      label="Type"
                      name="type"
                      options={types}
                      disabled={!!challenge?.type}
                    />
                  </Col>
                  <Col sm={12} md={6} xl={6}>
                    <FormikController
                      control="select"
                      label="Category"
                      name="category"
                      options={challengeCategories}
                    />
                  </Col>
                  <Col sm={12} md={6} xl={6}>
                    <FormikController
                      control="input"
                      type="text"
                      label="Title"
                      name="title"
                    />
                  </Col>
                  <Col sm={12} md={6} xl={6}>
                    <FormikController
                      control="input"
                      type="text"
                      label="Subtitle"
                      name="subtitle"
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <FormikController
                      control="input"
                      type="number"
                      label="Max Players"
                      name="maxPlayers"
                      helperText="Default value is 10 when not configured"
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <FormLabel
                      style={{ display: "block" }}
                      htmlFor={"isPrivate"}
                    >
                      Is Private <span className="text-red">*</span>
                    </FormLabel>
                    <Switch
                      name="isPrivate"
                      checked={formik.values.isPrivate === true}
                      onChange={(event, checked) => {
                        formik.setFieldValue(
                          "isPrivate",
                          checked ? true : false,
                        );
                      }}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <FormikController
                      control="date"
                      label="Start Date"
                      name="startDate"
                      min={new Date().toISOString().split("T")[0]}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <FormikController
                      control="date"
                      label="End Date"
                      name="endDate"
                      min={
                        new Date(
                          new Date(formik.values.startDate).setDate(
                            new Date(formik.values.startDate).getDate() + 1,
                          ),
                        )
                          .toISOString()
                          .split("T")[0]
                      }
                    />
                  </Col>
                  <Col xs={12}>
                    <FormikController
                      control="input"
                      type="text"
                      label="Description"
                      name="description"
                    />
                  </Col>
                  <Col xs={12}>
                    <FormikController
                      control="input"
                      type="number"
                      label="Daily Yeti Points"
                      name="yetiPoints"
                      helperText="Assigning any non-zero value will add Yeti to the arena, generating the corresponding daily points."
                    />
                  </Col>
                </Row>
                <Box
                  mt="25px"
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Link to="/challenge-arena/" style={{ marginRight: "10px" }}>
                    Cancel
                  </Link>
                  <Button
                    variant="default"
                    className="me-1"
                    disabled={loading}
                    type="submit"
                  >
                    Create
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </Box>
  );
};

export default Create;
