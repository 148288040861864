import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  FormLabel,
  Row,
  Col,
  Form as BootstrapForm,
} from "react-bootstrap";
import { Box } from "@mui/material";
import { Formik, Form } from "formik";
import Select from "react-select";
import * as Yup from "yup";

import FormikController from "../Formik/FormikController.js";
import { GET_KPI_MODELS } from "../../../api/kpi.js";
import { CREATE_MASTER_BADGE, UPLOAD_GRAPHICS } from "../../../api/badge.js";
import { notifySuccess, notifyError } from "../../../helpers/notifications.js";

const CreateMasterBadge = () => {
  const [loading, setLoading] = useState(false);
  const [kpis, setKpis] = useState([]);
  const [image, setImage] = useState(null);

  // Formik
  const initialValues = {
    kpiId: "",
    title: "",
    target: 0,
  };

  const validationSchema = Yup.object({
    kpiId: Yup.object().nullable().required("Required"),
    target: Yup.number().required("Required"),
    title: Yup.string().required("Required"),
  });

  const createHandler = async (formDetails, { resetForm }) => {
    if (image) {
      let formData = new FormData();
      formData.append("image", image);

      const { data } = await UPLOAD_GRAPHICS(formData);

      if (data.key) {
        const payload = {
          ...formDetails,
          kpiId: formDetails.kpiId.value,
          image: data.key,
        };
        setLoading(true);
        try {
          await CREATE_MASTER_BADGE(payload);
          notifySuccess("Badge created successfully");
          resetForm();
          setImage(null);
        } catch (err) {
          console.log(err);
        }
        setLoading(false);
      }
    } else {
      notifyError("Please upload graphics");
    }
  };

  const fetchKpis = async () => {
    setLoading(true);
    try {
      const { data } = await GET_KPI_MODELS();
      const records = data.map((item) => ({
        ...item,
        value: item._id,
        label: item.type,
      }));
      setKpis(records);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchKpis();
  }, []);

  return (
    <Box sx={{ gridArea: "first", marginTop: 5 }}>
      <Card>
        <Card.Header className="card-header">
          <h3 className="card-title">Create Master Badge</h3>
        </Card.Header>
        <Card.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={createHandler}
          >
            {(formik) => (
              <Form>
                <Row>
                  <Col sm={12} md={6} xl={6} className="mb-3">
                    <FormikController
                      control="input"
                      type="text"
                      label="Title"
                      name="title"
                    />
                  </Col>
                  <Col sm={12} md={6} xl={6} className="mb-3">
                    <FormLabel>Industry</FormLabel>
                    <Select
                      name="industry"
                      value={formik.values.kpiId}
                      onChange={(value) => {
                        formik.setFieldValue("kpiId", value);
                      }}
                      placeholder="Select KPI"
                      isClearable
                      isLoading={loading}
                      options={kpis}
                    />
                    {formik.errors.kpiId && formik.touched.kpiId ? (
                      <div className="error">{formik.errors.kpiId}</div>
                    ) : null}
                  </Col>
                  <Col sm={12} md={6} xl={6} className="mb-3">
                    <FormikController
                      control="input"
                      type="number"
                      label="Target"
                      name="target"
                    />
                  </Col>
                  <Col sm={12} md={6} xl={6} className="mb-3">
                    <BootstrapForm.Group>
                      <BootstrapForm.Label>Graphics</BootstrapForm.Label>
                      <input
                        className="form-control"
                        type="file"
                        id="formFile"
                        name=""
                        onChange={(event) => {
                          setImage(event.target.files[0]);
                        }}
                      />
                    </BootstrapForm.Group>
                  </Col>
                </Row>
                <Box
                  mt="25px"
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Button
                    variant=""
                    className="btn btn-outline-danger"
                    disabled={loading}
                    onClick={() => {
                      formik.resetForm();
                    }}
                  >
                    <i className="fe fe-trash-2 me-2" />
                    Cancel
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    variant="primary"
                    className="me-1"
                    disabled={loading}
                    type="submit"
                  >
                    <i className="fe fe-save me-2" />
                    Create
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </Box>
  );
};

export default CreateMasterBadge;
