import { request } from "../helpers/axios";

export const GET_COMPANIES = ({ page = 1 }) => {
  return request({
    url: `/company?page=${page}&all=${false}`,
    method: "GET",
  });
};

export const GET_SUBSCRIPTION = () => {
  return request({
    url: `/subscription`,
    method: "GET",
  });
};

export const CREATE_SUBSCRIPTION = (data) => {
  return request({
    url: `/subscription/beta`,
    method: "POST",
    data,
  });
};

export const UPDATE_SUBSCRIPTION = (companyId, data) => {
  return request({
    url: `/subscription/update?companyId=${companyId}`,
    method: "PATCH",
    data,
  });
};

export const PROCESS_CANCELLATION = (body) => {
  return request({
    url: '/subscription/process-cancellation',
    method: "PATCH",
    data: body,
  });
};


export const GET_PAYMENTS = () => {
  return request({
    url: `/payment/list`,
    method: "GET",
  });
}