import React from "react";
import ReactApexChart from "react-apexcharts";

const StatsWithPieChart = ({ labels, series, colors = null, height = 300 }) => {
  const options = {
    chart: {
      type: "pie",
    },
    fill: {
      type: "gradient",
    },
    labels: [],
    dataLabels: {
      enabled: true,
    },
    legend: {
      show: true,
      position: "bottom",
      labels: {
        useSeriesColors: true,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 380,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  return (
    <ReactApexChart
      type="pie"
      options={{ ...options, ...(colors && { colors }), labels }}
      series={series}
      width={"100%"}
      height={height}
    />
  );
};

export default StatsWithPieChart;
