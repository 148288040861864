import React, { useEffect, useState } from "react";
import { Card, Button, Row, Col, Form } from "react-bootstrap";
import { Box } from "@mui/material";

import { notifySuccess, notifyError } from "../../../helpers/notifications.js";
import { ADD_SETTINGS, GET_SETTINGS } from "../../../api/settings.js";

const Settings = () => {
  const [inputList, setInputList] = useState([]);

  const handleInputChange = (e, index) => {
    const { value, name } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([
      ...inputList,
      { key: "", value: "", keyDisabled: false, valueDisabled: false },
    ]);
  };

  const triggerEdit = (index) => {
    const list = [...inputList];

    if (list[index].valueDisabled) {
      list[index].valueDisabled = false;

      setInputList(list);
    }
  };

  const triggerSave = async () => {
    if (inputList.length) {
      const filteredSettings = inputList.filter(
        (item) => item.key.trim() !== "" && item.value.trim() !== "",
      );

      try {
        const data = await Promise.all(
          filteredSettings.map((item) => ADD_SETTINGS(item)),
        );

        if (data) {
          const list = [...inputList];

          const updatedList = list.map((item) => ({
            ...item,
            keyDisabled: true,
            valueDisabled: true,
          }));

          setInputList(updatedList);
          notifySuccess("Settings saved successfully");
        }
      } catch (err) {
        console.log(err.response);
        notifyError(err.response.data.message);
      }
    } else {
      notifyError("Please add settings");
    }
  };

  const fetchSettings = async () => {
    try {
      const { data } = await GET_SETTINGS();

      const list = data.map((item) => ({
        key: item.key,
        value: item.value,
        keyDisabled: true,
        valueDisabled: true,
      }));

      setInputList(list);
    } catch (err) {
      console.log(err.response.data.message);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  return (
    <Box sx={{ gridArea: "first", marginTop: 5 }}>
      <Card>
        <Card.Header className="card-header">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <h3 className="card-title">Global Settings</h3>

            <Box>
              <Button
                className="btn btn-outline-secondary mx-2"
                variant=""
                onClick={handleAddClick}
              >
                <>
                  <i className="icon icon-note me-2" />
                  Add New
                </>
              </Button>
              <Button
                onClick={triggerSave}
                variant="primary"
                type="submit"
                className="btn btn-Register an existing User as an Affiliateprimary me-1"
              >
                <>
                  <i className="fe fe-save me-2" />
                  Save
                </>
              </Button>
            </Box>
          </Box>
        </Card.Header>
        <Card.Body>
          <Form>
            {inputList.map((item, index) => (
              <Row key={index} className="mb-4">
                <Col md="6">
                  <Form.Control
                    name="key"
                    placeholder="Key"
                    value={item.key}
                    required
                    onChange={(e) => handleInputChange(e, index)}
                    disabled={item.keyDisabled}
                  />
                </Col>
                <Col md="5">
                  <Form.Control
                    name="value"
                    placeholder="Value"
                    value={item.value}
                    required
                    onChange={(e) => handleInputChange(e, index)}
                    disabled={item.valueDisabled}
                  />
                </Col>
                <Col md="1">
                  <Button
                    className="btn btn-outline-secondary mx-2"
                    variant=""
                    onClick={() => triggerEdit(index)}
                  >
                    <i className="fe fe-edit" />
                  </Button>
                </Col>
              </Row>
            ))}
          </Form>
        </Card.Body>
      </Card>
    </Box>
  );
};

export default Settings;
