import { useSelector } from "react-redux";

export const UseAuth = () => {
  const isAuthenticated = useSelector(state => {
    return state.adminReducer?.authUserId !== null && state.adminReducer?.authUserId !== undefined
  });
  return isAuthenticated;
};

export const UseAuthUserId = () => {
  const authUserId = useSelector(state => state.adminReducer?.authUserId);
  return authUserId;
};

export const UseAuthName = () => {
  const authName = useSelector(state => state.adminReducer?.authName);
  return authName;
};

