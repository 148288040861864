import React, { useState, useRef, useEffect } from "react";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from "quill-image-resize-module-react";
import "react-quill/dist/quill.snow.css";
import "../styles/editor.css";

// Import and whitelist fonts to be included in the editor
const Font = Quill.import("formats/font");
Font.whitelist = ["Roboto", "Inter"];
Quill.register(Font, true);

// Activate image resize module
Quill.register("modules/imageResize", ImageResize);

const JetpackEditor = ({ content, updateValue }) => {
  const [value, setValue] = useState(content ?? "");
  const editorRef = useRef();

  useEffect(() => {
    // Add paste event listener to handle pasted HTML
    const editor = document.querySelector(".ql-editor");
    if (editor) {
      editor.addEventListener("paste", handlePaste);
    }

    return () => {
      // Remove event listener on component unmount
      if (editor) {
        editor.removeEventListener("paste", handlePaste);
      }
    };
  }, []);

  const rqModules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [{ script: "sub" }, { script: "super" }],
      [{ color: [] }, { background: [] }],
      [{ font: Font.whitelist }],
      [{ align: [] }],
      ["link", "image", "video"],
      ["clean"],
    ],
    imageResize: {
      parchment: Quill.import("parchment"),
      modules: ["Resize", "DisplaySize", "Toolbar"],
    },
    clipboard: {
      matchVisual: false,
    },
  };

  const handleValue = (value) => {
    setValue(value);
    updateValue?.(value);
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const text = (event.originalEvent || event).clipboardData.getData(
      "text/plain",
    );
    const html = (event.originalEvent || event).clipboardData.getData(
      "text/html",
    );

    // Access the Quill instance using the ref
    const quillInstance = editorRef.current.getEditor();
    // Ensure that the editor is focused before attempting to get the selection
    quillInstance.focus();

    // Use a setTimeout to allow the editor to update its state before getting the selection
    setTimeout(() => {
      const range = quillInstance.getSelection();
      const clipboard = quillInstance.getModule("clipboard");
      clipboard.dangerouslyPasteHTML(range.index, text || html, "user");
    }, 0);
  };

  return (
    <ReactQuill
      ref={editorRef}
      theme="snow"
      modules={rqModules}
      value={value}
      onChange={handleValue}
    />
  );
};

export default JetpackEditor;
