export const DATASETS = [
  "Job",
  "Estimate",
  "Review",
  "Invoice",
  "Ad",
  "Lead",
  "Activity",
  "Opportunity",
];

export const PARAMS_INIT = {
  "subSet": "",
  "status": "",
  "value": "",
  "filter": "",
  "filterValue": "",
  "calc": "",
  "period": "",
  "condition": {key: "", value: ""},
  "level": "",
  "gap": "",
};

export const PARAMS_KEYS = Object.keys(PARAMS_INIT);
 
export const PARAMS_PRESETS = {
  "calc": ["average", "rate", "sum"],
  "period": [ "month", "ytd", "year", "all" ],
  "gap": [ true, false ],
};