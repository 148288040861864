import { request } from "../helpers/axios";

export const GET_AFFILIATES = (level) => {
  return request({
    url: `/affiliate${level >= 0 ? `/level/${level}` : ""}`,
    method: "GET",
  });
};

export const GET_USERS = () => {
  return request({
    url: `/affiliate/users`,
    method: "GET",
  });
};

export const PUT_AFFILIATE = (payload) => {
  return request({
    url: "/affiliate",
    method: "PUT",
    data: payload,
  });
};

export const SEND_CREDENTIALS_TO_AFFILIATE = (payload) => {
  return request({
    url: "/auth/send",
    method: "PATCH",
    data: payload,
  });
};
