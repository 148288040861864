import React, { useState } from "react";
import {
  Card,
  Button,
  FormLabel,
  Row,
  Col,
  Form as BootstrapForm,
} from "react-bootstrap";
import { Box, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { Formik, Form, useField, useFormikContext, Field } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import slugify from "slugify";

import FormikController from "../Formik/FormikController.js";
import { notifySuccess } from "../../../helpers/notifications.js";
import { CREATE_WIDGET } from "../../../api/widget.js";

const KeyField = (props) => {
  const {
    values: { title },
    touched,
    setFieldValue,
  } = useFormikContext();
  const [field, meta] = useField(props);

  React.useEffect(() => {
    if (title.trim() !== "" && touched.title) {
      setFieldValue(
        props.name,
        slugify(title, {
          lower: true,
          trim: true,
          replacement: "_",
        }),
      );
    }
  }, [title, touched.title, setFieldValue, props.name]);

  return (
    <>
      <FormLabel style={{ display: "block" }} htmlFor={props.name}>
        Key {<span className="text-red">*</span>}
      </FormLabel>
      <Field as="input" {...props} {...field} className="form-control" />
      {!!meta.touched && !!meta.error && (
        <div className="error text-red py-1">{meta.error}</div>
      )}
    </>
  );
};

const CreateWidget = () => {
  const [loading, setLoading] = useState(false);
  const [enable, setEnable] = useState(false);
  const [editable, setEditable] = useState(true);
  const [isMultiline, setIsMultiline] = useState(false);

  const categories = [
    {
      value: "earnings",
      label: "Earnings",
    },
    {
      value: "metrics",
      label: "Metrics",
    },
  ];

  // Formik
  const initialValues = {
    title: "",
    key: "",
    description: "",
    category: "",
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Required"),
    key: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    category: Yup.object().nullable().required("Required"),
  });

  const createHandler = async (formDetails, { resetForm }) => {
    const payload = {
      ...formDetails,
      editable,
      category: formDetails.category.label,
      userInput: enable,
      isMultiline,
    };

    setLoading(true);
    try {
      await CREATE_WIDGET(payload);
      notifySuccess("Widget created successfully");
      resetForm();
      setEnable(false);
      setIsMultiline(false);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  return (
    <Box sx={{ gridArea: "first", marginTop: 5 }}>
      <Card>
        <Card.Header className="card-header">
          <h3 className="card-title">Create Widget</h3>
        </Card.Header>
        <Card.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={createHandler}
          >
            {(formik) => (
              <Form>
                <Row>
                  <Col sm={12} md={6} xl={6} className="mb-3">
                    <FormikController
                      control="input"
                      type="text"
                      label="Title"
                      name="title"
                      required
                    />
                  </Col>
                  <Col sm={12} md={6} xl={6} className="mb-3">
                    <KeyField
                      control="input"
                      type="text"
                      label="Key"
                      name="key"
                      disabled
                    />
                  </Col>
                  <Col sm={12} md={6} xl={6} className="mb-3">
                    <FormikController
                      control="input"
                      type="text"
                      label="Description"
                      name="description"
                      required
                    />
                  </Col>
                  <Col sm={12} md={6} xl={6} className="mb-3">
                    <FormLabel>Category</FormLabel>
                    <Select
                      name="category"
                      value={formik.values.category}
                      onChange={(value) => {
                        formik.setFieldValue("category", value);
                      }}
                      placeholder="Select Category"
                      isClearable
                      isLoading={loading}
                      options={categories}
                      required
                    />
                    {formik.errors.category && formik.touched.category ? (
                      <div className="error text-red py-1">
                        {formik.errors.category}
                      </div>
                    ) : null}
                  </Col>
                  <Col sm={12} md={6} xl={6} className="mb-3">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={editable || false}
                            onChange={() => setEditable(!editable)}
                          />
                        }
                        label="Does this widget editable?"
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6} xl={6} className="mb-3">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={enable || false}
                            onChange={() => setEnable(!enable)}
                          />
                        }
                        label="Does this widgets accepts user input?"
                      />
                    </FormGroup>
                  </Col>
                  {enable && (
                    <Col sm={12} md={6} xl={6} className="mb-3">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isMultiline || false}
                              onChange={() => setIsMultiline(!isMultiline)}
                            />
                          }
                          label="Multine?"
                        />
                      </FormGroup>
                    </Col>
                  )}
                </Row>
                <Box
                  mt="25px"
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Button
                    variant=""
                    className="btn btn-outline-danger"
                    disabled={loading}
                    onClick={() => {
                      formik.resetForm();
                    }}
                  >
                    <i className="fe fe-trash-2 me-2" />
                    Cancel
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    variant="primary"
                    className="me-1"
                    disabled={loading}
                    type="submit"
                  >
                    <i className="fe fe-save me-2" />
                    Create
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </Box>
  );
};

export default CreateWidget;
