import { all, fork, takeEvery, call, put, select } from "redux-saga/effects";
import { types } from "./types";

import { GET_APP_VERSION } from "../api/app";
import { LOGIN_GOOGLE_USER } from "../api/auth";

// Ticket and Company data have been added to redux to synchronize states in the pages, sidenav badge, plus regular data fetching intervals
import { GET_TICKETS } from "../api/tickets";
import { GET_ALL_COMPANIES } from "../api/company";

const getClientVersion = (state) => state.adminReducer?.version;

function* GetAppVersion() {
  try {
    const { data: version } = yield call(() => GET_APP_VERSION());
    const clientVersion = yield select(getClientVersion);

    yield put({
      type: types.PUT_VERSION,
      version,
      synced: clientVersion === version,
    });
  } catch (err) {
    yield put({
      type: types.API_OFFLINE,
      version: 0,
      synced: false,
      error: err.message,
    });
    console.log("Get version error", err.message);
  }
}

function* CheckAppVersion(action) {
  try {
    const { synced } = action;
    if (!synced) {
      // Force sign out if version doesn't match latest server (API) version
      yield put({ type: types.SIGN_OUT });
    } else {
      yield put({ type: types.VERSION_SYNCED });
    }
  } catch (err) {
    console.log("Version check error", err.message);
  }
}

function* appSaga() {
  yield takeEvery(types.GET_VERSION, GetAppVersion);
  yield takeEvery(types.PUT_VERSION, CheckAppVersion);
}

function* handleSocialLogin(action) {
  try {
    const { token: accessToken, authType } = action;
    let result = null;
    if (authType === "google") {
      result = yield call(() => LOGIN_GOOGLE_USER(accessToken));
    }

    if (result) {
      const { token, _id: userId, email, firstName, lastName } = result.data;
      const domain = email.split("@")[1];
      if (domain === "gamemybiz.com") {
        yield localStorage.setItem("user", email);
        yield localStorage.setItem("token", token);
        yield localStorage.setItem("userId", userId);

        yield put({
          type: types.LOGIN_SUCCESS,
          userId,
          name: `${firstName} ${lastName}`,
        });
      } else {
        yield put({
          type: types.LOGIN_FAILED,
          error: `${email} is not an authorized user.`,
        });
      }
    } else {
      yield put({
        type: types.LOGIN_FAILED,
        error: `User not found.`,
      });
    }
  } catch (err) {
    const { data: message } = err.response;
    yield put({ type: types.LOGIN_FAILED, error: message });
  }
}

function* authSaga() {
  yield takeEvery(types.SOCIAL_LOGIN_REQUEST, handleSocialLogin);
}

function* getTickets() {
  try {
    const { data } = yield call(() => GET_TICKETS());
    yield put({ type: types.GET_TICKETS_SUCCESS, data });
  } catch (err) {
    const { data: message } = err.response;
    yield put({ type: types.GET_TICKETS_FAILED, error: message });
    if (message === "Unauthorized") {
      yield put({ type: types.SIGN_OUT });
    }
  }
}

function* getRecentCompanies() {
  try {
    const { data } = yield call(() => GET_ALL_COMPANIES({ all: true }));
    yield put({ type: types.GET_RECENT_COMPANIES_SUCCESS, data: data?.docs });
  } catch (err) {
    const { data: message } = err.response;
    yield put({ type: types.GET_RECENT_COMPANIES_FAILED, error: message });
    if (message === "Unauthorized") {
      yield put({ type: types.SIGN_OUT });
    }
  }
}

function* dataSaga() {
  yield takeEvery(types.GET_TICKETS_REQUEST, getTickets);
  yield takeEvery(types.GET_RECENT_COMPANIES_REQUEST, getRecentCompanies);
}

function* rootSaga() {
  yield all([fork(appSaga), fork(authSaga), fork(dataSaga)]);
}

export default rootSaga;
