import { request } from "../helpers/axios";

export const GET_TICKETS = () => {
   return request({
      url: `/tickets/all`,
      method: "GET",
   });
};

export const HANDLE_TICKET = (ticketId) => {
   return request({
      url: `/tickets/handle`,
      method: "PATCH",
      data: {ticketId}
   });
};

export const RESOLVE_TICKET = (ticketId) => {
   return request({
      url: `/tickets/resolve`,
      method: "PATCH",
      data: {ticketId}
   });
};