import React from "react";
import moment from "moment";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";

import { PROCESS_CANCELLATION } from "../../../api/subscription";

const CancelRequestTable = ({ companies }) => {
  const columns = [
    {
      name: "Company Name",
      selector: (row) => [row.businessName?.trim() ? row.businessName : "N/A"],
      sortable: true,
    },
    {
      name: "User Name",
      selector: (row) => [
        row.user ? `${row.user.firstName} ${row.user.lastName}` : "N/A",
      ],
      grow: 1,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => [row.user ? row.user.email : "N/A"],
      sortable: true,
    },
    {
      name: "Request Date",
      selector: (row) => {
        if (row.createdAt) {
          const createdAt = moment(row.createdAt);
          const isRecent = moment().diff(createdAt, "days") <= 7;
          return [
            <span
              className={`tag ${isRecent? 'tag-success' : ''}`}
            >
              {createdAt.format("YYYY-MM-DD")}
            </span>,
          ];
        }
        return "";
      },
      grow: 0,
      sortable: true,
    },
    {
      name: "Subscription",
      selector: (row) => [
        row.subscription?.status ? row.subscription.status : "N/A",
      ],
      grow: 1,
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (row) => [
        <Button
          variant="primary"
          size="sm"
          onClick={async () => {

            const body = {
              companyId: row._id,
              level: 0,
              status: 'Cancelled',
            };
            const response = await PROCESS_CANCELLATION(body);

            if ((response.statusText = "OK")) {
              window.location.reload();
            } else {
              console.error(
                "Failed to get customer URL:",
                response.status,
                response.statusText,
              );
            }
          }}
        >
          Cancel
        </Button>,
      ],
      grow: 0,
      sortable: false,
    },
  ];

  return (
    <div className="table remove-filter-input">
        <DataTable
          columns={columns}
          data={companies}
          noHeader
          defaultSortField="id"
          defaultSortAsc={false}
          striped={true}
          center={true}
          persistTableHead
          pagination={false}
          highlightOnHover
        />
    </div>
  );
};

export default CancelRequestTable;
