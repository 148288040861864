import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import LoadingScreen from "../LoadingScreen/index.jsx";
import { FETCH_WIDGETS } from "../../../api/widget.js";

const ManageWidgets = () => {
  const [loading, setLoading] = useState(false);
  const [widgets, setWidgets] = useState([]);
  const navigate = useNavigate();

  const fetchWidgets = async () => {
    try {
      setLoading(true);
      const { data } = await FETCH_WIDGETS({});
      setWidgets(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.response);
    }
  };

  useEffect(() => {
    fetchWidgets();
  }, []);

  return (
    <Box sx={{ gridArea: "first", marginTop: 5 }}>
      <Card>
        <Card.Header className="card-header">
          <div className="flex-fill">
            <h2 className="card-title">Master Widgets List</h2>
          </div>
        </Card.Header>
        <Card.Body className="card-body">
          <Row>
            {loading ? (
              <LoadingScreen />
            ) : !widgets.length ? (
              <Box
                sx={{
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <h5>No widgets found!</h5>
              </Box>
            ) : (
              widgets.map((item, index) => (
                <Col sm={12} md={6} xl={4} key={index}>
                  <Card>
                    <Card.Header>
                      <h3
                        className="card-title"
                        style={{ textTransform: "capitalize" }}
                      >
                        {item.title}
                      </h3>
                      <div className="card-options">
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() =>
                            navigate(`/widgets/edit/${item._id}`, {
                              state: {
                                item,
                              },
                            })
                          }
                        >
                          Edit
                        </button>
                      </div>
                    </Card.Header>
                    <Card.Body className="card-body">
                      <div className="tags">
                        <span className="tag">{item.category}</span>
                      </div>
                      <p>{item?.description}</p>
                      Enabled: <Switch disabled checked={item.enabled} />
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={item?.userInput || false}
                              disabled
                            />
                          }
                          label="Widgets accepts user input"
                        />
                      </FormGroup>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={item?.isMultiline || false}
                              disabled
                            />
                          }
                          label="Is multiline?"
                        />
                      </FormGroup>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            )}
          </Row>
        </Card.Body>
      </Card>
    </Box>
  );
};

export default ManageWidgets;
