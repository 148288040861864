import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Button, Row, Col } from "react-bootstrap";
import { Box, Divider } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import INDUSTRY_OPTIONS from "../../../constants/industries.js";

import FormikController from "../Formik/FormikController.js";
import { CREATE_DEMO_USER } from "../../../api/user.js";
import { GET_DEMO_WORKSPACES } from "../../../api/workspace.js";
import { notifySuccess } from "../../../helpers/notifications.js";

const CreateDemoAccounts = () => {
  const [loading, setLoading] = useState(false);
  const [workspaces, setWorkspaces] = useState([]);
  const [industries] = useState(INDUSTRY_OPTIONS.map((industry) => ({
    label: industry,
    value: industry,
  })));
  const navigate = useNavigate();

  // Formik
  const initialValues = {
    industry: "",
    otherIndustry: "",
    groupId: "",
    fname: "",
    lname: "",
    email: "",
    companyName: "",
    website: "",
  };

  const validationSchema = Yup.object({
    industry: Yup.string().nullable().required("Required"),
    groupId: Yup.string().required("Required"),
    otherIndustry: Yup.string().nullable(),
    fname: Yup.string().required("Required"),
    lname: Yup.string().required("Required"),
    email: Yup.string().email().required("Required"),
    companyName: Yup.string().required("Required"),
    website: Yup.string(),
  });

  const createHandler = async (formDetails, { resetForm }) => {
    const payload = {
      ...formDetails,
      userId: localStorage.getItem("userId"),
    };

    setLoading(true);
    try {
      await CREATE_DEMO_USER(payload);
      notifySuccess("Demo user created successfully");
      resetForm();
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    GET_DEMO_WORKSPACES()
      .then(({ data }) => {
        const options = data.map(w => ({value: w._id, label: w.name}));
        setWorkspaces(options);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Box sx={{ gridArea: "first", marginTop: 5 }}>
      <Card>
        <Card.Header className="card-header d-flex justify-content-between">
          <h3 className="card-title">Create Demo Account</h3>
          <Button
            className="btn btn-primary-light"
            variant="primary-light"
            onClick={() => navigate("/prospects/cold-leads")}
          >
            <i className="icon icon-list me-2" /> Back to List
          </Button>
        </Card.Header>
        <Card.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={createHandler}
          >
            {(formik) => (
              <Form>
                <Row>
                  <Col sm={12} md={6} xl={3} className="mb-3">
                    <FormikController
                      control="select"
                      label="Industry"
                      name="industry"
                      options={industries}
                      helperText="Choose 'Others' if not on the list"
                      required
                    />
                  </Col>
                  <Col sm={12} md={6} xl={3} className="mb-3">
                    <FormikController
                      control="input"
                      type="text"
                      label="Specific Industry"
                      helperText="Manually input industry if 'Others'"
                      name="otherIndustry"
                    />
                  </Col>
                  <Col sm={12} md={12} xl={6} className="mb-3">
                    <FormikController
                      control="select"
                      label="Demo Workspace"
                      helperText="The workspace determines what KPIs the demo user would see"
                      placeholder="Select Demo Workspace"
                      options={workspaces}
                      name="groupId"
                      required
                    />
                  </Col>
                  <Col xs={12} className="my-4">
                    <Divider />
                  </Col>
                  <Col sm={12} md={6} xl={3} className="mb-3">
                    <FormikController
                      control="input"
                      type="text"
                      label="First Name"
                      name="fname"
                    />
                  </Col>
                  <Col sm={12} md={6} xl={3} className="mb-3">
                    <FormikController
                      control="input"
                      type="text"
                      label="Last Name"
                      name="lname"
                    />
                  </Col>
                  <Col sm={12} md={12} xl={6} className="mb-3">
                    <FormikController
                      control="input"
                      type="text"
                      label="Email"
                      name="email"
                    />
                  </Col>
                  <Col xs={12} className="my-4">
                    <Divider />
                  </Col>
                  <Col sm={12} md={6} xl={6} className="mb-3">
                    <FormikController
                      control="input"
                      type="text"
                      label="Company Name"
                      name="companyName"
                    />
                  </Col>
                  <Col sm={12} md={6} xl={6} className="mb-3">
                    <FormikController
                      control="input"
                      type="text"
                      label="Website"
                      name="website"
                    />
                  </Col>
                </Row>
                <Box
                  mt="25px"
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Button
                    variant=""
                    className="btn btn-outline-danger"
                    disabled={loading}
                    onClick={() => navigate("/prospects/cold-leads")}
                  >
                    <i className="fe fe-trash-2 me-2" />
                    Cancel
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    variant="primary"
                    className="me-1"
                    disabled={loading}
                    type="submit"
                  >
                    <i className="fe fe-save me-2" />
                    Create
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </Box>
  );
};

export default CreateDemoAccounts;
