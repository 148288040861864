import React, { useEffect, useState } from "react";
import { Card, Button } from "react-bootstrap";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";

import { GET_MASTER_BADGE } from "../../../api/badge.js";
import { GET_IMAGE } from "../../../helpers/images.js";
import LoadingScreen from "../LoadingScreen/index.jsx";

const ManageMasterBadge = () => {
  const [badges, setBadges] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchBadges = async () => {
    try {
      setLoading(true);
      const { data } = await GET_MASTER_BADGE({});
      setBadges(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.response);
    }
  };

  useEffect(() => {
    fetchBadges();
  }, []);

  const columns = [
    {
      name: "GRAPHICS",
      selector: (row) => [
        row.image ? (
          <img
            src={GET_IMAGE(row.image)}
            alt={row.target}
            className="avatar avatar-lg cover-image"
          />
        ) : null,
      ],
      sortable: true,
    },
    {
      name: "TITLE",
      selector: (row) => [row.title ? row.title : "N/A"],
      sortable: true,
    },
    {
      name: "KPI",
      selector: (row) => [row.kpiId ? row.kpiId.type : "N/A"],
      sortable: true,
    },
    {
      name: "TARGET",
      selector: (row) => [row.target ? row.target : "N/A"],
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (row) => [
        <Button
          variant="secondary"
          size="sm"
          onClick={() => {
            navigate(`/master-badge/edit/${row._id}`, {
              state: {
                row,
              },
            });
          }}
        >
          <i className="icon icon-pencil" /> EDIT
        </Button>,
      ],
      sortable: false,
    },
  ];

  const tableData = {
    columns,
    data: badges,
  };

  return (
    <Box sx={{ gridArea: "first", marginTop: 5 }}>
      <Card>
        <Card.Header className="card-header">
          <div className="flex-fill">
            <h2 className="card-title">Master Badges List</h2>
          </div>
        </Card.Header>
        <Card.Body className="card-body">
          {loading ? (
            <LoadingScreen />
          ) : !badges.length ? (
            <Box
              sx={{
                width: "100%",
                textAlign: "center",
              }}
            >
              <h5>No badges found!</h5>
            </Box>
          ) : (
            <div className="table remove-filter-input">
              <DataTableExtensions {...tableData}>
                <DataTable
                  columns={columns}
                  data={badges}
                  noHeader
                  defaultSortField="id"
                  defaultSortAsc={false}
                  striped={true}
                  center={true}
                  persistTableHead
                  pagination={false}
                  highlightOnHover
                />
              </DataTableExtensions>
            </div>
          )}
        </Card.Body>
      </Card>
    </Box>
  );
};

export default ManageMasterBadge;
