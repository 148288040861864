import { request } from "../helpers/axios";

export const GET_NEW_COMPANY = () => {
  return request({
    url: `/company/new-company`,
    method: "GET",
  });
};

export const GET_ALL_COMPANIES = ({ page = 1, limit = 50, all = false, filter = null }) => {
  return request({
    url: `/company?limit=${limit}&page=${page}${all? '&all=true' : ''}${filter? `&filter=${filter}` : ''}`,
    method: "GET",
  });
};

export const GET_CANCEL_COMPANIES = ({ page = 1, limit = 50, all = false, filter = null }) => {
  return request({
    url: `/company/cancel-company?limit=${limit}&page=${page}${all? '&all=true' : ''}${filter? `&filter=${filter}` : ''}`,
    method: "GET",
  });
};
