import React from "react";
import { Field, ErrorMessage } from "formik";
import { FormLabel } from "react-bootstrap";

import Error from "./Error.js";

const Input = (props) => {
  const { name, label, className, rows, required, helperText, ...rest } = props;

  return (
    <div className={`formik-controller ${className}`}>
      {
        label && <FormLabel style={{ display: "block" }} htmlFor={name}>
          {label} { required && <span className="text-red">*</span> }
        </FormLabel>
      }
      {
        helperText && <span className="text-muted">{helperText}</span>
      }
      <Field as={rows? "textarea": "input"} name={name} {...rest} className="form-control" />
      <ErrorMessage name={name} component={Error} />
    </div>
  );
};

export default Input;
