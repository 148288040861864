import { request } from "../helpers/axios";

export const GET_JETPACKS = () => {
  return request({
    url: `/jetpacks/all`,
    method: "GET",
  });
};

export const GET_JETPACK = (id) => {
  return request({
    url: `/jetpacks/item?id=${id}`,
    method: "GET",
  });
};

export const ADD_JETPACK = (data) => {
  return request({
    url: `/jetpacks`,
    method: "PUT",
    data,
  });
};