import axios from 'axios';

const IMAGE_BLOBS = {};

export const GET_IMAGE_URL = (imageKey) => `${process.env.REACT_APP_LINK_HOST}/images/${imageKey}`;

export const GET_IMAGE = (src) => {
  if (src) {
    if (IMAGE_BLOBS[src]) {
      return IMAGE_BLOBS[src];
    } else {
      let imgUrl = null;
      try {
        const testUrl = new URL(src);
        imgUrl = src;
      } catch (_) {
        imgUrl = GET_IMAGE_URL(src);
      }

     axios.get(imgUrl, { responseType: 'blob' })
        .then((response) => response.data)
        .then((blob) => {
          const srcUrl = URL.createObjectURL(blob);
          IMAGE_BLOBS[src] = srcUrl;
          return srcUrl;
        })
        .catch((err) => {
          console.log('Image fetch error', err.message);
          throw err;
        });

      return imgUrl;
    }
  }
  return null;
};